
.container-datapicker {
  display: grid;
  grid-template-columns: 1rem 1fr 18%;
  justify-items: stretch;
  background: #f3f3f3;
  border: 1px solid #cccccc;
  margin-top: 2rem;
}

.datepicker {
  width: 100%;
  background: #e5e5e5;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  padding: 1.5rem 1.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-items: center;
  align-items: center;

  &-wrapper {
    background: #f3f3f3;
    border: 1px solid #cccccc;
    padding: 20px;

    &-inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      justify-items: stretch;

      .form-controls {
        margin-bottom: 0 !important;
      }

      label {
        text-align: left;
      }

      input {
        font-size: 0.8rem;
        font-weight: 700;
        background: #ffffff;
        border: 1px solid #cccccc;
      }
    }
  }

  button {
    grid-column-start: 3;
    justify-self: end;
    padding: 1rem 1.5rem;
    background: #fea82b;
    border: 2px solid #e69827;
  }

  p {
    font-size: 0.98rem;
    font-weight: 800;
    line-height: 22px;
    color: #333333;
    text-align: left;
    padding-bottom: 4px;
  }
}

.wrapper {
  display: grid;
  place-content: center;
  padding: 0.5rem 2rem;
}

.button {
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  background: #a3b84b;
  border: 2px solid #9cb045;
  border-radius: 0;

  img {
    width: 20px;
    padding: 0.05rem;
  }
}

// Animations

.expand-enter-active {
  animation: showInputs 0.2s ease-in;
}
.expand-leave-active {
  animation: showInputs 0.2s reverse ease-out;
}

@keyframes showInputs {
  0% {
    opacity: 0;
    padding: 0rem 1.5rem;
    height: 0px;
  }
  100% {
    opacity: 1;
    padding: 1.5rem 1.5rem;
    height: 300px;
  }
}
