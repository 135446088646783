$font-family: "material-ketchup" !default;
$font-path: $fonts_path;

$icon-500px: "\f26e";
$icon-address-book: "\f2b9";
$icon-address-book-o: "\f2ba";
$icon-address-card: "\f2bb";
$icon-vcard: "\f2bb";
$icon-address-card-o: "\f2bc";
$icon-vcard-o: "\f2bc";
$icon-adjust1: "\f042";
$icon-adn: "\f170";
$icon-align-center: "\f037";
$icon-align-justify: "\f039";
$icon-align-left: "\f036";
$icon-align-right: "\f038";
$icon-amazon: "\f270";
$icon-ambulance: "\f0f9";
$icon-american-sign-language-interpreting: "\f2a3";
$icon-asl-interpreting: "\f2a3";
$icon-anchor: "\f13d";
$icon-android: "\f17b";
$icon-angellist: "\f209";
$icon-angle-double-down: "\f103";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-down: "\f107";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-apple: "\f179";
$icon-archive1: "\f187";
$icon-area-chart: "\f1fe";
$icon-arrow-circle-down: "\f0ab";
$icon-arrow-circle-left: "\f0a8";
$icon-arrow-circle-o-down: "\f01a";
$icon-arrow-circle-o-left: "\f190";
$icon-arrow-circle-o-right: "\f18e";
$icon-arrow-circle-o-up: "\f01b";
$icon-arrow-circle-right: "\f0a9";
$icon-arrow-circle-up: "\f0aa";
$icon-arrow-down: "\f063";
$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrows: "\f047";
$icon-arrows-alt: "\f0b2";
$icon-arrows-h: "\f07e";
$icon-arrows-v: "\f07d";
$icon-assistive-listening-systems: "\f2a2";
$icon-asterisk: "\f069";
$icon-at: "\f1fa";
$icon-audio-description: "\f29e";
$icon-automobile: "\f1b9";
$icon-car: "\f1b9";
$icon-backward: "\f04a";
$icon-balance-scale: "\f24e";
$icon-ban: "\f05e";
$icon-bandcamp: "\f2d5";
$icon-bank: "\f19c";
$icon-institution: "\f19c";
$icon-university: "\f19c";
$icon-bar-chart: "\f080";
$icon-bar-chart-o: "\f080";
$icon-barcode: "\f02a";
$icon-bars: "\f0c9";
$icon-navicon: "\f0c9";
$icon-reorder1: "\f0c9";
$icon-bath: "\f2cd";
$icon-bathtub: "\f2cd";
$icon-s15: "\f2cd";
$icon-battery: "\f240";
$icon-battery-4: "\f240";
$icon-battery-full: "\f240";
$icon-battery-0: "\f244";
$icon-battery-empty: "\f244";
$icon-battery-1: "\f243";
$icon-battery-quarter: "\f243";
$icon-battery-2: "\f242";
$icon-battery-half: "\f242";
$icon-battery-3: "\f241";
$icon-battery-three-quarters: "\f241";
$icon-bed: "\f236";
$icon-hotel: "\f236";
$icon-beer: "\f0fc";
$icon-behance: "\f1b4";
$icon-behance-square: "\f1b5";
$icon-bell: "\f0f3";
$icon-bell-o: "\f0a2";
$icon-bell-slash: "\f1f6";
$icon-bell-slash-o: "\f1f7";
$icon-bicycle: "\f206";
$icon-binoculars: "\f1e5";
$icon-birthday-cake: "\f1fd";
$icon-bitbucket: "\f171";
$icon-bitbucket-square: "\f172";
$icon-bitcoin: "\f15a";
$icon-btc: "\f15a";
$icon-black-tie: "\f27e";
$icon-blind: "\f29d";
$icon-bluetooth1: "\f293";
$icon-bluetooth-b: "\f294";
$icon-bold: "\f032";
$icon-bolt: "\f0e7";
$icon-flash: "\f0e7";
$icon-bomb: "\f1e2";
$icon-book: "\f02d";
$icon-bookmark: "\f02e";
$icon-bookmark-o: "\f097";
$icon-braille: "\f2a1";
$icon-briefcase: "\f0b1";
$icon-bug: "\f188";
$icon-building: "\f1ad";
$icon-building-o: "\f0f7";
$icon-bullhorn: "\f0a1";
$icon-bullseye: "\f140";
$icon-bus: "\f207";
$icon-buysellads: "\f20d";
$icon-cab: "\f1ba";
$icon-taxi: "\f1ba";
$icon-calculator: "\f1ec";
$icon-calendar: "\f073";
$icon-calendar-check-o: "\f274";
$icon-calendar-minus-o: "\f272";
$icon-calendar-o: "\f133";
$icon-calendar-plus-o: "\f271";
$icon-calendar-times-o: "\f273";
$icon-camera1: "\f030";
$icon-camera-retro: "\f083";
$icon-caret-down: "\f0d7";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-caret-square-o-down: "\f150";
$icon-toggle-down: "\f150";
$icon-caret-square-o-left: "\f191";
$icon-toggle-left: "\f191";
$icon-caret-square-o-right: "\f152";
$icon-toggle-right: "\f152";
$icon-caret-square-o-up: "\f151";
$icon-toggle-up: "\f151";
$icon-caret-up: "\f0d8";
$icon-cart-arrow-down: "\f218";
$icon-cart-plus: "\f217";
$icon-cc: "\f20a";
$icon-cc-amex: "\f1f3";
$icon-cc-diners-club: "\f24c";
$icon-cc-discover: "\f1f2";
$icon-cc-jcb: "\f24b";
$icon-cc-mastercard: "\f1f1";
$icon-cc-paypal: "\f1f4";
$icon-cc-stripe: "\f1f5";
$icon-cc-visa: "\f1f0";
$icon-certificate: "\f0a3";
$icon-chain: "\f0c1";
$icon-link1: "\f0c1";
$icon-chain-broken: "\f127";
$icon-unlink: "\f127";
$icon-check1: "\f00c";
$icon-check-circle: "\f058";
$icon-check-circle-o: "\f05d";
$icon-check-square: "\f14a";
$icon-check-square-o: "\f046";
$icon-chevron-circle-down: "\f13a";
$icon-chevron-circle-left: "\f137";
$icon-chevron-circle-right: "\f138";
$icon-chevron-circle-up: "\f139";
$icon-chevron_bold_down: "\f078";
$icon-chevron_bold_left: "\f053";
$icon-chevron_bold_right: "\f054";
$icon-chevron_bold_up: "\f077";
$icon-child: "\f1ae";
$icon-chrome: "\f268";
$icon-circle: "\f111";
$icon-circle-o: "\f10c";
$icon-circle-o-notch: "\f1ce";
$icon-circle-thin: "\f1db";
$icon-clipboard: "\f0ea";
$icon-paste: "\f0ea";
$icon-clock-o: "\f017";
$icon-clone: "\f24d";
$icon-close1: "\f00d";
$icon-remove1: "\f00d";
$icon-times: "\f00d";
$icon-cloud: "\f0c2";
$icon-cloud-download: "\f0ed";
$icon-cloud-upload: "\f0ee";
$icon-cny: "\f157";
$icon-jpy: "\f157";
$icon-rmb: "\f157";
$icon-yen: "\f157";
$icon-code1: "\f121";
$icon-code-fork: "\f126";
$icon-codepen: "\f1cb";
$icon-codiepie: "\f284";
$icon-coffee: "\f0f4";
$icon-cog: "\f013";
$icon-gear: "\f013";
$icon-cogs: "\f085";
$icon-gears: "\f085";
$icon-columns: "\f0db";
$icon-comment_bubble: "\f075"; //ex comment1
$icon-comment-o: "\f0e5";
$icon-commenting: "\f27a";
$icon-commenting-o: "\f27b";
$icon-comments: "\f086";
$icon-comments-o: "\f0e6";
$icon-compass: "\f14e";
$icon-compress: "\f066";
$icon-connectdevelop: "\f20e";
$icon-contao: "\f26d";
$icon-copy: "\f0c5";
$icon-files-o: "\f0c5";
$icon-copyright_alt: "\f1f9";
$icon-creative-commons: "\f25e";
$icon-credit-card: "\f09d";
$icon-credit-card-alt: "\f283";
$icon-crop1: "\f125";
$icon-crosshairs: "\f05b";
$icon-css3: "\f13c";
$icon-cube: "\f1b2";
$icon-cubes: "\f1b3";
$icon-cut: "\f0c4";
$icon-scissors: "\f0c4";
$icon-cutlery: "\f0f5";
$icon-dashboard1: "\f0e4";
$icon-tachometer: "\f0e4";
$icon-dashcube: "\f210";
$icon-database: "\f1c0";
$icon-deaf: "\f2a4";
$icon-deafness: "\f2a4";
$icon-hard-of-hearing: "\f2a4";
$icon-dedent: "\f03b";
$icon-outdent: "\f03b";
$icon-delicious: "\f1a5";
$icon-desktop: "\f108";
$icon-deviantart: "\f1bd";
$icon-diamond: "\f219";
$icon-digg: "\f1a6";
$icon-dollar: "\f155";
$icon-usd: "\f155";
$icon-dot-circle-o: "\f192";
$icon-download: "\f019";
$icon-dribbble: "\f17d";
$icon-drivers-license: "\f2c2";
$icon-id-card: "\f2c2";
$icon-drivers-license-o: "\f2c3";
$icon-id-card-o: "\f2c3";
$icon-dropbox: "\f16b";
$icon-drupal: "\f1a9";
$icon-edge: "\f282";
$icon-edit: "\f044";
$icon-pencil-square-o: "\f044";
$icon-eercast: "\f2da";
$icon-eject1: "\f052";
$icon-ellipsis-h: "\f141";
$icon-ellipsis-v: "\f142";
$icon-empire: "\f1d1";
$icon-ge: "\f1d1";
$icon-envelope: "\f0e0";
$icon-envelope-o: "\f003";
$icon-envelope-open: "\f2b6";
$icon-envelope-open-o: "\f2b7";
$icon-envelope-square: "\f199";
$icon-envira: "\f299";
$icon-eraser: "\f12d";
$icon-etsy: "\f2d7";
$icon-eur: "\f153";
$icon-euro: "\f153";
$icon-exchange: "\f0ec";
$icon-exclamation: "\f12a";
$icon-exclamation-circle: "\f06a";
$icon-exclamation-triangle: "\f071";
$icon-warning1: "\f071";
$icon-expand: "\f065";
$icon-expeditedssl: "\f23e";
$icon-external-link: "\f08e";
$icon-external-link-square: "\f14c";
$icon-eye: "\f06e";
$icon-eye-slash: "\f070";
$icon-eyedropper: "\f1fb";
$icon-fa: "\f2b4";
$icon-font-awesome: "\f2b4";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-facebook-official: "\f230";
$icon-facebook-square: "\f082";
$icon-fast-backward: "\f049";
$icon-fast-forward: "\f050";
$icon-fax: "\f1ac";
$icon-feed: "\f09e";
$icon-rss: "\f09e";
$icon-female: "\f182";
$icon-fighter-jet: "\f0fb";
$icon-file: "\f15b";
$icon-file-archive-o: "\f1c6";
$icon-file-zip-o: "\f1c6";
$icon-file-audio-o: "\f1c7";
$icon-file-sound-o: "\f1c7";
$icon-file-code-o: "\f1c9";
$icon-file-excel-o: "\f1c3";
$icon-file-image-o: "\f1c5";
$icon-file-photo-o: "\f1c5";
$icon-file-picture-o: "\f1c5";
$icon-file-movie-o: "\f1c8";
$icon-file-video-o: "\f1c8";
$icon-file-o: "\f016";
$icon-file-pdf-o: "\f1c1";
$icon-file-powerpoint-o: "\f1c4";
$icon-file-text: "\f15c";
$icon-file-text-o: "\f0f6";
$icon-file-word-o: "\f1c2";
$icon-film: "\f008";
$icon-filter1: "\f0b0";
$icon-fire: "\f06d";
$icon-fire-extinguisher: "\f134";
$icon-firefox: "\f269";
$icon-first-order: "\f2b0";
$icon-flag1: "\f024";
$icon-flag-checkered: "\f11e";
$icon-flag-o: "\f11d";
$icon-flask: "\f0c3";
$icon-flickr: "\f16e";
$icon-floppy-o: "\f0c7";
$icon-save1: "\f0c7";
$icon-folder1: "\f07b";
$icon-folder-o: "\f114";
$icon-folder-open: "\f07c";
$icon-folder-open-o: "\f115";
$icon-font: "\f031";
$icon-fonticons: "\f280";
$icon-fort-awesome: "\f286";
$icon-forumbee: "\f211";
$icon-forward1: "\f04e";
$icon-foursquare: "\f180";
$icon-free-code-camp: "\f2c5";
$icon-frown-o: "\f119";
$icon-futbol-o: "\f1e3";
$icon-soccer-ball-o: "\f1e3";
$icon-gamepad: "\f11b";
$icon-gavel1: "\f0e3";
$icon-legal: "\f0e3";
$icon-gbp: "\f154";
$icon-genderless: "\f22d";
$icon-get-pocket: "\f265";
$icon-gg: "\f260";
$icon-gg-circle: "\f261";
$icon-gift: "\f06b";
$icon-git: "\f1d3";
$icon-git-square: "\f1d2";
$icon-github: "\f09b";
$icon-github-alt: "\f113";
$icon-github-square: "\f092";
$icon-gitlab: "\f296";
$icon-gittip: "\f184";
$icon-gratipay: "\f184";
$icon-glass: "\f000";
$icon-glide: "\f2a5";
$icon-glide-g: "\f2a6";
$icon-globe: "\f0ac";
$icon-google: "\f1a0";
$icon-google-plus: "\f0d5";
$icon-google-plus-circle: "\f2b3";
$icon-google-plus-official: "\f2b3";
$icon-google-plus-square: "\f0d4";
$icon-google-wallet: "\f1ee";
$icon-graduation-cap: "\f19d";
$icon-mortar-board: "\f19d";
$icon-grav: "\f2d6";
$icon-group: "\f0c0";
$icon-users: "\f0c0";
$icon-h-square: "\f0fd";
$icon-hacker-news: "\f1d4";
$icon-y-combinator-square: "\f1d4";
$icon-yc-square: "\f1d4";
$icon-hand-grab-o: "\f255";
$icon-hand-rock-o: "\f255";
$icon-hand-lizard-o: "\f258";
$icon-hand-o-down: "\f0a7";
$icon-hand-o-left: "\f0a5";
$icon-hand-o-right: "\f0a4";
$icon-hand-o-up: "\f0a6";
$icon-hand-paper-o: "\f256";
$icon-hand-stop-o: "\f256";
$icon-hand-peace-o: "\f25b";
$icon-hand-pointer-o: "\f25a";
$icon-hand-scissors-o: "\f257";
$icon-hand-spock-o: "\f259";
$icon-handshake-o: "\f2b5";
$icon-hashtag: "\f292";
$icon-hdd-o: "\f0a0";
$icon-header: "\f1dc";
$icon-headphones: "\f025";
$icon-heart: "\f004";
$icon-heart-o: "\f08a";
$icon-heartbeat: "\f21e";
$icon-history: "\f1da";
$icon-home1: "\f015";
$icon-hospital-o: "\f0f8";
$icon-hourglass: "\f254";
$icon-hourglass-1: "\f251";
$icon-hourglass-start: "\f251";
$icon-hourglass-2: "\f252";
$icon-hourglass-half: "\f252";
$icon-hourglass-3: "\f253";
$icon-hourglass-end: "\f253";
$icon-hourglass-o: "\f250";
$icon-houzz: "\f27c";
$icon-html5: "\f13b";
$icon-i-cursor: "\f246";
$icon-id-badge: "\f2c1";
$icon-ils: "\f20b";
$icon-shekel: "\f20b";
$icon-sheqel: "\f20b";
$icon-image: "\f03e";
$icon-photo1: "\f03e";
$icon-picture-o: "\f03e";
$icon-imdb: "\f2d8";
$icon-inbox1: "\f01c";
$icon-indent: "\f03c";
$icon-industry: "\f275";
$icon-info_alt: "\f129"; //ex info1
$icon-info-circle: "\f05a";
$icon-inr: "\f156";
$icon-rupee: "\f156";
$icon-instagram: "\f16d";
$icon-internet-explorer: "\f26b";
$icon-intersex: "\f224";
$icon-transgender: "\f224";
$icon-ioxhost: "\f208";
$icon-italic: "\f033";
$icon-joomla: "\f1aa";
$icon-jsfiddle: "\f1cc";
$icon-key: "\f084";
$icon-keyboard-o: "\f11c";
$icon-krw: "\f159";
$icon-won: "\f159";
$icon-language1: "\f1ab";
$icon-laptop1: "\f109";
$icon-lastfm: "\f202";
$icon-lastfm-square: "\f203";
$icon-leaf: "\f06c";
$icon-leanpub: "\f212";
$icon-lemon-o: "\f094";
$icon-level-down: "\f149";
$icon-level-up: "\f148";
$icon-life-bouy: "\f1cd";
$icon-life-buoy: "\f1cd";
$icon-life-ring: "\f1cd";
$icon-life-saver: "\f1cd";
$icon-support: "\f1cd";
$icon-lightbulb-o: "\f0eb";
$icon-line-chart: "\f201";
$icon-linkedin: "\f0e1";
$icon-linkedin-square: "\f08c";
$icon-linode: "\f2b8";
$icon-linux: "\f17c";
$icon-list1: "\f03a";
$icon-list-alt: "\f022";
$icon-list-ol: "\f0cb";
$icon-list-ul: "\f0ca";
$icon-location-arrow: "\f124";
$icon-lock1: "\f023";
$icon-long-arrow-down: "\f175";
$icon-long-arrow-left: "\f177";
$icon-long-arrow-right: "\f178";
$icon-long-arrow-up: "\f176";
$icon-low-vision: "\f2a8";
$icon-magic: "\f0d0";
$icon-magnet: "\f076";
$icon-mail-forward: "\f064";
$icon-share1: "\f064";
$icon-mail-reply: "\f112";
$icon-reply1: "\f112";
$icon-mail-reply-all: "\f122";
$icon-reply-all: "\f122";
$icon-male: "\f183";
$icon-map1: "\f279";
$icon-map-marker: "\f041";
$icon-map-o: "\f278";
$icon-map-pin: "\f276";
$icon-map-signs: "\f277";
$icon-mars: "\f222";
$icon-mars-double: "\f227";
$icon-mars-stroke: "\f229";
$icon-mars-stroke-h: "\f22b";
$icon-mars-stroke-v: "\f22a";
$icon-maxcdn: "\f136";
$icon-meanpath: "\f20c";
$icon-medium: "\f23a";
$icon-medkit: "\f0fa";
$icon-meetup: "\f2e0";
$icon-meh-o: "\f11a";
$icon-mercury: "\f223";
$icon-microchip: "\f2db";
$icon-microphone: "\f130";
$icon-microphone-slash: "\f131";
$icon-minus: "\f068";
$icon-minus-circle: "\f056";
$icon-minus-square: "\f146";
$icon-minus-square-o: "\f147";
$icon-mixcloud: "\f289";
$icon-mobile: "\f10b";
$icon-mobile-phone: "\f10b";
$icon-modx: "\f285";
$icon-money: "\f0d6";
$icon-moon-o: "\f186";
$icon-motorcycle1: "\f21c";
$icon-mouse-pointer: "\f245";
$icon-music: "\f001";
$icon-neuter: "\f22c";
$icon-newspaper-o: "\f1ea";
$icon-object-group: "\f247";
$icon-object-ungroup: "\f248";
$icon-odnoklassniki: "\f263";
$icon-odnoklassniki-square: "\f264";
$icon-opencart: "\f23d";
$icon-openid: "\f19b";
$icon-opera: "\f26a";
$icon-optin-monster: "\f23c";
$icon-pagelines: "\f18c";
$icon-paint-brush: "\f1fc";
$icon-paper-plane: "\f1d8";
$icon-send1: "\f1d8";
$icon-paper-plane-o: "\f1d9";
$icon-send-o: "\f1d9";
$icon-paperclip: "\f0c6";
$icon-paragraph: "\f1dd";
$icon-pause1: "\f04c";
$icon-pause-circle: "\f28b";
$icon-pause-circle-o: "\f28c";
$icon-paw: "\f1b0";
$icon-paypal: "\f1ed";
$icon-pencil: "\f040";
$icon-pencil-square: "\f14b";
$icon-percent: "\f295";
$icon-phone1: "\f095";
$icon-phone-square: "\f098";
$icon-pie-chart: "\f200";
$icon-pied-piper: "\f2ae";
$icon-pied-piper-alt: "\f1a8";
$icon-pied-piper-pp: "\f1a7";
$icon-pinterest: "\f0d2";
$icon-pinterest-p: "\f231";
$icon-pinterest-square: "\f0d3";
$icon-plane: "\f072";
$icon-play: "\f04b";
$icon-play-circle: "\f144";
$icon-play-circle-o: "\f01d";
$icon-plug: "\f1e6";
$icon-plus: "\f067";
$icon-plus-circle: "\f055";
$icon-plus-square: "\f0fe";
$icon-plus-square-o: "\f196";
$icon-podcast: "\f2ce";
$icon-power-off: "\f011";
$icon-print1: "\f02f";
$icon-product-hunt: "\f288";
$icon-puzzle-piece: "\f12e";
$icon-qq: "\f1d6";
$icon-qrcode: "\f029";
$icon-question: "\f128";
$icon-question-circle: "\f059";
$icon-question-circle-o: "\f29c";
$icon-quora: "\f2c4";
$icon-quote-left: "\f10d";
$icon-quote-right: "\f10e";
$icon-ra: "\f1d0";
$icon-rebel: "\f1d0";
$icon-resistance: "\f1d0";
$icon-random: "\f074";
$icon-ravelry: "\f2d9";
$icon-recycle: "\f1b8";
$icon-reddit: "\f1a1";
$icon-reddit-alien: "\f281";
$icon-reddit-square: "\f1a2";
$icon-refresh1: "\f021";
$icon-registered: "\f25d";
$icon-renren: "\f18b";
$icon-repeat1: "\f01e";
$icon-rotate-right: "\f01e";
$icon-retweet: "\f079";
$icon-road: "\f018";
$icon-rocket: "\f135";
$icon-rotate-left: "\f0e2";
$icon-undo1: "\f0e2";
$icon-rouble: "\f158";
$icon-rub: "\f158";
$icon-ruble: "\f158";
$icon-rss-square: "\f143";
$icon-safari: "\f267";
$icon-scribd: "\f28a";
$icon-search1: "\f002";
$icon-search-minus: "\f010";
$icon-search-plus: "\f00e";
$icon-sellsy: "\f213";
$icon-server: "\f233";
$icon-share-alt: "\f1e0";
$icon-share-alt-square: "\f1e1";
$icon-share-square: "\f14d";
$icon-share-square-o: "\f045";
$icon-shield: "\f132";
$icon-ship: "\f21a";
$icon-shirtsinbulk: "\f214";
$icon-shopping-bag: "\f290";
$icon-shopping-basket: "\f291";
$icon-shopping-cart: "\f07a";
$icon-shower: "\f2cc";
$icon-sign-in: "\f090";
$icon-sign-language: "\f2a7";
$icon-signing: "\f2a7";
$icon-sign-out: "\f08b";
$icon-signal: "\f012";
$icon-simplybuilt: "\f215";
$icon-sitemap: "\f0e8";
$icon-skyatlas: "\f216";
$icon-skype: "\f17e";
$icon-slack: "\f198";
$icon-sliders: "\f1de";
$icon-slideshare: "\f1e7";
$icon-smile-o: "\f118";
$icon-snapchat: "\f2ab";
$icon-snapchat-ghost: "\f2ac";
$icon-snapchat-square: "\f2ad";
$icon-snowflake-o: "\f2dc";
$icon-sort1: "\f0dc";
$icon-unsorted: "\f0dc";
$icon-sort-alpha-asc: "\f15d";
$icon-sort-alpha-desc: "\f15e";
$icon-sort-amount-asc: "\f160";
$icon-sort-amount-desc: "\f161";
$icon-sort-asc: "\f0de";
$icon-sort-up: "\f0de";
$icon-sort-desc: "\f0dd";
$icon-sort-down: "\f0dd";
$icon-sort-numeric-asc: "\f162";
$icon-sort-numeric-desc: "\f163";
$icon-soundcloud: "\f1be";
$icon-space-shuttle: "\f197";
$icon-spinner: "\f110";
$icon-spoon: "\f1b1";
$icon-spotify: "\f1bc";
$icon-square: "\f0c8";
$icon-square-o: "\f096";
$icon-stack-exchange: "\f18d";
$icon-stack-overflow: "\f16c";
$icon-star1: "\f005";
$icon-star-half: "\f089";
$icon-star-half-empty: "\f123";
$icon-star-half-full: "\f123";
$icon-star-half-o: "\f123";
$icon-star-o: "\f006";
$icon-steam: "\f1b6";
$icon-steam-square: "\f1b7";
$icon-step-backward: "\f048";
$icon-step-forward: "\f051";
$icon-stethoscope: "\f0f1";
$icon-sticky-note: "\f249";
$icon-sticky-note-o: "\f24a";
$icon-stop1: "\f04d";
$icon-stop-circle: "\f28d";
$icon-stop-circle-o: "\f28e";
$icon-street-view: "\f21d";
$icon-strikethrough: "\f0cc";
$icon-stumbleupon: "\f1a4";
$icon-stumbleupon-circle: "\f1a3";
$icon-subscript: "\f12c";
$icon-subway1: "\f239";
$icon-suitcase: "\f0f2";
$icon-sun-o: "\f185";
$icon-superpowers: "\f2dd";
$icon-superscript: "\f12b";
$icon-table: "\f0ce";
$icon-tablet1: "\f10a";
$icon-tag: "\f02b";
$icon-tags: "\f02c";
$icon-tasks: "\f0ae";
$icon-telegram: "\f2c6";
$icon-television: "\f26c";
$icon-tv1: "\f26c";
$icon-tencent-weibo: "\f1d5";
$icon-terminal: "\f120";
$icon-text-height: "\f034";
$icon-text-width: "\f035";
$icon-th: "\f00a";
$icon-th-large: "\f009";
$icon-th-list: "\f00b";
$icon-themeisle: "\f2b2";
$icon-thermometer: "\f2c7";
$icon-thermometer-4: "\f2c7";
$icon-thermometer-full: "\f2c7";
$icon-thermometer-0: "\f2cb";
$icon-thermometer-empty: "\f2cb";
$icon-thermometer-1: "\f2ca";
$icon-thermometer-quarter: "\f2ca";
$icon-thermometer-2: "\f2c9";
$icon-thermometer-half: "\f2c9";
$icon-thermometer-3: "\f2c8";
$icon-thermometer-three-quarters: "\f2c8";
$icon-thumb-tack: "\f08d";
$icon-thumbs-down: "\f165";
$icon-thumbs-o-down: "\f088";
$icon-thumbs-o-up: "\f087";
$icon-thumbs-up: "\f164";
$icon-ticket: "\f145";
$icon-times-circle: "\f057";
$icon-times-circle-o: "\f05c";
$icon-times-rectangle: "\f2d3";
$icon-window-close: "\f2d3";
$icon-times-rectangle-o: "\f2d4";
$icon-window-close-o: "\f2d4";
$icon-tint: "\f043";
$icon-toggle-off: "\f204";
$icon-toggle-on: "\f205";
$icon-trademark: "\f25c";
$icon-train1: "\f238";
$icon-transgender-alt: "\f225";
$icon-trash: "\f1f8";
$icon-trash-o: "\f014";
$icon-tree: "\f1bb";
$icon-trello: "\f181";
$icon-tripadvisor: "\f262";
$icon-trophy: "\f091";
$icon-truck: "\f0d1";
$icon-try: "\f195";
$icon-turkish-lira: "\f195";
$icon-tty: "\f1e4";
$icon-tumblr: "\f173";
$icon-tumblr-square: "\f174";
$icon-twitch: "\f1e8";
$icon-twitter: "\f099";
$icon-twitter-square: "\f081";
$icon-umbrella: "\f0e9";
$icon-underline: "\f0cd";
$icon-universal-access: "\f29a";
$icon-unlock: "\f09c";
$icon-unlock-alt: "\f13e";
$icon-upload: "\f093";
$icon-usb1: "\f287";
$icon-user: "\f007";
$icon-user-circle: "\f2bd";
$icon-user-circle-o: "\f2be";
$icon-user-md: "\f0f0";
$icon-user-o: "\f2c0";
$icon-user-plus: "\f234";
$icon-user-secret: "\f21b";
$icon-user-times: "\f235";
$icon-venus: "\f221";
$icon-venus-double: "\f226";
$icon-venus-mars: "\f228";
$icon-viacoin: "\f237";
$icon-viadeo: "\f2a9";
$icon-viadeo-square: "\f2aa";
$icon-video-camera: "\f03d";
$icon-vimeo: "\f27d";
$icon-vimeo-square: "\f194";
$icon-vine: "\f1ca";
$icon-vk: "\f189";
$icon-volume-control-phone: "\f2a0";
$icon-volume-down: "\f027";
$icon-volume-off: "\f026";
$icon-volume-up: "\f028";
$icon-wechat: "\f1d7";
$icon-weixin: "\f1d7";
$icon-weibo: "\f18a";
$icon-whatsapp: "\f232";
$icon-wheelchair: "\f193";
$icon-wheelchair-alt: "\f29b";
$icon-wifi1: "\f1eb";
$icon-wikipedia-w: "\f266";
$icon-window-maximize: "\f2d0";
$icon-window-minimize: "\f2d1";
$icon-window-restore: "\f2d2";
$icon-windows: "\f17a";
$icon-wordpress: "\f19a";
$icon-wpbeginner: "\f297";
$icon-wpexplorer: "\f2de";
$icon-wpforms: "\f298";
$icon-wrench: "\f0ad";
$icon-xing: "\f168";
$icon-xing-square: "\f169";
$icon-y-combinator: "\f23b";
$icon-yc: "\f23b";
$icon-yahoo: "\f19e";
$icon-yelp: "\f1e9";
$icon-yoast: "\f2b1";
$icon-youtube: "\f167";
$icon-youtube-play: "\f16a";
$icon-youtube-square: "\f166";
$icon-3d_rotation: "\e84d";
$icon-ac_unit: "\eb3b";
$icon-access_alarms: "\e191";
$icon-accessibility: "\e84e";
$icon-accessible: "\ea9b";
$icon-account_balance: "\e84f";
$icon-account_balance_wallet: "\e850";
$icon-account_box: "\e851";
$icon-account_circle: "\e853";
$icon-adb: "\e60e";
$icon-add: "\e145";
$icon-add_a_photo: "\e439";
$icon-add_alert: "\e003";
$icon-add_box: "\e146";
$icon-add_circle: "\e147";
$icon-add_location: "\e567";
$icon-add_shopping_cart: "\e854";
$icon-add_to_queue: "\e05c";
$icon-adjust: "\e39e";
$icon-airline_seat_flat: "\e630";
$icon-airline_seat_flat_angled: "\e631";
$icon-airline_seat_individual_suite: "\e632";
$icon-airline_seat_legroom_extra: "\e633";
$icon-airline_seat_legroom_normal: "\e634";
$icon-airline_seat_legroom_reduced: "\e635";
$icon-airline_seat_recline_extra: "\e636";
$icon-airline_seat_recline_normal: "\e637";
$icon-airplanemode_inactive: "\e194";
$icon-airplay: "\e055";
$icon-airport_shuttle: "\eb3c";
$icon-alarm: "\e855";
$icon-alarm_add: "\e856";
$icon-alarm_off: "\e857";
$icon-alarm_on: "\e858";
$icon-album: "\e019";
$icon-all_inclusive: "\eb3d";
$icon-all_out: "\ea9c";
$icon-android1: "\e859";
$icon-announcement: "\e85a";
$icon-apps: "\e5c3";
$icon-archive: "\e149";
$icon-arrow_back: "\e5c4";
$icon-arrow_downward: "\e5db";
$icon-arrow_drop_down: "\e5c5";
$icon-arrow_drop_down_circle: "\e5c6";
$icon-arrow_drop_up: "\e5c7";
$icon-arrow_forward: "\e5c8";
$icon-arrow_upward: "\e5d8";
$icon-art_track: "\e060";
$icon-aspect_ratio: "\e85b";
$icon-assignment: "\e85d";
$icon-assignment_ind: "\e85e";
$icon-assignment_late: "\e85f";
$icon-assignment_return: "\e860";
$icon-assignment_returned: "\e861";
$icon-assignment_turned_in: "\e862";
$icon-assistant: "\e39f";
$icon-attach_file: "\e226";
$icon-attach_money: "\e227";
$icon-attachment: "\e2bc";
$icon-audiotrack: "\e3a1";
$icon-autorenew: "\e863";
$icon-av_timer: "\e01b";
$icon-backspace: "\e14a";
$icon-battery_alert: "\e19c";
$icon-battery_charging_full: "\e1a3";
$icon-battery_std: "\e1a5";
$icon-battery_unknown: "\e1a6";
$icon-beach_access: "\eb3e";
$icon-beenhere: "\e52d";
$icon-block: "\e14b";
$icon-bluetooth: "\e1a7";
$icon-bluetooth_connected: "\e1a8";
$icon-bluetooth_disabled: "\e1a9";
$icon-bluetooth_searching: "\e1aa";
$icon-blur_circular: "\e3a2";
$icon-blur_linear: "\e3a3";
$icon-blur_off: "\e3a4";
$icon-blur_on: "\e3a5";
$icon-border_all: "\e228";
$icon-border_bottom: "\e229";
$icon-border_clear: "\e22a";
$icon-border_color: "\e22b";
$icon-border_horizontal: "\e22c";
$icon-border_inner: "\e22d";
$icon-border_left: "\e22e";
$icon-border_outer: "\e22f";
$icon-border_right: "\e230";
$icon-border_style: "\e231";
$icon-border_top: "\e232";
$icon-border_vertical: "\e233";
$icon-branding_watermark: "\e06b";
$icon-brightness_1: "\e3a6";
$icon-brightness_2: "\e3a7";
$icon-brightness_3: "\e3a8";
$icon-brightness_4: "\e3a9";
$icon-brightness_auto: "\e1ab";
$icon-brightness_high: "\e1ac";
$icon-brightness_low: "\e1ad";
$icon-brightness_medium: "\e1ae";
$icon-broken_image: "\e3ad";
$icon-brush: "\e3ae";
$icon-bubble_chart: "\e6dd";
$icon-bug_report: "\e868";
$icon-build: "\e869";
$icon-burst_mode: "\e43c";
$icon-business_center: "\eb3f";
$icon-cached: "\e86a";
$icon-cake: "\e7e9";
$icon-call_end: "\e0b1";
$icon-call_made: "\e0b2";
$icon-call_missed: "\e0b4";
$icon-call_missed_outgoing: "\e0e4";
$icon-call_received: "\e0b5";
$icon-call_split: "\e0b6";
$icon-call_to_action: "\e06c";
$icon-camera: "\e3af";
$icon-camera_enhance: "\e8fc";
$icon-camera_front: "\e3b1";
$icon-camera_rear: "\e3b2";
$icon-camera_roll: "\e3b3";
$icon-cancel: "\e5c9";
$icon-card_membership: "\e8f7";
$icon-card_travel: "\e8f8";
$icon-casino: "\eb40";
$icon-cast: "\e307";
$icon-cast_connected: "\e308";
$icon-center_focus_strong: "\e3b4";
$icon-center_focus_weak: "\e3b5";
$icon-change_history: "\e86b";
$icon-chat: "\e0b7";
$icon-chat_bubble: "\e0ca";
$icon-chat_bubble_outline: "\e0cb";
$icon-check: "\e5ca";
$icon-check_box: "\e834";
$icon-check_box_outline_blank: "\e835";
$icon-check_circle: "\e86c";
$icon-child_care: "\eb41";
$icon-child_friendly: "\eb42";
$icon-chrome_reader_mode: "\e86d";
$icon-class: "\e86e";
$icon-clear_all: "\e0b8";
$icon-close: "\e5cd";
$icon-closed_caption: "\e01c";
$icon-cloud_circle: "\e2be";
$icon-cloud_done: "\e2bf";
$icon-cloud_download: "\e2c0";
$icon-cloud_off: "\e2c1";
$icon-cloud_queue: "\e2c2";
$icon-cloud_upload: "\e2c3";
$icon-code: "\e86f";
$icon-collections_bookmark: "\e431";
$icon-colorize: "\e3b8";
$icon-comment: "\e0b9";
$icon-compare: "\e3b9";
$icon-compare_arrows: "\ea9d";
$icon-confirmation_number: "\e638";
$icon-contact_mail: "\e0d0";
$icon-contact_phone: "\e0cf";
$icon-contacts: "\e0ba";
$icon-content_copy: "\e14d";
$icon-content_cut: "\e14e";
$icon-content_paste: "\e14f";
$icon-control_point: "\e3ba";
$icon-control_point_duplicate: "\e3bb";
$icon-copyright: "\ea9e"; //ex copyright1
$icon-create_new_folder: "\e2cc";
$icon-crop: "\e3be";
$icon-crop_16_9: "\e3bc";
$icon-crop_3_2: "\e3bd";
$icon-crop_7_5: "\e3c0";
$icon-crop_din: "\e3c1";
$icon-crop_free: "\e3c2";
$icon-crop_landscape: "\e3c3";
$icon-crop_original: "\e3c4";
$icon-crop_portrait: "\e3c5";
$icon-crop_rotate: "\e437";
$icon-crop_square: "\e3c6";
$icon-dashboard: "\e871";
$icon-data_usage: "\e1af";
$icon-date_range: "\ea9f";
$icon-dehaze: "\e3c7";
$icon-delete: "\e872";
$icon-delete_forever: "\eaa0";
$icon-delete_sweep: "\e16c";
$icon-description: "\e873";
$icon-desktop_mac: "\e30b";
$icon-desktop_windows: "\e30c";
$icon-details: "\e3c8";
$icon-developer_board: "\e30d";
$icon-developer_mode: "\e1b0";
$icon-device_hub: "\e335";
$icon-devices_other: "\e337";
$icon-dialer_sip: "\e0bb";
$icon-dialpad: "\e0bc";
$icon-directions: "\e52e";
$icon-directions_bike: "\e52f";
$icon-directions_boat: "\e532";
$icon-directions_bus: "\e530";
$icon-directions_car: "\e531";
$icon-directions_railway: "\e534";
$icon-directions_run: "\e566";
$icon-directions_transit: "\e535";
$icon-directions_walk: "\e536";
$icon-disc_full: "\e610";
$icon-dns: "\e875";
$icon-do_not_disturb_alt: "\e611";
$icon-do_not_disturb_off: "\e643";
$icon-dock: "\e30e";
$icon-domain: "\e7ee";
$icon-done: "\e876";
$icon-done_all: "\e877";
$icon-donut_large: "\eaa1";
$icon-donut_small: "\eaa2";
$icon-drafts: "\e151";
$icon-drag_handle: "\e25d";
$icon-dvr: "\e1b2";
$icon-edit_location: "\e568";
$icon-eject: "\e8fb";
$icon-enhanced_encryption: "\e63f";
$icon-equalizer: "\e01d";
$icon-error: "\e000";
$icon-error_outline: "\e001";
$icon-euro_symbol: "\eaa3";
$icon-ev_station: "\e56d";
$icon-event_available: "\e614";
$icon-event_busy: "\e615";
$icon-event_note: "\e616";
$icon-event_seat: "\eaa4";
$icon-exit_to_app: "\e879";
$icon-expand_less: "\e5ce";
$icon-expand_more: "\e5cf";
$icon-explicit: "\e01e";
$icon-explore: "\e87a";
$icon-exposure: "\e3ca";
$icon-exposure_neg_1: "\e3cb";
$icon-exposure_neg_2: "\e3cc";
$icon-exposure_plus_1: "\e3cd";
$icon-exposure_plus_2: "\e3ce";
$icon-exposure_zero: "\e3cf";
$icon-extension: "\e87b";
$icon-face: "\e87c";
$icon-fast_forward: "\e01f";
$icon-fast_rewind: "\e020";
$icon-favorite: "\e87d";
$icon-favorite_border: "\e87e";
$icon-featured_play_list: "\e06d";
$icon-featured_video: "\e06e";
$icon-fiber_dvr: "\e05d";
$icon-fiber_manual_record: "\e061";
$icon-fiber_new: "\e05e";
$icon-fiber_pin: "\e06a";
$icon-fiber_smart_record: "\e062";
$icon-file_upload: "\e2c6";
$icon-filter: "\e3d3";
$icon-filter_1: "\e3d0";
$icon-filter_2: "\e3d1";
$icon-filter_3: "\e3d2";
$icon-filter_4: "\e3d4";
$icon-filter_5: "\e3d5";
$icon-filter_6: "\e3d6";
$icon-filter_7: "\e3d7";
$icon-filter_8: "\e3d8";
$icon-filter_9: "\e3d9";
$icon-filter_9_plus: "\e3da";
$icon-filter_b_and_w: "\e3db";
$icon-filter_center_focus: "\e3dc";
$icon-filter_drama: "\e3dd";
$icon-filter_frames: "\e3de";
$icon-filter_list: "\e152";
$icon-filter_none: "\e3e0";
$icon-filter_tilt_shift: "\e3e2";
$icon-filter_vintage: "\e3e3";
$icon-find_in_page: "\e880";
$icon-find_replace: "\e881";
$icon-fingerprint: "\eaa5";
$icon-first_page: "\e5dc";
$icon-fitness_center: "\eb43";
$icon-flag: "\e153";
$icon-flare: "\e3e4";
$icon-flash_auto: "\e3e5";
$icon-flash_off: "\e3e6";
$icon-flash_on: "\e3e7";
$icon-flight: "\e539";
$icon-flight_land: "\eaa6";
$icon-flight_takeoff: "\eaa7";
$icon-flip: "\e3e8";
$icon-flip_to_back: "\e882";
$icon-flip_to_front: "\e883";
$icon-folder: "\e2c7";
$icon-folder_open: "\e2c8";
$icon-folder_shared: "\e2c9";
$icon-folder_special: "\e617";
$icon-font_download: "\e167";
$icon-format_align_center: "\e234";
$icon-format_align_justify: "\e235";
$icon-format_align_left: "\e236";
$icon-format_align_right: "\e237";
$icon-format_bold: "\e238";
$icon-format_clear: "\e239";
$icon-format_color_fill: "\e23a";
$icon-format_color_reset: "\e23b";
$icon-format_color_text: "\e23c";
$icon-format_indent_decrease: "\e23d";
$icon-format_indent_increase: "\e23e";
$icon-format_italic: "\e23f";
$icon-format_line_spacing: "\e240";
$icon-format_list_bulleted: "\e241";
$icon-format_list_numbered: "\e242";
$icon-format_paint: "\e243";
$icon-format_quote: "\e244";
$icon-format_shapes: "\e25e";
$icon-format_size: "\e245";
$icon-format_strikethrough: "\e246";
$icon-format_textdirection_l_to_r: "\e247";
$icon-format_textdirection_r_to_l: "\e248";
$icon-format_underlined: "\e249";
$icon-forward: "\e154";
$icon-forward_10: "\e056";
$icon-forward_30: "\e057";
$icon-forward_5: "\e058";
$icon-free_breakfast: "\eb44";
$icon-fullscreen: "\e5d0";
$icon-fullscreen_exit: "\e5d1";
$icon-functions: "\e24a";
$icon-g_translate: "\eaa8";
$icon-games: "\e021";
$icon-gavel: "\eaa9";
$icon-gesture: "\e155";
$icon-get_app: "\e884";
$icon-gif: "\eaaa";
$icon-goat: "\eaab";
$icon-golf_course: "\eb45";
$icon-gradient: "\e3e9";
$icon-grain: "\e3ea";
$icon-graphic_eq: "\e1b8";
$icon-grid_off: "\e3eb";
$icon-grid_on: "\e3ec";
$icon-group_add: "\e7f0";
$icon-group_work: "\e886";
$icon-hd: "\e052";
$icon-hdr_off: "\e3ed";
$icon-hdr_on: "\e3ee";
$icon-hdr_strong: "\e3f1";
$icon-hdr_weak: "\e3f2";
$icon-headset: "\e310";
$icon-headset_mic: "\e311";
$icon-healing: "\e3f3";
$icon-hearing: "\e023";
$icon-help: "\e887";
$icon-help_outline: "\e8fd";
$icon-high_quality: "\e024";
$icon-highlight: "\e25f";
$icon-highlight_off: "\e888";
$icon-home: "\e88a";
$icon-hot_tub: "\eb46";
$icon-hourglass_empty: "\e88b";
$icon-hourglass_full: "\e88c";
$icon-http: "\eaac";
$icon-image_aspect_ratio: "\e3f5";
$icon-import_contacts: "\e0e0";
$icon-import_export: "\e0c3";
$icon-important_devices: "\eaad";
$icon-inbox: "\e156";
$icon-indeterminate_check_box: "\eaae";
$icon-info: "\e88e";
$icon-info_outline: "\e88f";
$icon-input: "\e890";
$icon-insert_comment: "\e24c";
$icon-insert_drive_file: "\e24d";
$icon-insert_invitation: "\e24f";
$icon-invert_colors: "\e891";
$icon-invert_colors_off: "\e0c4";
$icon-iso: "\e3f6";
$icon-keyboard: "\e312";
$icon-chevron_down: "\e313";
$icon-chevron_left: "\e314";
$icon-chevron_right: "\e315";
$icon-chevron_up: "\e316";
$icon-keyboard_backspace: "\e317";
$icon-keyboard_capslock: "\e318";
$icon-keyboard_hide: "\e31a";
$icon-keyboard_return: "\e31b";
$icon-keyboard_tab: "\e31c";
$icon-keyboard_voice: "\e31d";
$icon-kitchen: "\eb47";
$icon-label: "\e892";
$icon-label_outline: "\e893";
$icon-language: "\e894";
$icon-laptop: "\e31e";
$icon-laptop_chromebook: "\e31f";
$icon-laptop_mac: "\e320";
$icon-laptop_windows: "\e321";
$icon-last_page: "\e5dd";
$icon-layers: "\e53b";
$icon-layers_clear: "\e53c";
$icon-leak_add: "\e3f8";
$icon-leak_remove: "\e3f9";
$icon-lens: "\e3fa";
$icon-library_books: "\e02f";
$icon-library_music: "\e030";
$icon-lightbulb_outline: "\eaaf";
$icon-line_style: "\eab0";
$icon-line_weight: "\eab1";
$icon-linear_scale: "\e260";
$icon-link: "\e157";
$icon-linked_camera: "\e438";
$icon-list: "\e896";
$icon-live_help: "\e0c6";
$icon-live_tv: "\e639";
$icon-local_airport: "\e53d";
$icon-local_atm: "\e53e";
$icon-local_bar: "\e540";
$icon-local_cafe: "\e541";
$icon-local_car_wash: "\e542";
$icon-local_convenience_store: "\e543";
$icon-local_drink: "\e544";
$icon-local_florist: "\e545";
$icon-local_gas_station: "\e546";
$icon-local_hospital: "\e548";
$icon-local_hotel: "\e549";
$icon-local_laundry_service: "\e54a";
$icon-local_library: "\e54b";
$icon-local_mall: "\e54c";
$icon-local_offer: "\e54e";
$icon-local_parking: "\e54f";
$icon-local_pharmacy: "\e550";
$icon-local_pizza: "\e552";
$icon-local_play: "\e553";
$icon-local_shipping: "\e558";
$icon-local_taxi: "\e559";
$icon-location_city: "\e7f1";
$icon-location_disabled: "\e1b6";
$icon-location_off: "\e0c7";
$icon-location_searching: "\e1b7";
$icon-lock: "\e897";
$icon-lock_open: "\e898";
$icon-lock_outline: "\e899";
$icon-looks: "\e3fc";
$icon-looks_3: "\e3fb";
$icon-looks_4: "\e3fd";
$icon-looks_5: "\e3fe";
$icon-looks_6: "\e3ff";
$icon-looks_one: "\e400";
$icon-looks_two: "\e401";
$icon-loupe: "\e402";
$icon-low_priority: "\e16d";
$icon-loyalty: "\e89a";
$icon-mail_outline: "\e0e1";
$icon-map: "\e55b";
$icon-markunread: "\e159";
$icon-markunread_mailbox: "\e89b";
$icon-memory: "\e322";
$icon-menu: "\e5d2";
$icon-merge_type: "\e252";
$icon-message: "\e0c9";
$icon-mic: "\e029";
$icon-mic_none: "\e02a";
$icon-mic_off: "\e02b";
$icon-mms: "\e618";
$icon-mode_comment: "\e253";
$icon-mode_edit: "\e254";
$icon-monetization_on: "\e263";
$icon-money_off: "\e25c";
$icon-monochrome_photos: "\e403";
$icon-mood_bad: "\e7f3";
$icon-more: "\e619";
$icon-more_horiz: "\e5d3";
$icon-more_vert: "\e5d4";
$icon-motorcycle: "\eab2";
$icon-mouse: "\e323";
$icon-move_to_inbox: "\e168";
$icon-movie_creation: "\e404";
$icon-movie_filter: "\e43a";
$icon-multiline_chart: "\e6df";
$icon-music_note: "\e405";
$icon-music_video: "\e063";
$icon-my_location: "\e55c";
$icon-nature: "\e406";
$icon-nature_people: "\e407";
$icon-navigate_before: "\e408";
$icon-navigate_next: "\e409";
$icon-navigation: "\e55d";
$icon-near_me: "\e569";
$icon-network_cell: "\e1b9";
$icon-network_check: "\e640";
$icon-network_locked: "\e61a";
$icon-network_wifi: "\e1ba";
$icon-new_releases: "\e031";
$icon-next_week: "\e16a";
$icon-nfc: "\e1bb";
$icon-no_encryption: "\e641";
$icon-not_interested: "\e033";
$icon-note: "\e06f";
$icon-note_add: "\e89c";
$icon-notifications: "\e7f4";
$icon-notifications_active: "\e7f7";
$icon-notifications_none: "\e7f5";
$icon-notifications_off: "\e7f6";
$icon-notifications_paused: "\e7f8";
$icon-offline_pin: "\eab3";
$icon-ondemand_video: "\e63a";
$icon-opacity: "\eab4";
$icon-open_in_browser: "\e89d";
$icon-open_in_new: "\e89e";
$icon-open_with: "\e89f";
$icon-pages: "\e7f9";
$icon-pageview: "\e8a0";
$icon-palette: "\e40a";
$icon-pan_tool: "\eab5";
$icon-panorama: "\e40b";
$icon-panorama_horizontal: "\e40d";
$icon-panorama_vertical: "\e40e";
$icon-panorama_wide_angle: "\e40f";
$icon-party_mode: "\e7fa";
$icon-pause: "\e034";
$icon-pause_circle_filled: "\e035";
$icon-pause_circle_outline: "\e036";
$icon-payment: "\e8a1";
$icon-people: "\e7fb";
$icon-people_outline: "\e7fc";
$icon-perm_camera_mic: "\e8a2";
$icon-perm_contact_calendar: "\e8a3";
$icon-perm_data_setting: "\e8a4";
$icon-perm_device_information: "\e8a5";
$icon-perm_media: "\e8a7";
$icon-perm_phone_msg: "\e8a8";
$icon-perm_scan_wifi: "\e8a9";
$icon-person: "\e7fd";
$icon-person_add: "\e7fe";
$icon-person_outline: "\e7ff";
$icon-person_pin: "\e55a";
$icon-person_pin_circle: "\e56a";
$icon-personal_video: "\e63b";
$icon-pets: "\eab6";
$icon-phone: "\e0cd";
$icon-phone_android: "\e324";
$icon-phone_bluetooth_speaker: "\e61b";
$icon-phone_forwarded: "\e61c";
$icon-phone_in_talk: "\e61d";
$icon-phone_iphone: "\e325";
$icon-phone_locked: "\e61e";
$icon-phone_missed: "\e61f";
$icon-phone_paused: "\e620";
$icon-phonelink: "\e326";
$icon-phonelink_erase: "\e0db";
$icon-phonelink_lock: "\e0dc";
$icon-phonelink_off: "\e327";
$icon-phonelink_ring: "\e0dd";
$icon-phonelink_setup: "\e0de";
$icon-photo: "\e410";
$icon-photo_album: "\e411";
$icon-photo_camera: "\e412";
$icon-photo_filter: "\e43b";
$icon-photo_library: "\e413";
$icon-photo_size_select_actual: "\e432";
$icon-photo_size_select_large: "\e433";
$icon-photo_size_select_small: "\e434";
$icon-picture_as_pdf: "\e415";
$icon-picture_in_picture: "\e8aa";
$icon-picture_in_picture_alt: "\eab7";
$icon-pie_chart: "\e6c4";
$icon-pie_chart_outlined: "\e6c5";
$icon-pin_drop: "\e55e";
$icon-play_arrow: "\e037";
$icon-play_circle_filled: "\e038";
$icon-play_circle_outline: "\e039";
$icon-play_for_work: "\eab8";
$icon-playlist_add: "\e03b";
$icon-playlist_add_check: "\e065";
$icon-playlist_play: "\e05f";
$icon-plus_one: "\e800";
$icon-poll: "\e801";
$icon-polymer: "\e8ab";
$icon-pool: "\eb48";
$icon-portable_wifi_off: "\e0ce";
$icon-portrait: "\e416";
$icon-power: "\e63c";
$icon-power_input: "\e336";
$icon-power_settings_new: "\e8ac";
$icon-pregnant_woman: "\eab9";
$icon-present_to_all: "\e0df";
$icon-print: "\e8ad";
$icon-priority_high: "\e645";
$icon-public: "\e80b";
$icon-publish: "\e255";
$icon-question_answer: "\e8af";
$icon-queue: "\e03c";
$icon-queue_music: "\e03d";
$icon-queue_play_next: "\e066";
$icon-radio: "\e03e";
$icon-radio_button_checked: "\e837";
$icon-radio_button_unchecked: "\e836";
$icon-rate_review: "\e560";
$icon-receipt: "\e8b0";
$icon-recent_actors: "\e03f";
$icon-record_voice_over: "\eaba";
$icon-redeem: "\e8b1";
$icon-redo: "\e15a";
$icon-refresh: "\e5d5";
$icon-remove: "\e15b";
$icon-remove_circle: "\e15c";
$icon-remove_circle_outline: "\e15d";
$icon-remove_from_queue: "\e067";
$icon-remove_shopping_cart: "\eabb";
$icon-reorder: "\e8fe";
$icon-repeat: "\e040";
$icon-repeat_one: "\e041";
$icon-replay: "\e042";
$icon-replay_10: "\e059";
$icon-replay_30: "\e05a";
$icon-replay_5: "\e05b";
$icon-reply: "\e15e";
$icon-reply_all: "\e15f";
$icon-report: "\e160";
$icon-restaurant: "\e56c";
$icon-restaurant_menu: "\e561";
$icon-restore: "\e8b3";
$icon-restore_page: "\eabc";
$icon-ring_volume: "\e0d1";
$icon-room: "\e8b4";
$icon-place: "\e8b4";
$icon-room_service: "\eb49";
$icon-rotate_90_degrees_ccw: "\e418";
$icon-rotate_left: "\e419";
$icon-rotate_right: "\e41a";
$icon-rounded_corner: "\eabd";
$icon-router: "\e328";
$icon-rowing: "\eabe";
$icon-rss_feed: "\e0e5";
$icon-rv_hookup: "\e642";
$icon-satellite: "\e562";
$icon-save: "\e161";
$icon-scanner: "\e329";
$icon-schedule: "\e8b5";
$icon-school: "\e80c";
$icon-screen_lock_landscape: "\e1be";
$icon-screen_lock_portrait: "\e1bf";
$icon-screen_lock_rotation: "\e1c0";
$icon-screen_rotation: "\e1c1";
$icon-screen_share: "\e0e2";
$icon-sd_storage: "\e1c2";
$icon-search: "\e8b6";
$icon-security: "\e32a";
$icon-select_all: "\e162";
$icon-send: "\e163";
$icon-sentiment_dissatisfied: "\e811";
$icon-sentiment_neutral: "\e812";
$icon-sentiment_satisfied: "\e813";
$icon-sentiment_very_dissatisfied: "\e814";
$icon-sentiment_very_satisfied: "\e815";
$icon-settings: "\e8b8";
$icon-settings_applications: "\e8b9";
$icon-settings_backup_restore: "\e8ba";
$icon-settings_bluetooth: "\e8bb";
$icon-settings_brightness: "\e8bd";
$icon-settings_cell: "\e8bc";
$icon-settings_ethernet: "\e8be";
$icon-settings_input_antenna: "\e8bf";
$icon-settings_input_composite: "\e8c1";
$icon-settings_input_hdmi: "\e8c2";
$icon-settings_input_svideo: "\e8c3";
$icon-settings_overscan: "\e8c4";
$icon-settings_phone: "\e8c5";
$icon-settings_power: "\e8c6";
$icon-settings_remote: "\e8c7";
$icon-settings_system_daydream: "\e1c3";
$icon-settings_voice: "\e8c8";
$icon-share: "\e80d";
$icon-shop: "\e8c9";
$icon-shop_two: "\e8ca";
$icon-shopping_basket: "\e8cb";
$icon-shopping_cart: "\e8cc";
$icon-short_text: "\e261";
$icon-show_chart: "\e6e1";
$icon-shuffle: "\e043";
$icon-signal_cellular_4_bar: "\e1c8";
$icon-signal_cellular_connected_no_internet_4_bar: "\e1cd";
$icon-signal_cellular_no_sim: "\e1ce";
$icon-signal_cellular_null: "\e1cf";
$icon-signal_cellular_off: "\e1d0";
$icon-signal_wifi_4_bar: "\e1d8";
$icon-signal_wifi_4_bar_lock: "\e1d9";
$icon-signal_wifi_off: "\e1da";
$icon-sim_card: "\e32b";
$icon-sim_card_alert: "\e624";
$icon-skip_next: "\e044";
$icon-skip_previous: "\e045";
$icon-slideshow: "\e41b";
$icon-slow_motion_video: "\e068";
$icon-smoke_free: "\eb4a";
$icon-smoking_rooms: "\eb4b";
$icon-sms_failed: "\e626";
$icon-snooze: "\e046";
$icon-sort: "\e164";
$icon-sort_by_alpha: "\e053";
$icon-spa: "\eb4c";
$icon-space_bar: "\e256";
$icon-speaker: "\e32d";
$icon-speaker_group: "\e32e";
$icon-speaker_notes: "\e8cd";
$icon-speaker_notes_off: "\eabf";
$icon-speaker_phone: "\e0d2";
$icon-spellcheck: "\e8ce";
$icon-star: "\e838";
$icon-star_border: "\e83a";
$icon-star_half: "\e839";
$icon-stars: "\e8d0";
$icon-stay_primary_landscape: "\e0d5";
$icon-stay_primary_portrait: "\e0d6";
$icon-stop: "\e047";
$icon-stop_screen_share: "\e0e3";
$icon-storage: "\e1db";
$icon-store_mall_directory: "\e563";
$icon-straighten: "\e41c";
$icon-streetview: "\e56e";
$icon-strikethrough_s: "\e257";
$icon-style: "\e41d";
$icon-subdirectory_arrow_left: "\e5d9";
$icon-subdirectory_arrow_right: "\e5da";
$icon-subject: "\e8d2";
$icon-subscriptions: "\e064";
$icon-subtitles: "\e048";
$icon-subway: "\e56f";
$icon-supervisor_account: "\e8d3";
$icon-surround_sound: "\e049";
$icon-swap_calls: "\e0d7";
$icon-swap_horiz: "\e8d4";
$icon-swap_vert: "\e8d5";
$icon-swap_vertical_circle: "\e8d6";
$icon-switch_camera: "\e41e";
$icon-switch_video: "\e41f";
$icon-sync: "\e627";
$icon-sync_disabled: "\e628";
$icon-sync_problem: "\e629";
$icon-system_update: "\e62a";
$icon-system_update_alt: "\e8d7";
$icon-tab: "\e8d8";
$icon-tab_unselected: "\e8d9";
$icon-tablet: "\e32f";
$icon-tablet_android: "\e330";
$icon-tablet_mac: "\e331";
$icon-tag_faces: "\e420";
$icon-tap_and_play: "\e62b";
$icon-terrain: "\e564";
$icon-text_fields: "\e262";
$icon-text_format: "\e165";
$icon-textsms: "\e0d8";
$icon-texture: "\e421";
$icon-theaters: "\e8da";
$icon-thumb_down: "\e8db";
$icon-thumb_up: "\e8dc";
$icon-thumbs_up_down: "\e8dd";
$icon-time_to_leave: "\e62c";
$icon-timelapse: "\e422";
$icon-timeline: "\eac0";
$icon-timer: "\e425";
$icon-timer_10: "\e423";
$icon-timer_3: "\e424";
$icon-timer_off: "\e426";
$icon-title: "\e264";
$icon-toc: "\e8de";
$icon-today: "\e8df";
$icon-toll: "\e8e0";
$icon-tonality: "\e427";
$icon-touch_app: "\eac1";
$icon-toys: "\e332";
$icon-track_changes: "\e8e1";
$icon-traffic: "\e565";
$icon-train: "\e570";
$icon-tram: "\e571";
$icon-transfer_within_a_station: "\e572";
$icon-transform: "\e428";
$icon-translate: "\e8e2";
$icon-trending_down: "\e8e3";
$icon-trending_flat: "\e8e4";
$icon-trending_up: "\e8e5";
$icon-tune: "\e429";
$icon-turned_in: "\e8e6";
$icon-turned_in_not: "\e8e7";
$icon-tv: "\e333";
$icon-unarchive: "\e169";
$icon-undo: "\e166";
$icon-unfold_less: "\e5d6";
$icon-unfold_more: "\e5d7";
$icon-update: "\eac2";
$icon-usb: "\e1e0";
$icon-verified_user: "\e8e8";
$icon-vertical_align_bottom: "\e258";
$icon-vertical_align_center: "\e259";
$icon-vertical_align_top: "\e25a";
$icon-vibration: "\e62d";
$icon-video_call: "\e070";
$icon-video_label: "\e071";
$icon-video_library: "\e04a";
$icon-videocam: "\e04b";
$icon-videocam_off: "\e04c";
$icon-videogame_asset: "\e338";
$icon-view_agenda: "\e8e9";
$icon-view_array: "\e8ea";
$icon-view_carousel: "\e8eb";
$icon-view_column: "\e8ec";
$icon-view_comfy: "\e42a";
$icon-view_compact: "\e42b";
$icon-view_day: "\e8ed";
$icon-view_headline: "\e8ee";
$icon-view_list: "\e8ef";
$icon-view_module: "\e8f0";
$icon-view_quilt: "\e8f1";
$icon-view_stream: "\e8f2";
$icon-view_week: "\e8f3";
$icon-vignette: "\e435";
$icon-visibility: "\e8f4";
$icon-visibility_off: "\e8f5";
$icon-voice_chat: "\e62e";
$icon-voicemail: "\e0d9";
$icon-volume_down: "\e04d";
$icon-volume_mute: "\e04e";
$icon-volume_off: "\e04f";
$icon-volume_up: "\e050";
$icon-vpn_key: "\e0da";
$icon-vpn_lock: "\e62f";
$icon-wallpaper: "\e1bc";
$icon-warning: "\e002";
$icon-watch: "\e334";
$icon-watch_later: "\eac3";
$icon-wb_auto: "\e42c";
$icon-wb_cloudy: "\e42d";
$icon-wb_incandescent: "\e42e";
$icon-wb_iridescent: "\e436";
$icon-wb_sunny: "\e430";
$icon-wc: "\e63d";
$icon-web: "\e051";
$icon-web_asset: "\e069";
$icon-weekend: "\e16b";
$icon-whatshot: "\e80e";
$icon-widgets: "\e1bd";
$icon-wifi: "\e63e";
$icon-wifi_lock: "\e1e1";
$icon-wifi_tethering: "\e1e2";
$icon-work: "\e8f9";
$icon-wrap_text: "\e25b";
$icon-youtube_searched_for: "\e8fa";
$icon-zoom_in: "\e8ff";
$icon-zoom_out: "\eac4";
$icon-zoom_out_map: "\e56b";

$social_icons_map: (
	facebook: $icon-facebook,
	twitter: $icon-twitter,
	googleplus: $icon-google_plus,
	linkedin: $icon-linkedin,
	youtube: $icon-youtube_play,
	instagram: $icon-instagram,
	shortlink: $icon-link,
	feed: $icon-feed
);