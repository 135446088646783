///////////////////////////////////////////////
/////////////////////////////////////////////// Modules
///////////////////////////////////////////////
.card {
	display: flex;

	background-color: transparent;

	////////
	//////// Elements
	////////
	&__header {
		position: relative;

		// force embedded code to behave
		iframe, object, embed {
			display: block;
			width: 100%;
		}

		&--featured {
			border-top: .25em solid map-get(map-get($colors_map, backup), regular);
		}
	}

	&__date {
		display: inline-block;
		font-weight: bold;
		font-size: map-get($text_sizes, big);
		color: $color_white;
		text-align: center;
		text-transform: uppercase;
		padding: .5em 1em;
		background: map-get(map-get($colors_map, backup), regular);
		// border-radius: 0 0 $base_border_radius 0;
	}

	&__figure, &__graph {
		flex: 1;
		position: relative;
		padding: 0;
		margin: 0;

		.card--horizontal--balanced & {
			flex: 2;
		}

		&--featured {
			padding: $base_padding $base_padding 0 $base_padding;
			border-top: .25em solid map-get(map-get($colors_map, main), regular);

			@include media( medium-up ){
				.card--horizontal & {
					padding: $base_padding 0 $base_padding $base_padding;
					border-top: 0;
					border-left: .25em solid map-get(map-get($colors_map, main), regular);
				}
			}
		}
	}

	&__graph{
		>*{
			margin: 0 auto;
		}
	}

	&__body {
		flex: 2;
		flex-direction: column;
		padding: .5rem;
		.card--inversed &, .card--inversed & a { color: $color_white; }

		.card--fancy & {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba($color_black, .7);

			@include media( medium-down ){
				position: static;
				background: map-get(map-get($colors_map, grey), dark);
			}
		}
	}

	&__figure, &__body{
		display: block;
		max-width: 100%;
	}

	&__meta {
		font-size: map-get($text_sizes, tiny);
		line-height: 1.2;
		color: map-get(map-get($colors_map, grey), dark);
		margin: 0 0 .5rem;

		.card--inversed & { color: $color_white; }
	}

	&__title {
		display: block;
		font-weight: bold;
		text-decoration: none;
		margin: map-get($text_sizes, tiny) 0;
		font-size: 1.25rem;
		line-height: 1.3;
		margin: 0 0 1rem;

		&:first-child { margin-top: 0; }

		a {
			display: block;
			text-decoration: none;
			color: map-get(map-get($colors_map, main), regular);
			// transform: translateX(0);
			transition: $base_transition;

			&:hover {
				// transform: translateX(map-get($text_sizes, tiny));
				color: map-get(map-get($colors_map, main), dark);
			}
		}

		.card--inversed & {
			color: $color_white;
			a:hover { color: map-get(map-get($colors_map, main), regular); }
		}

		.card--fancy & {
			color: $color_white;

			a {
				color: $color_white;
				&:hover { color: map-get(map-get($colors_map, main), regular); }
			}
		}

		&--featured {
			font-size: map-get($text_sizes, bigger);
		}
	}

	&__excerpt {
		@include get-text-size($base_font_size, $size_6);
	}

	&__data{
		display: flex;
		align-items: center;
		&__item{
			flex: 1;
			.card{
				&__title{
					margin: 0 0 .5rem;
				}
				&__meta{
					margin: 0;
					+.card__meta{
						margin: .25rem 0 0;
					}
				}
			}
			+.card__data__item{
				margin-left: .5rem;
			}
		}
	}



	&--seamless {
		background: transparent;
		box-shadow: none;
	}

	&--inversed {
		background: map-get(map-get($colors_map, grey), dark);
	}

	&--main {
		background: map-get(map-get($colors_map, main), regular);
		color: $color_white;
	}

	&--highlighted {
		background: map-get(map-get($colors_map, grey), light);
		border: 1px solid map-get(map-get($colors_map, grey), light);
	}

	&--featured, &--featured-balanced {
		.card__figure { flex: 2; }
		.card__body { flex: 1; }

		.card__title {
			font-size: map-get($text_sizes, tall);
		}
	}

	&--featured {
		.card__figure { flex: 2; }
		.card__body { flex: 1; }
	}

	&--featured-balanced {
		.card__figure, .card__body { flex: 1; }
	}

	&--fancy {
		position: relative;
		background: map-get(map-get($colors_map, grey), dark);
		box-shadow: none;
		&:not(:first-child) { margin: 0; }
	}

	&--horizontal {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		flex-direction: row;
		.card{
			&__figure{
				flex: 1;
			}
		}
		>*{
			flex: 2;
			max-width: 50%;
		}
		&--align-start {
			align-items: flex-start;
		}
		&--align-end {
			align-items: flex-end;
		}
		&--triple {
			padding: .5rem;
			width: 100%
		}
	}

	&__table{
		overflow-y: auto;
		-webkit-overflow-scrolling: auto;
	}
}

.card{
	>*{ background: $color_light }
	&--small{
		@extend .gr-2, .gr-4\@desktop, .gr-6\@tablet, .gr-12\@small;
		>*{
			padding: .5rem;
		}
	}
	&--smallium{
		@extend .gr-3, .gr-6\@tablet, .gr-12\@small;
		>*{
			padding: .75rem;
		}
	}
	&--medium{
		@extend .gr-4, .gr-12\@small;
		>*{
			padding: 1rem
		}
	}
	&--big{
		@extend .gr-6, .gr-12\@small;
		>*{
			padding: 1rem
			// padding: 2rem
		}
	}
}
