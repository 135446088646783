$font-family: "material-ketchup" !default;
$font-path: $fonts_path;

@font-face {
  font-family: '#{$font-family}';
  src: url('#{$font-path}/#{$font-family}/#{$font-family}.eot?2qf61v');
  src: url('#{$font-path}/#{$font-family}/#{$font-family}.eot?2qf61v#iefix') format('embedded-opentype'),
  url('#{$font-path}/#{$font-family}/#{$font-family}.ttf?2qf61v') format('truetype'),
  url('#{$font-path}/#{$font-family}/#{$font-family}.woff?2qf61v') format('woff'),
  url('#{$font-path}/#{$font-family}/#{$font-family}.svg?2qf61v##{$font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon-font-family {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-font-family {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: unset;
  line-height: 1.4;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  @extend %icon-font-family
}

.icon-elem {
  @extend .icon
}

.icon-500px {
  &:before {
    content: $icon-500px;
  }
}

.icon-address-book {
  &:before {
    content: $icon-address-book;
  }
}

.icon-address-book-o {
  &:before {
    content: $icon-address-book-o;
  }
}

.icon-address-card {
  &:before {
    content: $icon-address-card;
  }
}

.icon-vcard {
  &:before {
    content: $icon-vcard;
  }
}

.icon-address-card-o {
  &:before {
    content: $icon-address-card-o;
  }
}

.icon-vcard-o {
  &:before {
    content: $icon-vcard-o;
  }
}

.icon-adjust1 {
  &:before {
    content: $icon-adjust1;
  }
}

.icon-adn {
  &:before {
    content: $icon-adn;
  }
}

.icon-align-center {
  &:before {
    content: $icon-align-center;
  }
}

.icon-align-justify {
  &:before {
    content: $icon-align-justify;
  }
}

.icon-align-left {
  &:before {
    content: $icon-align-left;
  }
}

.icon-align-right {
  &:before {
    content: $icon-align-right;
  }
}

.icon-amazon {
  &:before {
    content: $icon-amazon;
  }
}

.icon-ambulance {
  &:before {
    content: $icon-ambulance;
  }
}

.icon-american-sign-language-interpreting {
  &:before {
    content: $icon-american-sign-language-interpreting;
  }
}

.icon-asl-interpreting {
  &:before {
    content: $icon-asl-interpreting;
  }
}

.icon-anchor {
  &:before {
    content: $icon-anchor;
  }
}

.icon-android {
  &:before {
    content: $icon-android;
  }
}

.icon-angellist {
  &:before {
    content: $icon-angellist;
  }
}

.icon-angle-double-down {
  &:before {
    content: $icon-angle-double-down;
  }
}

.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left;
  }
}

.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right;
  }
}

.icon-angle-double-up {
  &:before {
    content: $icon-angle-double-up;
  }
}

.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}

.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}

.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}

.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}

.icon-apple {
  &:before {
    content: $icon-apple;
  }
}

.icon-archive1 {
  &:before {
    content: $icon-archive1;
  }
}

.icon-area-chart {
  &:before {
    content: $icon-area-chart;
  }
}

.icon-arrow-circle-down {
  &:before {
    content: $icon-arrow-circle-down;
  }
}

.icon-arrow-circle-left {
  &:before {
    content: $icon-arrow-circle-left;
  }
}

.icon-arrow-circle-o-down {
  &:before {
    content: $icon-arrow-circle-o-down;
  }
}

.icon-arrow-circle-o-left {
  &:before {
    content: $icon-arrow-circle-o-left;
  }
}

.icon-arrow-circle-o-right {
  &:before {
    content: $icon-arrow-circle-o-right;
  }
}

.icon-arrow-circle-o-up {
  &:before {
    content: $icon-arrow-circle-o-up;
  }
}

.icon-arrow-circle-right {
  &:before {
    content: $icon-arrow-circle-right;
  }
}

.icon-arrow-circle-up {
  &:before {
    content: $icon-arrow-circle-up;
  }
}

.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}

.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}

.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}

.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}

.icon-arrows {
  &:before {
    content: $icon-arrows;
  }
}

.icon-arrows-alt {
  &:before {
    content: $icon-arrows-alt;
  }
}

.icon-arrows-h {
  &:before {
    content: $icon-arrows-h;
  }
}

.icon-arrows-v {
  &:before {
    content: $icon-arrows-v;
  }
}

.icon-assistive-listening-systems {
  &:before {
    content: $icon-assistive-listening-systems;
  }
}

.icon-asterisk {
  &:before {
    content: $icon-asterisk;
  }
}

.icon-at {
  &:before {
    content: $icon-at;
  }
}

.icon-audio-description {
  &:before {
    content: $icon-audio-description;
  }
}

.icon-automobile {
  &:before {
    content: $icon-automobile;
  }
}

.icon-car {
  &:before {
    content: $icon-car;
  }
}

.icon-backward {
  &:before {
    content: $icon-backward;
  }
}

.icon-balance-scale {
  &:before {
    content: $icon-balance-scale;
  }
}

.icon-ban {
  &:before {
    content: $icon-ban;
  }
}

.icon-bandcamp {
  &:before {
    content: $icon-bandcamp;
  }
}

.icon-bank {
  &:before {
    content: $icon-bank;
  }
}

.icon-institution {
  &:before {
    content: $icon-institution;
  }
}

.icon-university {
  &:before {
    content: $icon-university;
  }
}

.icon-bar-chart {
  &:before {
    content: $icon-bar-chart;
  }
}

.icon-bar-chart-o {
  &:before {
    content: $icon-bar-chart-o;
  }
}

.icon-barcode {
  &:before {
    content: $icon-barcode;
  }
}

.icon-bars {
  &:before {
    content: $icon-bars;
  }
}

.icon-navicon {
  &:before {
    content: $icon-navicon;
  }
}

.icon-reorder1 {
  &:before {
    content: $icon-reorder1;
  }
}

.icon-bath {
  &:before {
    content: $icon-bath;
  }
}

.icon-bathtub {
  &:before {
    content: $icon-bathtub;
  }
}

.icon-s15 {
  &:before {
    content: $icon-s15;
  }
}

.icon-battery {
  &:before {
    content: $icon-battery;
  }
}

.icon-battery-4 {
  &:before {
    content: $icon-battery-4;
  }
}

.icon-battery-full {
  &:before {
    content: $icon-battery-full;
  }
}

.icon-battery-0 {
  &:before {
    content: $icon-battery-0;
  }
}

.icon-battery-empty {
  &:before {
    content: $icon-battery-empty;
  }
}

.icon-battery-1 {
  &:before {
    content: $icon-battery-1;
  }
}

.icon-battery-quarter {
  &:before {
    content: $icon-battery-quarter;
  }
}

.icon-battery-2 {
  &:before {
    content: $icon-battery-2;
  }
}

.icon-battery-half {
  &:before {
    content: $icon-battery-half;
  }
}

.icon-battery-3 {
  &:before {
    content: $icon-battery-3;
  }
}

.icon-battery-three-quarters {
  &:before {
    content: $icon-battery-three-quarters;
  }
}

.icon-bed {
  &:before {
    content: $icon-bed;
  }
}

.icon-hotel {
  &:before {
    content: $icon-hotel;
  }
}

.icon-beer {
  &:before {
    content: $icon-beer;
  }
}

.icon-behance {
  &:before {
    content: $icon-behance;
  }
}

.icon-behance-square {
  &:before {
    content: $icon-behance-square;
  }
}

.icon-bell {
  &:before {
    content: $icon-bell;
  }
}

.icon-bell-o {
  &:before {
    content: $icon-bell-o;
  }
}

.icon-bell-slash {
  &:before {
    content: $icon-bell-slash;
  }
}

.icon-bell-slash-o {
  &:before {
    content: $icon-bell-slash-o;
  }
}

.icon-bicycle {
  &:before {
    content: $icon-bicycle;
  }
}

.icon-binoculars {
  &:before {
    content: $icon-binoculars;
  }
}

.icon-birthday-cake {
  &:before {
    content: $icon-birthday-cake;
  }
}

.icon-bitbucket {
  &:before {
    content: $icon-bitbucket;
  }
}

.icon-bitbucket-square {
  &:before {
    content: $icon-bitbucket-square;
  }
}

.icon-bitcoin {
  &:before {
    content: $icon-bitcoin;
  }
}

.icon-btc {
  &:before {
    content: $icon-btc;
  }
}

.icon-black-tie {
  &:before {
    content: $icon-black-tie;
  }
}

.icon-blind {
  &:before {
    content: $icon-blind;
  }
}

.icon-bluetooth1 {
  &:before {
    content: $icon-bluetooth1;
  }
}

.icon-bluetooth-b {
  &:before {
    content: $icon-bluetooth-b;
  }
}

.icon-bold {
  &:before {
    content: $icon-bold;
  }
}

.icon-bolt {
  &:before {
    content: $icon-bolt;
  }
}

.icon-flash {
  &:before {
    content: $icon-flash;
  }
}

.icon-bomb {
  &:before {
    content: $icon-bomb;
  }
}

.icon-book {
  &:before {
    content: $icon-book;
  }
}

.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}

.icon-bookmark-o {
  &:before {
    content: $icon-bookmark-o;
  }
}

.icon-braille {
  &:before {
    content: $icon-braille;
  }
}

.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}

.icon-bug {
  &:before {
    content: $icon-bug;
  }
}

.icon-building {
  &:before {
    content: $icon-building;
  }
}

.icon-building-o {
  &:before {
    content: $icon-building-o;
  }
}

.icon-bullhorn {
  &:before {
    content: $icon-bullhorn;
  }
}

.icon-bullseye {
  &:before {
    content: $icon-bullseye;
  }
}

.icon-bus {
  &:before {
    content: $icon-bus;
  }
}

.icon-buysellads {
  &:before {
    content: $icon-buysellads;
  }
}

.icon-cab {
  &:before {
    content: $icon-cab;
  }
}

.icon-taxi {
  &:before {
    content: $icon-taxi;
  }
}

.icon-calculator {
  &:before {
    content: $icon-calculator;
  }
}

.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}

.icon-calendar-check-o {
  &:before {
    content: $icon-calendar-check-o;
  }
}

.icon-calendar-minus-o {
  &:before {
    content: $icon-calendar-minus-o;
  }
}

.icon-calendar-o {
  &:before {
    content: $icon-calendar-o;
  }
}

.icon-calendar-plus-o {
  &:before {
    content: $icon-calendar-plus-o;
  }
}

.icon-calendar-times-o {
  &:before {
    content: $icon-calendar-times-o;
  }
}

.icon-camera1 {
  &:before {
    content: $icon-camera1;
  }
}

.icon-camera-retro {
  &:before {
    content: $icon-camera-retro;
  }
}

.icon-caret-down {
  &:before {
    content: $icon-caret-down;
  }
}

.icon-caret-left {
  &:before {
    content: $icon-caret-left;
  }
}

.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}

.icon-caret-square-o-down {
  &:before {
    content: $icon-caret-square-o-down;
  }
}

.icon-toggle-down {
  &:before {
    content: $icon-toggle-down;
  }
}

.icon-caret-square-o-left {
  &:before {
    content: $icon-caret-square-o-left;
  }
}

.icon-toggle-left {
  &:before {
    content: $icon-toggle-left;
  }
}

.icon-caret-square-o-right {
  &:before {
    content: $icon-caret-square-o-right;
  }
}

.icon-toggle-right {
  &:before {
    content: $icon-toggle-right;
  }
}

.icon-caret-square-o-up {
  &:before {
    content: $icon-caret-square-o-up;
  }
}

.icon-toggle-up {
  &:before {
    content: $icon-toggle-up;
  }
}

.icon-caret-up {
  &:before {
    content: $icon-caret-up;
  }
}

.icon-cart-arrow-down {
  &:before {
    content: $icon-cart-arrow-down;
  }
}

.icon-cart-plus {
  &:before {
    content: $icon-cart-plus;
  }
}

.icon-cc {
  &:before {
    content: $icon-cc;
  }
}

.icon-cc-amex {
  &:before {
    content: $icon-cc-amex;
  }
}

.icon-cc-diners-club {
  &:before {
    content: $icon-cc-diners-club;
  }
}

.icon-cc-discover {
  &:before {
    content: $icon-cc-discover;
  }
}

.icon-cc-jcb {
  &:before {
    content: $icon-cc-jcb;
  }
}

.icon-cc-mastercard {
  &:before {
    content: $icon-cc-mastercard;
  }
}

.icon-cc-paypal {
  &:before {
    content: $icon-cc-paypal;
  }
}

.icon-cc-stripe {
  &:before {
    content: $icon-cc-stripe;
  }
}

.icon-cc-visa {
  &:before {
    content: $icon-cc-visa;
  }
}

.icon-certificate {
  &:before {
    content: $icon-certificate;
  }
}

.icon-chain {
  &:before {
    content: $icon-chain;
  }
}

.icon-link1 {
  &:before {
    content: $icon-link1;
  }
}

.icon-chain-broken {
  &:before {
    content: $icon-chain-broken;
  }
}

.icon-unlink {
  &:before {
    content: $icon-unlink;
  }
}

.icon-check1 {
  &:before {
    content: $icon-check1;
  }
}

.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}

.icon-check-circle-o {
  &:before {
    content: $icon-check-circle-o;
  }
}

.icon-check-square {
  &:before {
    content: $icon-check-square;
  }
}

.icon-check-square-o {
  &:before {
    content: $icon-check-square-o;
  }
}

.icon-chevron-circle-down {
  &:before {
    content: $icon-chevron-circle-down;
  }
}

.icon-chevron-circle-left {
  &:before {
    content: $icon-chevron-circle-left;
  }
}

.icon-chevron-circle-right {
  &:before {
    content: $icon-chevron-circle-right;
  }
}

.icon-chevron-circle-up {
  &:before {
    content: $icon-chevron-circle-up;
  }
}

.icon-chevron_bold_down {
  &:before {
    content: $icon-chevron_bold_down;
  }
}

.icon-chevron_bold_left {
  &:before {
    content: $icon-chevron_bold_left;
  }
}

.icon-chevron_bold_right {
  &:before {
    content: $icon-chevron_bold_right;
  }
}

.icon-chevron_bold_up {
  &:before {
    content: $icon-chevron_bold_up;
  }
}

.icon-child {
  &:before {
    content: $icon-child;
  }
}

.icon-chrome {
  &:before {
    content: $icon-chrome;
  }
}

.icon-circle {
  &:before {
    content: $icon-circle;
  }
}

.icon-circle-o {
  &:before {
    content: $icon-circle-o;
  }
}

.icon-circle-o-notch {
  &:before {
    content: $icon-circle-o-notch;
  }
}

.icon-circle-thin {
  &:before {
    content: $icon-circle-thin;
  }
}

.icon-clipboard {
  &:before {
    content: $icon-clipboard;
  }
}

.icon-paste {
  &:before {
    content: $icon-paste;
  }
}

.icon-clock-o {
  &:before {
    content: $icon-clock-o;
  }
}

.icon-clone {
  &:before {
    content: $icon-clone;
  }
}

.icon-close1 {
  &:before {
    content: $icon-close1;
  }
}

.icon-remove1 {
  &:before {
    content: $icon-remove1;
  }
}

.icon-times {
  &:before {
    content: $icon-times;
  }
}

.icon-cloud {
  &:before {
    content: $icon-cloud;
  }
}

.icon-cloud-download {
  &:before {
    content: $icon-cloud-download;
  }
}

.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload;
  }
}

.icon-cny {
  &:before {
    content: $icon-cny;
  }
}

.icon-jpy {
  &:before {
    content: $icon-jpy;
  }
}

.icon-rmb {
  &:before {
    content: $icon-rmb;
  }
}

.icon-yen {
  &:before {
    content: $icon-yen;
  }
}

.icon-code1 {
  &:before {
    content: $icon-code1;
  }
}

.icon-code-fork {
  &:before {
    content: $icon-code-fork;
  }
}

.icon-codepen {
  &:before {
    content: $icon-codepen;
  }
}

.icon-codiepie {
  &:before {
    content: $icon-codiepie;
  }
}

.icon-coffee {
  &:before {
    content: $icon-coffee;
  }
}

.icon-cog {
  &:before {
    content: $icon-cog;
  }
}

.icon-gear {
  &:before {
    content: $icon-gear;
  }
}

.icon-cogs {
  &:before {
    content: $icon-cogs;
  }
}

.icon-gears {
  &:before {
    content: $icon-gears;
  }
}

.icon-columns {
  &:before {
    content: $icon-columns;
  }
}

.icon-comment_bubble {
  &:before {
    content: $icon-comment_bubble;
  }
}

.icon-comment-o {
  &:before {
    content: $icon-comment-o;
  }
}

.icon-commenting {
  &:before {
    content: $icon-commenting;
  }
}

.icon-commenting-o {
  &:before {
    content: $icon-commenting-o;
  }
}

.icon-comments {
  &:before {
    content: $icon-comments;
  }
}

.icon-comments-o {
  &:before {
    content: $icon-comments-o;
  }
}

.icon-compass {
  &:before {
    content: $icon-compass;
  }
}

.icon-compress {
  &:before {
    content: $icon-compress;
  }
}

.icon-connectdevelop {
  &:before {
    content: $icon-connectdevelop;
  }
}

.icon-contao {
  &:before {
    content: $icon-contao;
  }
}

.icon-copy {
  &:before {
    content: $icon-copy;
  }
}

.icon-files-o {
  &:before {
    content: $icon-files-o;
  }
}

.icon-copyright_alt {
  &:before {
    content: $icon-copyright_alt;
  }
}

.icon-creative-commons {
  &:before {
    content: $icon-creative-commons;
  }
}

.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}

.icon-credit-card-alt {
  &:before {
    content: $icon-credit-card-alt;
  }
}

.icon-crop1 {
  &:before {
    content: $icon-crop1;
  }
}

.icon-crosshairs {
  &:before {
    content: $icon-crosshairs;
  }
}

.icon-css3 {
  &:before {
    content: $icon-css3;
  }
}

.icon-cube {
  &:before {
    content: $icon-cube;
  }
}

.icon-cubes {
  &:before {
    content: $icon-cubes;
  }
}

.icon-cut {
  &:before {
    content: $icon-cut;
  }
}

.icon-scissors {
  &:before {
    content: $icon-scissors;
  }
}

.icon-cutlery {
  &:before {
    content: $icon-cutlery;
  }
}

.icon-dashboard1 {
  &:before {
    content: $icon-dashboard1;
  }
}

.icon-tachometer {
  &:before {
    content: $icon-tachometer;
  }
}

.icon-dashcube {
  &:before {
    content: $icon-dashcube;
  }
}

.icon-database {
  &:before {
    content: $icon-database;
  }
}

.icon-deaf {
  &:before {
    content: $icon-deaf;
  }
}

.icon-deafness {
  &:before {
    content: $icon-deafness;
  }
}

.icon-hard-of-hearing {
  &:before {
    content: $icon-hard-of-hearing;
  }
}

.icon-dedent {
  &:before {
    content: $icon-dedent;
  }
}

.icon-outdent {
  &:before {
    content: $icon-outdent;
  }
}

.icon-delicious {
  &:before {
    content: $icon-delicious;
  }
}

.icon-desktop {
  &:before {
    content: $icon-desktop;
  }
}

.icon-deviantart {
  &:before {
    content: $icon-deviantart;
  }
}

.icon-diamond {
  &:before {
    content: $icon-diamond;
  }
}

.icon-digg {
  &:before {
    content: $icon-digg;
  }
}

.icon-dollar {
  &:before {
    content: $icon-dollar;
  }
}

.icon-usd {
  &:before {
    content: $icon-usd;
  }
}

.icon-dot-circle-o {
  &:before {
    content: $icon-dot-circle-o;
  }
}

.icon-download {
  &:before {
    content: $icon-download;
  }
}

.icon-dribbble {
  &:before {
    content: $icon-dribbble;
  }
}

.icon-drivers-license {
  &:before {
    content: $icon-drivers-license;
  }
}

.icon-id-card {
  &:before {
    content: $icon-id-card;
  }
}

.icon-drivers-license-o {
  &:before {
    content: $icon-drivers-license-o;
  }
}

.icon-id-card-o {
  &:before {
    content: $icon-id-card-o;
  }
}

.icon-dropbox {
  &:before {
    content: $icon-dropbox;
  }
}

.icon-drupal {
  &:before {
    content: $icon-drupal;
  }
}

.icon-edge {
  &:before {
    content: $icon-edge;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}

.icon-pencil-square-o {
  &:before {
    content: $icon-pencil-square-o;
  }
}

.icon-eercast {
  &:before {
    content: $icon-eercast;
  }
}

.icon-eject1 {
  &:before {
    content: $icon-eject1;
  }
}

.icon-ellipsis-h {
  &:before {
    content: $icon-ellipsis-h;
  }
}

.icon-ellipsis-v {
  &:before {
    content: $icon-ellipsis-v;
  }
}

.icon-empire {
  &:before {
    content: $icon-empire;
  }
}

.icon-ge {
  &:before {
    content: $icon-ge;
  }
}

.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}

.icon-envelope-o {
  &:before {
    content: $icon-envelope-o;
  }
}

.icon-envelope-open {
  &:before {
    content: $icon-envelope-open;
  }
}

.icon-envelope-open-o {
  &:before {
    content: $icon-envelope-open-o;
  }
}

.icon-envelope-square {
  &:before {
    content: $icon-envelope-square;
  }
}

.icon-envira {
  &:before {
    content: $icon-envira;
  }
}

.icon-eraser {
  &:before {
    content: $icon-eraser;
  }
}

.icon-etsy {
  &:before {
    content: $icon-etsy;
  }
}

.icon-eur {
  &:before {
    content: $icon-eur;
  }
}

.icon-euro {
  &:before {
    content: $icon-euro;
  }
}

.icon-exchange {
  &:before {
    content: $icon-exchange;
  }
}

.icon-exclamation {
  &:before {
    content: $icon-exclamation;
  }
}

.icon-exclamation-circle {
  &:before {
    content: $icon-exclamation-circle;
  }
}

.icon-exclamation-triangle {
  &:before {
    content: $icon-exclamation-triangle;
  }
}

.icon-warning1 {
  &:before {
    content: $icon-warning1;
  }
}

.icon-expand {
  &:before {
    content: $icon-expand;
  }
}

.icon-expeditedssl {
  &:before {
    content: $icon-expeditedssl;
  }
}

.icon-external-link {
  &:before {
    content: $icon-external-link;
  }
}

.icon-external-link-square {
  &:before {
    content: $icon-external-link-square;
  }
}

.icon-eye {
  &:before {
    content: $icon-eye;
  }
}

.icon-eye-slash {
  &:before {
    content: $icon-eye-slash;
  }
}

.icon-eyedropper {
  &:before {
    content: $icon-eyedropper;
  }
}

.icon-fa {
  &:before {
    content: $icon-fa;
  }
}

.icon-font-awesome {
  &:before {
    content: $icon-font-awesome;
  }
}

.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

.icon-facebook-f {
  &:before {
    content: $icon-facebook-f;
  }
}

.icon-facebook-official {
  &:before {
    content: $icon-facebook-official;
  }
}

.icon-facebook-square {
  &:before {
    content: $icon-facebook-square;
  }
}

.icon-fast-backward {
  &:before {
    content: $icon-fast-backward;
  }
}

.icon-fast-forward {
  &:before {
    content: $icon-fast-forward;
  }
}

.icon-fax {
  &:before {
    content: $icon-fax;
  }
}

.icon-feed {
  &:before {
    content: $icon-feed;
  }
}

.icon-rss {
  &:before {
    content: $icon-rss;
  }
}

.icon-female {
  &:before {
    content: $icon-female;
  }
}

.icon-fighter-jet {
  &:before {
    content: $icon-fighter-jet;
  }
}

.icon-file {
  &:before {
    content: $icon-file;
  }
}

.icon-file-archive-o {
  &:before {
    content: $icon-file-archive-o;
  }
}

.icon-file-zip-o {
  &:before {
    content: $icon-file-zip-o;
  }
}

.icon-file-audio-o {
  &:before {
    content: $icon-file-audio-o;
  }
}

.icon-file-sound-o {
  &:before {
    content: $icon-file-sound-o;
  }
}

.icon-file-code-o {
  &:before {
    content: $icon-file-code-o;
  }
}

.icon-file-excel-o {
  &:before {
    content: $icon-file-excel-o;
  }
}

.icon-file-image-o {
  &:before {
    content: $icon-file-image-o;
  }
}

.icon-file-photo-o {
  &:before {
    content: $icon-file-photo-o;
  }
}

.icon-file-picture-o {
  &:before {
    content: $icon-file-picture-o;
  }
}

.icon-file-movie-o {
  &:before {
    content: $icon-file-movie-o;
  }
}

.icon-file-video-o {
  &:before {
    content: $icon-file-video-o;
  }
}

.icon-file-o {
  &:before {
    content: $icon-file-o;
  }
}

.icon-file-pdf-o {
  &:before {
    content: $icon-file-pdf-o;
  }
}

.icon-file-powerpoint-o {
  &:before {
    content: $icon-file-powerpoint-o;
  }
}

.icon-file-text {
  &:before {
    content: $icon-file-text;
  }
}

.icon-file-text-o {
  &:before {
    content: $icon-file-text-o;
  }
}

.icon-file-word-o {
  &:before {
    content: $icon-file-word-o;
  }
}

.icon-film {
  &:before {
    content: $icon-film;
  }
}

.icon-filter1 {
  &:before {
    content: $icon-filter1;
  }
}

.icon-fire {
  &:before {
    content: $icon-fire;
  }
}

.icon-fire-extinguisher {
  &:before {
    content: $icon-fire-extinguisher;
  }
}

.icon-firefox {
  &:before {
    content: $icon-firefox;
  }
}

.icon-first-order {
  &:before {
    content: $icon-first-order;
  }
}

.icon-flag1 {
  &:before {
    content: $icon-flag1;
  }
}

.icon-flag-checkered {
  &:before {
    content: $icon-flag-checkered;
  }
}

.icon-flag-o {
  &:before {
    content: $icon-flag-o;
  }
}

.icon-flask {
  &:before {
    content: $icon-flask;
  }
}

.icon-flickr {
  &:before {
    content: $icon-flickr;
  }
}

.icon-floppy-o {
  &:before {
    content: $icon-floppy-o;
  }
}

.icon-save1 {
  &:before {
    content: $icon-save1;
  }
}

.icon-folder1 {
  &:before {
    content: $icon-folder1;
  }
}

.icon-folder-o {
  &:before {
    content: $icon-folder-o;
  }
}

.icon-folder-open {
  &:before {
    content: $icon-folder-open;
  }
}

.icon-folder-open-o {
  &:before {
    content: $icon-folder-open-o;
  }
}

.icon-font {
  &:before {
    content: $icon-font;
  }
}

.icon-fonticons {
  &:before {
    content: $icon-fonticons;
  }
}

.icon-fort-awesome {
  &:before {
    content: $icon-fort-awesome;
  }
}

.icon-forumbee {
  &:before {
    content: $icon-forumbee;
  }
}

.icon-forward1 {
  &:before {
    content: $icon-forward1;
  }
}

.icon-foursquare {
  &:before {
    content: $icon-foursquare;
  }
}

.icon-free-code-camp {
  &:before {
    content: $icon-free-code-camp;
  }
}

.icon-frown-o {
  &:before {
    content: $icon-frown-o;
  }
}

.icon-futbol-o {
  &:before {
    content: $icon-futbol-o;
  }
}

.icon-soccer-ball-o {
  &:before {
    content: $icon-soccer-ball-o;
  }
}

.icon-gamepad {
  &:before {
    content: $icon-gamepad;
  }
}

.icon-gavel1 {
  &:before {
    content: $icon-gavel1;
  }
}

.icon-legal {
  &:before {
    content: $icon-legal;
  }
}

.icon-gbp {
  &:before {
    content: $icon-gbp;
  }
}

.icon-genderless {
  &:before {
    content: $icon-genderless;
  }
}

.icon-get-pocket {
  &:before {
    content: $icon-get-pocket;
  }
}

.icon-gg {
  &:before {
    content: $icon-gg;
  }
}

.icon-gg-circle {
  &:before {
    content: $icon-gg-circle;
  }
}

.icon-gift {
  &:before {
    content: $icon-gift;
  }
}

.icon-git {
  &:before {
    content: $icon-git;
  }
}

.icon-git-square {
  &:before {
    content: $icon-git-square;
  }
}

.icon-github {
  &:before {
    content: $icon-github;
  }
}

.icon-github-alt {
  &:before {
    content: $icon-github-alt;
  }
}

.icon-github-square {
  &:before {
    content: $icon-github-square;
  }
}

.icon-gitlab {
  &:before {
    content: $icon-gitlab;
  }
}

.icon-gittip {
  &:before {
    content: $icon-gittip;
  }
}

.icon-gratipay {
  &:before {
    content: $icon-gratipay;
  }
}

.icon-glass {
  &:before {
    content: $icon-glass;
  }
}

.icon-glide {
  &:before {
    content: $icon-glide;
  }
}

.icon-glide-g {
  &:before {
    content: $icon-glide-g;
  }
}

.icon-globe {
  &:before {
    content: $icon-globe;
  }
}

.icon-google {
  &:before {
    content: $icon-google;
  }
}

.icon-google-plus {
  &:before {
    content: $icon-google-plus;
  }
}

.icon-google-plus-circle {
  &:before {
    content: $icon-google-plus-circle;
  }
}

.icon-google-plus-official {
  &:before {
    content: $icon-google-plus-official;
  }
}

.icon-google-plus-square {
  &:before {
    content: $icon-google-plus-square;
  }
}

.icon-google-wallet {
  &:before {
    content: $icon-google-wallet;
  }
}

.icon-graduation-cap {
  &:before {
    content: $icon-graduation-cap;
  }
}

.icon-mortar-board {
  &:before {
    content: $icon-mortar-board;
  }
}

.icon-grav {
  &:before {
    content: $icon-grav;
  }
}

.icon-group {
  &:before {
    content: $icon-group;
  }
}

.icon-users {
  &:before {
    content: $icon-users;
  }
}

.icon-h-square {
  &:before {
    content: $icon-h-square;
  }
}

.icon-hacker-news {
  &:before {
    content: $icon-hacker-news;
  }
}

.icon-y-combinator-square {
  &:before {
    content: $icon-y-combinator-square;
  }
}

.icon-yc-square {
  &:before {
    content: $icon-yc-square;
  }
}

.icon-hand-grab-o {
  &:before {
    content: $icon-hand-grab-o;
  }
}

.icon-hand-rock-o {
  &:before {
    content: $icon-hand-rock-o;
  }
}

.icon-hand-lizard-o {
  &:before {
    content: $icon-hand-lizard-o;
  }
}

.icon-hand-o-down {
  &:before {
    content: $icon-hand-o-down;
  }
}

.icon-hand-o-left {
  &:before {
    content: $icon-hand-o-left;
  }
}

.icon-hand-o-right {
  &:before {
    content: $icon-hand-o-right;
  }
}

.icon-hand-o-up {
  &:before {
    content: $icon-hand-o-up;
  }
}

.icon-hand-paper-o {
  &:before {
    content: $icon-hand-paper-o;
  }
}

.icon-hand-stop-o {
  &:before {
    content: $icon-hand-stop-o;
  }
}

.icon-hand-peace-o {
  &:before {
    content: $icon-hand-peace-o;
  }
}

.icon-hand-pointer-o {
  &:before {
    content: $icon-hand-pointer-o;
  }
}

.icon-hand-scissors-o {
  &:before {
    content: $icon-hand-scissors-o;
  }
}

.icon-hand-spock-o {
  &:before {
    content: $icon-hand-spock-o;
  }
}

.icon-handshake-o {
  &:before {
    content: $icon-handshake-o;
  }
}

.icon-hashtag {
  &:before {
    content: $icon-hashtag;
  }
}

.icon-hdd-o {
  &:before {
    content: $icon-hdd-o;
  }
}

.icon-header {
  &:before {
    content: $icon-header;
  }
}

.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}

.icon-heart {
  &:before {
    content: $icon-heart;
  }
}

.icon-heart-o {
  &:before {
    content: $icon-heart-o;
  }
}

.icon-heartbeat {
  &:before {
    content: $icon-heartbeat;
  }
}

.icon-history {
  &:before {
    content: $icon-history;
  }
}

.icon-home1 {
  &:before {
    content: $icon-home1;
  }
}

.icon-hospital-o {
  &:before {
    content: $icon-hospital-o;
  }
}

.icon-hourglass {
  &:before {
    content: $icon-hourglass;
  }
}

.icon-hourglass-1 {
  &:before {
    content: $icon-hourglass-1;
  }
}

.icon-hourglass-start {
  &:before {
    content: $icon-hourglass-start;
  }
}

.icon-hourglass-2 {
  &:before {
    content: $icon-hourglass-2;
  }
}

.icon-hourglass-half {
  &:before {
    content: $icon-hourglass-half;
  }
}

.icon-hourglass-3 {
  &:before {
    content: $icon-hourglass-3;
  }
}

.icon-hourglass-end {
  &:before {
    content: $icon-hourglass-end;
  }
}

.icon-hourglass-o {
  &:before {
    content: $icon-hourglass-o;
  }
}

.icon-houzz {
  &:before {
    content: $icon-houzz;
  }
}

.icon-html5 {
  &:before {
    content: $icon-html5;
  }
}

.icon-i-cursor {
  &:before {
    content: $icon-i-cursor;
  }
}

.icon-id-badge {
  &:before {
    content: $icon-id-badge;
  }
}

.icon-ils {
  &:before {
    content: $icon-ils;
  }
}

.icon-shekel {
  &:before {
    content: $icon-shekel;
  }
}

.icon-sheqel {
  &:before {
    content: $icon-sheqel;
  }
}

.icon-image {
  &:before {
    content: $icon-image;
  }
}

.icon-photo1 {
  &:before {
    content: $icon-photo1;
  }
}

.icon-picture-o {
  &:before {
    content: $icon-picture-o;
  }
}

.icon-imdb {
  &:before {
    content: $icon-imdb;
  }
}

.icon-inbox1 {
  &:before {
    content: $icon-inbox1;
  }
}

.icon-indent {
  &:before {
    content: $icon-indent;
  }
}

.icon-industry {
  &:before {
    content: $icon-industry;
  }
}

.icon-info_alt {
  &:before {
    content: $icon-info_alt;
  }
}

.icon-info-circle {
  &:before {
    content: $icon-info-circle;
  }
}

.icon-inr {
  &:before {
    content: $icon-inr;
  }
}

.icon-rupee {
  &:before {
    content: $icon-rupee;
  }
}

.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}

.icon-internet-explorer {
  &:before {
    content: $icon-internet-explorer;
  }
}

.icon-intersex {
  &:before {
    content: $icon-intersex;
  }
}

.icon-transgender {
  &:before {
    content: $icon-transgender;
  }
}

.icon-ioxhost {
  &:before {
    content: $icon-ioxhost;
  }
}

.icon-italic {
  &:before {
    content: $icon-italic;
  }
}

.icon-joomla {
  &:before {
    content: $icon-joomla;
  }
}

.icon-jsfiddle {
  &:before {
    content: $icon-jsfiddle;
  }
}

.icon-key {
  &:before {
    content: $icon-key;
  }
}

.icon-keyboard-o {
  &:before {
    content: $icon-keyboard-o;
  }
}

.icon-krw {
  &:before {
    content: $icon-krw;
  }
}

.icon-won {
  &:before {
    content: $icon-won;
  }
}

.icon-language1 {
  &:before {
    content: $icon-language1;
  }
}

.icon-laptop1 {
  &:before {
    content: $icon-laptop1;
  }
}

.icon-lastfm {
  &:before {
    content: $icon-lastfm;
  }
}

.icon-lastfm-square {
  &:before {
    content: $icon-lastfm-square;
  }
}

.icon-leaf {
  &:before {
    content: $icon-leaf;
  }
}

.icon-leanpub {
  &:before {
    content: $icon-leanpub;
  }
}

.icon-lemon-o {
  &:before {
    content: $icon-lemon-o;
  }
}

.icon-level-down {
  &:before {
    content: $icon-level-down;
  }
}

.icon-level-up {
  &:before {
    content: $icon-level-up;
  }
}

.icon-life-bouy {
  &:before {
    content: $icon-life-bouy;
  }
}

.icon-life-buoy {
  &:before {
    content: $icon-life-buoy;
  }
}

.icon-life-ring {
  &:before {
    content: $icon-life-ring;
  }
}

.icon-life-saver {
  &:before {
    content: $icon-life-saver;
  }
}

.icon-support {
  &:before {
    content: $icon-support;
  }
}

.icon-lightbulb-o {
  &:before {
    content: $icon-lightbulb-o;
  }
}

.icon-line-chart {
  &:before {
    content: $icon-line-chart;
  }
}

.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}

.icon-linkedin-square {
  &:before {
    content: $icon-linkedin-square;
  }
}

.icon-linode {
  &:before {
    content: $icon-linode;
  }
}

.icon-linux {
  &:before {
    content: $icon-linux;
  }
}

.icon-list1 {
  &:before {
    content: $icon-list1;
  }
}

.icon-list-alt {
  &:before {
    content: $icon-list-alt;
  }
}

.icon-list-ol {
  &:before {
    content: $icon-list-ol;
  }
}

.icon-list-ul {
  &:before {
    content: $icon-list-ul;
  }
}

.icon-location-arrow {
  &:before {
    content: $icon-location-arrow;
  }
}

.icon-lock1 {
  &:before {
    content: $icon-lock1;
  }
}

.icon-long-arrow-down {
  &:before {
    content: $icon-long-arrow-down;
  }
}

.icon-long-arrow-left {
  &:before {
    content: $icon-long-arrow-left;
  }
}

.icon-long-arrow-right {
  &:before {
    content: $icon-long-arrow-right;
  }
}

.icon-long-arrow-up {
  &:before {
    content: $icon-long-arrow-up;
  }
}

.icon-low-vision {
  &:before {
    content: $icon-low-vision;
  }
}

.icon-magic {
  &:before {
    content: $icon-magic;
  }
}

.icon-magnet {
  &:before {
    content: $icon-magnet;
  }
}

.icon-mail-forward {
  &:before {
    content: $icon-mail-forward;
  }
}

.icon-share1 {
  &:before {
    content: $icon-share1;
  }
}

.icon-mail-reply {
  &:before {
    content: $icon-mail-reply;
  }
}

.icon-reply1 {
  &:before {
    content: $icon-reply1;
  }
}

.icon-mail-reply-all {
  &:before {
    content: $icon-mail-reply-all;
  }
}

.icon-reply-all {
  &:before {
    content: $icon-reply-all;
  }
}

.icon-male {
  &:before {
    content: $icon-male;
  }
}

.icon-map1 {
  &:before {
    content: $icon-map1;
  }
}

.icon-map-marker {
  &:before {
    content: $icon-map-marker;
  }
}

.icon-map-o {
  &:before {
    content: $icon-map-o;
  }
}

.icon-map-pin {
  &:before {
    content: $icon-map-pin;
  }
}

.icon-map-signs {
  &:before {
    content: $icon-map-signs;
  }
}

.icon-mars {
  &:before {
    content: $icon-mars;
  }
}

.icon-mars-double {
  &:before {
    content: $icon-mars-double;
  }
}

.icon-mars-stroke {
  &:before {
    content: $icon-mars-stroke;
  }
}

.icon-mars-stroke-h {
  &:before {
    content: $icon-mars-stroke-h;
  }
}

.icon-mars-stroke-v {
  &:before {
    content: $icon-mars-stroke-v;
  }
}

.icon-maxcdn {
  &:before {
    content: $icon-maxcdn;
  }
}

.icon-meanpath {
  &:before {
    content: $icon-meanpath;
  }
}

.icon-medium {
  &:before {
    content: $icon-medium;
  }
}

.icon-medkit {
  &:before {
    content: $icon-medkit;
  }
}

.icon-meetup {
  &:before {
    content: $icon-meetup;
  }
}

.icon-meh-o {
  &:before {
    content: $icon-meh-o;
  }
}

.icon-mercury {
  &:before {
    content: $icon-mercury;
  }
}

.icon-microchip {
  &:before {
    content: $icon-microchip;
  }
}

.icon-microphone {
  &:before {
    content: $icon-microphone;
  }
}

.icon-microphone-slash {
  &:before {
    content: $icon-microphone-slash;
  }
}

.icon-minus {
  &:before {
    content: $icon-minus;
  }
}

.icon-minus-circle {
  &:before {
    content: $icon-minus-circle;
  }
}

.icon-minus-square {
  &:before {
    content: $icon-minus-square;
  }
}

.icon-minus-square-o {
  &:before {
    content: $icon-minus-square-o;
  }
}

.icon-mixcloud {
  &:before {
    content: $icon-mixcloud;
  }
}

.icon-mobile {
  &:before {
    content: $icon-mobile;
  }
}

.icon-mobile-phone {
  &:before {
    content: $icon-mobile-phone;
  }
}

.icon-modx {
  &:before {
    content: $icon-modx;
  }
}

.icon-money {
  &:before {
    content: $icon-money;
  }
}

.icon-moon-o {
  &:before {
    content: $icon-moon-o;
  }
}

.icon-motorcycle1 {
  &:before {
    content: $icon-motorcycle1;
  }
}

.icon-mouse-pointer {
  &:before {
    content: $icon-mouse-pointer;
  }
}

.icon-music {
  &:before {
    content: $icon-music;
  }
}

.icon-neuter {
  &:before {
    content: $icon-neuter;
  }
}

.icon-newspaper-o {
  &:before {
    content: $icon-newspaper-o;
  }
}

.icon-object-group {
  &:before {
    content: $icon-object-group;
  }
}

.icon-object-ungroup {
  &:before {
    content: $icon-object-ungroup;
  }
}

.icon-odnoklassniki {
  &:before {
    content: $icon-odnoklassniki;
  }
}

.icon-odnoklassniki-square {
  &:before {
    content: $icon-odnoklassniki-square;
  }
}

.icon-opencart {
  &:before {
    content: $icon-opencart;
  }
}

.icon-openid {
  &:before {
    content: $icon-openid;
  }
}

.icon-opera {
  &:before {
    content: $icon-opera;
  }
}

.icon-optin-monster {
  &:before {
    content: $icon-optin-monster;
  }
}

.icon-pagelines {
  &:before {
    content: $icon-pagelines;
  }
}

.icon-paint-brush {
  &:before {
    content: $icon-paint-brush;
  }
}

.icon-paper-plane {
  &:before {
    content: $icon-paper-plane;
  }
}

.icon-send1 {
  &:before {
    content: $icon-send1;
  }
}

.icon-paper-plane-o {
  &:before {
    content: $icon-paper-plane-o;
  }
}

.icon-send-o {
  &:before {
    content: $icon-send-o;
  }
}

.icon-paperclip {
  &:before {
    content: $icon-paperclip;
  }
}

.icon-paragraph {
  &:before {
    content: $icon-paragraph;
  }
}

.icon-pause1 {
  &:before {
    content: $icon-pause1;
  }
}

.icon-pause-circle {
  &:before {
    content: $icon-pause-circle;
  }
}

.icon-pause-circle-o {
  &:before {
    content: $icon-pause-circle-o;
  }
}

.icon-paw {
  &:before {
    content: $icon-paw;
  }
}

.icon-paypal {
  &:before {
    content: $icon-paypal;
  }
}

.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}

.icon-pencil-square {
  &:before {
    content: $icon-pencil-square;
  }
}

.icon-percent {
  &:before {
    content: $icon-percent;
  }
}

.icon-phone1 {
  &:before {
    content: $icon-phone1;
  }
}

.icon-phone-square {
  &:before {
    content: $icon-phone-square;
  }
}

.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}

.icon-pied-piper {
  &:before {
    content: $icon-pied-piper;
  }
}

.icon-pied-piper-alt {
  &:before {
    content: $icon-pied-piper-alt;
  }
}

.icon-pied-piper-pp {
  &:before {
    content: $icon-pied-piper-pp;
  }
}

.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}

.icon-pinterest-p {
  &:before {
    content: $icon-pinterest-p;
  }
}

.icon-pinterest-square {
  &:before {
    content: $icon-pinterest-square;
  }
}

.icon-plane {
  &:before {
    content: $icon-plane;
  }
}

.icon-play {
  &:before {
    content: $icon-play;
  }
}

.icon-play-circle {
  &:before {
    content: $icon-play-circle;
  }
}

.icon-play-circle-o {
  &:before {
    content: $icon-play-circle-o;
  }
}

.icon-plug {
  &:before {
    content: $icon-plug;
  }
}

.icon-plus {
  &:before {
    content: $icon-plus;
  }
}

.icon-plus-circle {
  &:before {
    content: $icon-plus-circle;
  }
}

.icon-plus-square {
  &:before {
    content: $icon-plus-square;
  }
}

.icon-plus-square-o {
  &:before {
    content: $icon-plus-square-o;
  }
}

.icon-podcast {
  &:before {
    content: $icon-podcast;
  }
}

.icon-power-off {
  &:before {
    content: $icon-power-off;
  }
}

.icon-print1 {
  &:before {
    content: $icon-print1;
  }
}

.icon-product-hunt {
  &:before {
    content: $icon-product-hunt;
  }
}

.icon-puzzle-piece {
  &:before {
    content: $icon-puzzle-piece;
  }
}

.icon-qq {
  &:before {
    content: $icon-qq;
  }
}

.icon-qrcode {
  &:before {
    content: $icon-qrcode;
  }
}

.icon-question {
  &:before {
    content: $icon-question;
  }
}

.icon-question-circle {
  &:before {
    content: $icon-question-circle;
  }
}

.icon-question-circle-o {
  &:before {
    content: $icon-question-circle-o;
  }
}

.icon-quora {
  &:before {
    content: $icon-quora;
  }
}

.icon-quote-left {
  &:before {
    content: $icon-quote-left;
  }
}

.icon-quote-right {
  &:before {
    content: $icon-quote-right;
  }
}

.icon-ra {
  &:before {
    content: $icon-ra;
  }
}

.icon-rebel {
  &:before {
    content: $icon-rebel;
  }
}

.icon-resistance {
  &:before {
    content: $icon-resistance;
  }
}

.icon-random {
  &:before {
    content: $icon-random;
  }
}

.icon-ravelry {
  &:before {
    content: $icon-ravelry;
  }
}

.icon-recycle {
  &:before {
    content: $icon-recycle;
  }
}

.icon-reddit {
  &:before {
    content: $icon-reddit;
  }
}

.icon-reddit-alien {
  &:before {
    content: $icon-reddit-alien;
  }
}

.icon-reddit-square {
  &:before {
    content: $icon-reddit-square;
  }
}

.icon-refresh1 {
  &:before {
    content: $icon-refresh1;
  }
}

.icon-registered {
  &:before {
    content: $icon-registered;
  }
}

.icon-renren {
  &:before {
    content: $icon-renren;
  }
}

.icon-repeat1 {
  &:before {
    content: $icon-repeat1;
  }
}

.icon-rotate-right {
  &:before {
    content: $icon-rotate-right;
  }
}

.icon-retweet {
  &:before {
    content: $icon-retweet;
  }
}

.icon-road {
  &:before {
    content: $icon-road;
  }
}

.icon-rocket {
  &:before {
    content: $icon-rocket;
  }
}

.icon-rotate-left {
  &:before {
    content: $icon-rotate-left;
  }
}

.icon-undo1 {
  &:before {
    content: $icon-undo1;
  }
}

.icon-rouble {
  &:before {
    content: $icon-rouble;
  }
}

.icon-rub {
  &:before {
    content: $icon-rub;
  }
}

.icon-ruble {
  &:before {
    content: $icon-ruble;
  }
}

.icon-rss-square {
  &:before {
    content: $icon-rss-square;
  }
}

.icon-safari {
  &:before {
    content: $icon-safari;
  }
}

.icon-scribd {
  &:before {
    content: $icon-scribd;
  }
}

.icon-search1 {
  &:before {
    content: $icon-search1;
  }
}

.icon-search-minus {
  &:before {
    content: $icon-search-minus;
  }
}

.icon-search-plus {
  &:before {
    content: $icon-search-plus;
  }
}

.icon-sellsy {
  &:before {
    content: $icon-sellsy;
  }
}

.icon-server {
  &:before {
    content: $icon-server;
  }
}

.icon-share-alt {
  &:before {
    content: $icon-share-alt;
  }
}

.icon-share-alt-square {
  &:before {
    content: $icon-share-alt-square;
  }
}

.icon-share-square {
  &:before {
    content: $icon-share-square;
  }
}

.icon-share-square-o {
  &:before {
    content: $icon-share-square-o;
  }
}

.icon-shield {
  &:before {
    content: $icon-shield;
  }
}

.icon-ship {
  &:before {
    content: $icon-ship;
  }
}

.icon-shirtsinbulk {
  &:before {
    content: $icon-shirtsinbulk;
  }
}

.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag;
  }
}

.icon-shopping-basket {
  &:before {
    content: $icon-shopping-basket;
  }
}

.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart;
  }
}

.icon-shower {
  &:before {
    content: $icon-shower;
  }
}

.icon-sign-in {
  &:before {
    content: $icon-sign-in;
  }
}

.icon-sign-language {
  &:before {
    content: $icon-sign-language;
  }
}

.icon-signing {
  &:before {
    content: $icon-signing;
  }
}

.icon-sign-out {
  &:before {
    content: $icon-sign-out;
  }
}

.icon-signal {
  &:before {
    content: $icon-signal;
  }
}

.icon-simplybuilt {
  &:before {
    content: $icon-simplybuilt;
  }
}

.icon-sitemap {
  &:before {
    content: $icon-sitemap;
  }
}

.icon-skyatlas {
  &:before {
    content: $icon-skyatlas;
  }
}

.icon-skype {
  &:before {
    content: $icon-skype;
  }
}

.icon-slack {
  &:before {
    content: $icon-slack;
  }
}

.icon-sliders {
  &:before {
    content: $icon-sliders;
  }
}

.icon-slideshare {
  &:before {
    content: $icon-slideshare;
  }
}

.icon-smile-o {
  &:before {
    content: $icon-smile-o;
  }
}

.icon-snapchat {
  &:before {
    content: $icon-snapchat;
  }
}

.icon-snapchat-ghost {
  &:before {
    content: $icon-snapchat-ghost;
  }
}

.icon-snapchat-square {
  &:before {
    content: $icon-snapchat-square;
  }
}

.icon-snowflake-o {
  &:before {
    content: $icon-snowflake-o;
  }
}

.icon-sort1 {
  &:before {
    content: $icon-sort1;
  }
}

.icon-unsorted {
  &:before {
    content: $icon-unsorted;
  }
}

.icon-sort-alpha-asc {
  &:before {
    content: $icon-sort-alpha-asc;
  }
}

.icon-sort-alpha-desc {
  &:before {
    content: $icon-sort-alpha-desc;
  }
}

.icon-sort-amount-asc {
  &:before {
    content: $icon-sort-amount-asc;
  }
}

.icon-sort-amount-desc {
  &:before {
    content: $icon-sort-amount-desc;
  }
}

.icon-sort-asc {
  &:before {
    content: $icon-sort-asc;
  }
}

.icon-sort-up {
  &:before {
    content: $icon-sort-up;
  }
}

.icon-sort-desc {
  &:before {
    content: $icon-sort-desc;
  }
}

.icon-sort-down {
  &:before {
    content: $icon-sort-down;
  }
}

.icon-sort-numeric-asc {
  &:before {
    content: $icon-sort-numeric-asc;
  }
}

.icon-sort-numeric-desc {
  &:before {
    content: $icon-sort-numeric-desc;
  }
}

.icon-soundcloud {
  &:before {
    content: $icon-soundcloud;
  }
}

.icon-space-shuttle {
  &:before {
    content: $icon-space-shuttle;
  }
}

.icon-spinner {
  &:before {
    content: $icon-spinner;
  }
}

.icon-spoon {
  &:before {
    content: $icon-spoon;
  }
}

.icon-spotify {
  &:before {
    content: $icon-spotify;
  }
}

.icon-square {
  &:before {
    content: $icon-square;
  }
}

.icon-square-o {
  &:before {
    content: $icon-square-o;
  }
}

.icon-stack-exchange {
  &:before {
    content: $icon-stack-exchange;
  }
}

.icon-stack-overflow {
  &:before {
    content: $icon-stack-overflow;
  }
}

.icon-star1 {
  &:before {
    content: $icon-star1;
  }
}

.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}

.icon-star-half-empty {
  &:before {
    content: $icon-star-half-empty;
  }
}

.icon-star-half-full {
  &:before {
    content: $icon-star-half-full;
  }
}

.icon-star-half-o {
  &:before {
    content: $icon-star-half-o;
  }
}

.icon-star-o {
  &:before {
    content: $icon-star-o;
  }
}

.icon-steam {
  &:before {
    content: $icon-steam;
  }
}

.icon-steam-square {
  &:before {
    content: $icon-steam-square;
  }
}

.icon-step-backward {
  &:before {
    content: $icon-step-backward;
  }
}

.icon-step-forward {
  &:before {
    content: $icon-step-forward;
  }
}

.icon-stethoscope {
  &:before {
    content: $icon-stethoscope;
  }
}

.icon-sticky-note {
  &:before {
    content: $icon-sticky-note;
  }
}

.icon-sticky-note-o {
  &:before {
    content: $icon-sticky-note-o;
  }
}

.icon-stop1 {
  &:before {
    content: $icon-stop1;
  }
}

.icon-stop-circle {
  &:before {
    content: $icon-stop-circle;
  }
}

.icon-stop-circle-o {
  &:before {
    content: $icon-stop-circle-o;
  }
}

.icon-street-view {
  &:before {
    content: $icon-street-view;
  }
}

.icon-strikethrough {
  &:before {
    content: $icon-strikethrough;
  }
}

.icon-stumbleupon {
  &:before {
    content: $icon-stumbleupon;
  }
}

.icon-stumbleupon-circle {
  &:before {
    content: $icon-stumbleupon-circle;
  }
}

.icon-subscript {
  &:before {
    content: $icon-subscript;
  }
}

.icon-subway1 {
  &:before {
    content: $icon-subway1;
  }
}

.icon-suitcase {
  &:before {
    content: $icon-suitcase;
  }
}

.icon-sun-o {
  &:before {
    content: $icon-sun-o;
  }
}

.icon-superpowers {
  &:before {
    content: $icon-superpowers;
  }
}

.icon-superscript {
  &:before {
    content: $icon-superscript;
  }
}

.icon-table {
  &:before {
    content: $icon-table;
  }
}

.icon-tablet1 {
  &:before {
    content: $icon-tablet1;
  }
}

.icon-tag {
  &:before {
    content: $icon-tag;
  }
}

.icon-tags {
  &:before {
    content: $icon-tags;
  }
}

.icon-tasks {
  &:before {
    content: $icon-tasks;
  }
}

.icon-telegram {
  &:before {
    content: $icon-telegram;
  }
}

.icon-television {
  &:before {
    content: $icon-television;
  }
}

.icon-tv1 {
  &:before {
    content: $icon-tv1;
  }
}

.icon-tencent-weibo {
  &:before {
    content: $icon-tencent-weibo;
  }
}

.icon-terminal {
  &:before {
    content: $icon-terminal;
  }
}

.icon-text-height {
  &:before {
    content: $icon-text-height;
  }
}

.icon-text-width {
  &:before {
    content: $icon-text-width;
  }
}

.icon-th {
  &:before {
    content: $icon-th;
  }
}

.icon-th-large {
  &:before {
    content: $icon-th-large;
  }
}

.icon-th-list {
  &:before {
    content: $icon-th-list;
  }
}

.icon-themeisle {
  &:before {
    content: $icon-themeisle;
  }
}

.icon-thermometer {
  &:before {
    content: $icon-thermometer;
  }
}

.icon-thermometer-4 {
  &:before {
    content: $icon-thermometer-4;
  }
}

.icon-thermometer-full {
  &:before {
    content: $icon-thermometer-full;
  }
}

.icon-thermometer-0 {
  &:before {
    content: $icon-thermometer-0;
  }
}

.icon-thermometer-empty {
  &:before {
    content: $icon-thermometer-empty;
  }
}

.icon-thermometer-1 {
  &:before {
    content: $icon-thermometer-1;
  }
}

.icon-thermometer-quarter {
  &:before {
    content: $icon-thermometer-quarter;
  }
}

.icon-thermometer-2 {
  &:before {
    content: $icon-thermometer-2;
  }
}

.icon-thermometer-half {
  &:before {
    content: $icon-thermometer-half;
  }
}

.icon-thermometer-3 {
  &:before {
    content: $icon-thermometer-3;
  }
}

.icon-thermometer-three-quarters {
  &:before {
    content: $icon-thermometer-three-quarters;
  }
}

.icon-thumb-tack {
  &:before {
    content: $icon-thumb-tack;
  }
}

.icon-thumbs-down {
  &:before {
    content: $icon-thumbs-down;
  }
}

.icon-thumbs-o-down {
  &:before {
    content: $icon-thumbs-o-down;
  }
}

.icon-thumbs-o-up {
  &:before {
    content: $icon-thumbs-o-up;
  }
}

.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up;
  }
}

.icon-ticket {
  &:before {
    content: $icon-ticket;
  }
}

.icon-times-circle {
  &:before {
    content: $icon-times-circle;
  }
}

.icon-times-circle-o {
  &:before {
    content: $icon-times-circle-o;
  }
}

.icon-times-rectangle {
  &:before {
    content: $icon-times-rectangle;
  }
}

.icon-window-close {
  &:before {
    content: $icon-window-close;
  }
}

.icon-times-rectangle-o {
  &:before {
    content: $icon-times-rectangle-o;
  }
}

.icon-window-close-o {
  &:before {
    content: $icon-window-close-o;
  }
}

.icon-tint {
  &:before {
    content: $icon-tint;
  }
}

.icon-toggle-off {
  &:before {
    content: $icon-toggle-off;
  }
}

.icon-toggle-on {
  &:before {
    content: $icon-toggle-on;
  }
}

.icon-trademark {
  &:before {
    content: $icon-trademark;
  }
}

.icon-train1 {
  &:before {
    content: $icon-train1;
  }
}

.icon-transgender-alt {
  &:before {
    content: $icon-transgender-alt;
  }
}

.icon-trash {
  &:before {
    content: $icon-trash;
  }
}

.icon-trash-o {
  &:before {
    content: $icon-trash-o;
  }
}

.icon-tree {
  &:before {
    content: $icon-tree;
  }
}

.icon-trello {
  &:before {
    content: $icon-trello;
  }
}

.icon-tripadvisor {
  &:before {
    content: $icon-tripadvisor;
  }
}

.icon-trophy {
  &:before {
    content: $icon-trophy;
  }
}

.icon-truck {
  &:before {
    content: $icon-truck;
  }
}

.icon-try {
  &:before {
    content: $icon-try;
  }
}

.icon-turkish-lira {
  &:before {
    content: $icon-turkish-lira;
  }
}

.icon-tty {
  &:before {
    content: $icon-tty;
  }
}

.icon-tumblr {
  &:before {
    content: $icon-tumblr;
  }
}

.icon-tumblr-square {
  &:before {
    content: $icon-tumblr-square;
  }
}

.icon-twitch {
  &:before {
    content: $icon-twitch;
  }
}

.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}

.icon-twitter-square {
  &:before {
    content: $icon-twitter-square;
  }
}

.icon-umbrella {
  &:before {
    content: $icon-umbrella;
  }
}

.icon-underline {
  &:before {
    content: $icon-underline;
  }
}

.icon-universal-access {
  &:before {
    content: $icon-universal-access;
  }
}

.icon-unlock {
  &:before {
    content: $icon-unlock;
  }
}

.icon-unlock-alt {
  &:before {
    content: $icon-unlock-alt;
  }
}

.icon-upload {
  &:before {
    content: $icon-upload;
  }
}

.icon-usb1 {
  &:before {
    content: $icon-usb1;
  }
}

.icon-user {
  &:before {
    content: $icon-user;
  }
}

.icon-user-circle {
  &:before {
    content: $icon-user-circle;
  }
}

.icon-user-circle-o {
  &:before {
    content: $icon-user-circle-o;
  }
}

.icon-user-md {
  &:before {
    content: $icon-user-md;
  }
}

.icon-user-o {
  &:before {
    content: $icon-user-o;
  }
}

.icon-user-plus {
  &:before {
    content: $icon-user-plus;
  }
}

.icon-user-secret {
  &:before {
    content: $icon-user-secret;
  }
}

.icon-user-times {
  &:before {
    content: $icon-user-times;
  }
}

.icon-venus {
  &:before {
    content: $icon-venus;
  }
}

.icon-venus-double {
  &:before {
    content: $icon-venus-double;
  }
}

.icon-venus-mars {
  &:before {
    content: $icon-venus-mars;
  }
}

.icon-viacoin {
  &:before {
    content: $icon-viacoin;
  }
}

.icon-viadeo {
  &:before {
    content: $icon-viadeo;
  }
}

.icon-viadeo-square {
  &:before {
    content: $icon-viadeo-square;
  }
}

.icon-video-camera {
  &:before {
    content: $icon-video-camera;
  }
}

.icon-vimeo {
  &:before {
    content: $icon-vimeo;
  }
}

.icon-vimeo-square {
  &:before {
    content: $icon-vimeo-square;
  }
}

.icon-vine {
  &:before {
    content: $icon-vine;
  }
}

.icon-vk {
  &:before {
    content: $icon-vk;
  }
}

.icon-volume-control-phone {
  &:before {
    content: $icon-volume-control-phone;
  }
}

.icon-volume-down {
  &:before {
    content: $icon-volume-down;
  }
}

.icon-volume-off {
  &:before {
    content: $icon-volume-off;
  }
}

.icon-volume-up {
  &:before {
    content: $icon-volume-up;
  }
}

.icon-wechat {
  &:before {
    content: $icon-wechat;
  }
}

.icon-weixin {
  &:before {
    content: $icon-weixin;
  }
}

.icon-weibo {
  &:before {
    content: $icon-weibo;
  }
}

.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}

.icon-wheelchair {
  &:before {
    content: $icon-wheelchair;
  }
}

.icon-wheelchair-alt {
  &:before {
    content: $icon-wheelchair-alt;
  }
}

.icon-wifi1 {
  &:before {
    content: $icon-wifi1;
  }
}

.icon-wikipedia-w {
  &:before {
    content: $icon-wikipedia-w;
  }
}

.icon-window-maximize {
  &:before {
    content: $icon-window-maximize;
  }
}

.icon-window-minimize {
  &:before {
    content: $icon-window-minimize;
  }
}

.icon-window-restore {
  &:before {
    content: $icon-window-restore;
  }
}

.icon-windows {
  &:before {
    content: $icon-windows;
  }
}

.icon-wordpress {
  &:before {
    content: $icon-wordpress;
  }
}

.icon-wpbeginner {
  &:before {
    content: $icon-wpbeginner;
  }
}

.icon-wpexplorer {
  &:before {
    content: $icon-wpexplorer;
  }
}

.icon-wpforms {
  &:before {
    content: $icon-wpforms;
  }
}

.icon-wrench {
  &:before {
    content: $icon-wrench;
  }
}

.icon-xing {
  &:before {
    content: $icon-xing;
  }
}

.icon-xing-square {
  &:before {
    content: $icon-xing-square;
  }
}

.icon-y-combinator {
  &:before {
    content: $icon-y-combinator;
  }
}

.icon-yc {
  &:before {
    content: $icon-yc;
  }
}

.icon-yahoo {
  &:before {
    content: $icon-yahoo;
  }
}

.icon-yelp {
  &:before {
    content: $icon-yelp;
  }
}

.icon-yoast {
  &:before {
    content: $icon-yoast;
  }
}

.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}

.icon-youtube-play {
  &:before {
    content: $icon-youtube-play;
  }
}

.icon-youtube-square {
  &:before {
    content: $icon-youtube-square;
  }
}

.icon-3d_rotation {
  &:before {
    content: $icon-3d_rotation;
  }
}

.icon-ac_unit {
  &:before {
    content: $icon-ac_unit;
  }
}

.icon-access_alarms {
  &:before {
    content: $icon-access_alarms;
  }
}

.icon-accessibility {
  &:before {
    content: $icon-accessibility;
  }
}

.icon-accessible {
  &:before {
    content: $icon-accessible;
  }
}

.icon-account_balance {
  &:before {
    content: $icon-account_balance;
  }
}

.icon-account_balance_wallet {
  &:before {
    content: $icon-account_balance_wallet;
  }
}

.icon-account_box {
  &:before {
    content: $icon-account_box;
  }
}

.icon-account_circle {
  &:before {
    content: $icon-account_circle;
  }
}

.icon-adb {
  &:before {
    content: $icon-adb;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-add_a_photo {
  &:before {
    content: $icon-add_a_photo;
  }
}

.icon-add_alert {
  &:before {
    content: $icon-add_alert;
  }
}

.icon-add_box {
  &:before {
    content: $icon-add_box;
  }
}

.icon-add_circle {
  &:before {
    content: $icon-add_circle;
  }
}

.icon-add_location {
  &:before {
    content: $icon-add_location;
  }
}

.icon-add_shopping_cart {
  &:before {
    content: $icon-add_shopping_cart;
  }
}

.icon-add_to_queue {
  &:before {
    content: $icon-add_to_queue;
  }
}

.icon-adjust {
  &:before {
    content: $icon-adjust;
  }
}

.icon-airline_seat_flat {
  &:before {
    content: $icon-airline_seat_flat;
  }
}

.icon-airline_seat_flat_angled {
  &:before {
    content: $icon-airline_seat_flat_angled;
  }
}

.icon-airline_seat_individual_suite {
  &:before {
    content: $icon-airline_seat_individual_suite;
  }
}

.icon-airline_seat_legroom_extra {
  &:before {
    content: $icon-airline_seat_legroom_extra;
  }
}

.icon-airline_seat_legroom_normal {
  &:before {
    content: $icon-airline_seat_legroom_normal;
  }
}

.icon-airline_seat_legroom_reduced {
  &:before {
    content: $icon-airline_seat_legroom_reduced;
  }
}

.icon-airline_seat_recline_extra {
  &:before {
    content: $icon-airline_seat_recline_extra;
  }
}

.icon-airline_seat_recline_normal {
  &:before {
    content: $icon-airline_seat_recline_normal;
  }
}

.icon-airplanemode_inactive {
  &:before {
    content: $icon-airplanemode_inactive;
  }
}

.icon-airplay {
  &:before {
    content: $icon-airplay;
  }
}

.icon-airport_shuttle {
  &:before {
    content: $icon-airport_shuttle;
  }
}

.icon-alarm {
  &:before {
    content: $icon-alarm;
  }
}

.icon-alarm_add {
  &:before {
    content: $icon-alarm_add;
  }
}

.icon-alarm_off {
  &:before {
    content: $icon-alarm_off;
  }
}

.icon-alarm_on {
  &:before {
    content: $icon-alarm_on;
  }
}

.icon-album {
  &:before {
    content: $icon-album;
  }
}

.icon-all_inclusive {
  &:before {
    content: $icon-all_inclusive;
  }
}

.icon-all_out {
  &:before {
    content: $icon-all_out;
  }
}

.icon-android1 {
  &:before {
    content: $icon-android1;
  }
}

.icon-announcement {
  &:before {
    content: $icon-announcement;
  }
}

.icon-apps {
  &:before {
    content: $icon-apps;
  }
}

.icon-archive {
  &:before {
    content: $icon-archive;
  }
}

.icon-arrow_back {
  &:before {
    content: $icon-arrow_back;
  }
}

.icon-arrow_downward {
  &:before {
    content: $icon-arrow_downward;
  }
}

.icon-arrow_drop_down {
  &:before {
    content: $icon-arrow_drop_down;
  }
}

.icon-arrow_drop_down_circle {
  &:before {
    content: $icon-arrow_drop_down_circle;
  }
}

.icon-arrow_drop_up {
  &:before {
    content: $icon-arrow_drop_up;
  }
}

.icon-arrow_forward {
  &:before {
    content: $icon-arrow_forward;
  }
}

.icon-arrow_upward {
  &:before {
    content: $icon-arrow_upward;
  }
}

.icon-art_track {
  &:before {
    content: $icon-art_track;
  }
}

.icon-aspect_ratio {
  &:before {
    content: $icon-aspect_ratio;
  }
}

.icon-assignment {
  &:before {
    content: $icon-assignment;
  }
}

.icon-assignment_ind {
  &:before {
    content: $icon-assignment_ind;
  }
}

.icon-assignment_late {
  &:before {
    content: $icon-assignment_late;
  }
}

.icon-assignment_return {
  &:before {
    content: $icon-assignment_return;
  }
}

.icon-assignment_returned {
  &:before {
    content: $icon-assignment_returned;
  }
}

.icon-assignment_turned_in {
  &:before {
    content: $icon-assignment_turned_in;
  }
}

.icon-assistant {
  &:before {
    content: $icon-assistant;
  }
}

.icon-attach_file {
  &:before {
    content: $icon-attach_file;
  }
}

.icon-attach_money {
  &:before {
    content: $icon-attach_money;
  }
}

.icon-attachment {
  &:before {
    content: $icon-attachment;
  }
}

.icon-audiotrack {
  &:before {
    content: $icon-audiotrack;
  }
}

.icon-autorenew {
  &:before {
    content: $icon-autorenew;
  }
}

.icon-av_timer {
  &:before {
    content: $icon-av_timer;
  }
}

.icon-backspace {
  &:before {
    content: $icon-backspace;
  }
}

.icon-battery_alert {
  &:before {
    content: $icon-battery_alert;
  }
}

.icon-battery_charging_full {
  &:before {
    content: $icon-battery_charging_full;
  }
}

.icon-battery_std {
  &:before {
    content: $icon-battery_std;
  }
}

.icon-battery_unknown {
  &:before {
    content: $icon-battery_unknown;
  }
}

.icon-beach_access {
  &:before {
    content: $icon-beach_access;
  }
}

.icon-beenhere {
  &:before {
    content: $icon-beenhere;
  }
}

.icon-block {
  &:before {
    content: $icon-block;
  }
}

.icon-bluetooth {
  &:before {
    content: $icon-bluetooth;
  }
}

.icon-bluetooth_connected {
  &:before {
    content: $icon-bluetooth_connected;
  }
}

.icon-bluetooth_disabled {
  &:before {
    content: $icon-bluetooth_disabled;
  }
}

.icon-bluetooth_searching {
  &:before {
    content: $icon-bluetooth_searching;
  }
}

.icon-blur_circular {
  &:before {
    content: $icon-blur_circular;
  }
}

.icon-blur_linear {
  &:before {
    content: $icon-blur_linear;
  }
}

.icon-blur_off {
  &:before {
    content: $icon-blur_off;
  }
}

.icon-blur_on {
  &:before {
    content: $icon-blur_on;
  }
}

.icon-border_all {
  &:before {
    content: $icon-border_all;
  }
}

.icon-border_bottom {
  &:before {
    content: $icon-border_bottom;
  }
}

.icon-border_clear {
  &:before {
    content: $icon-border_clear;
  }
}

.icon-border_color {
  &:before {
    content: $icon-border_color;
  }
}

.icon-border_horizontal {
  &:before {
    content: $icon-border_horizontal;
  }
}

.icon-border_inner {
  &:before {
    content: $icon-border_inner;
  }
}

.icon-border_left {
  &:before {
    content: $icon-border_left;
  }
}

.icon-border_outer {
  &:before {
    content: $icon-border_outer;
  }
}

.icon-border_right {
  &:before {
    content: $icon-border_right;
  }
}

.icon-border_style {
  &:before {
    content: $icon-border_style;
  }
}

.icon-border_top {
  &:before {
    content: $icon-border_top;
  }
}

.icon-border_vertical {
  &:before {
    content: $icon-border_vertical;
  }
}

.icon-branding_watermark {
  &:before {
    content: $icon-branding_watermark;
  }
}

.icon-brightness_1 {
  &:before {
    content: $icon-brightness_1;
  }
}

.icon-brightness_2 {
  &:before {
    content: $icon-brightness_2;
  }
}

.icon-brightness_3 {
  &:before {
    content: $icon-brightness_3;
  }
}

.icon-brightness_4 {
  &:before {
    content: $icon-brightness_4;
  }
}

.icon-brightness_auto {
  &:before {
    content: $icon-brightness_auto;
  }
}

.icon-brightness_high {
  &:before {
    content: $icon-brightness_high;
  }
}

.icon-brightness_low {
  &:before {
    content: $icon-brightness_low;
  }
}

.icon-brightness_medium {
  &:before {
    content: $icon-brightness_medium;
  }
}

.icon-broken_image {
  &:before {
    content: $icon-broken_image;
  }
}

.icon-brush {
  &:before {
    content: $icon-brush;
  }
}

.icon-bubble_chart {
  &:before {
    content: $icon-bubble_chart;
  }
}

.icon-bug_report {
  &:before {
    content: $icon-bug_report;
  }
}

.icon-build {
  &:before {
    content: $icon-build;
  }
}

.icon-burst_mode {
  &:before {
    content: $icon-burst_mode;
  }
}

.icon-business_center {
  &:before {
    content: $icon-business_center;
  }
}

.icon-cached {
  &:before {
    content: $icon-cached;
  }
}

.icon-cake {
  &:before {
    content: $icon-cake;
  }
}

.icon-call_end {
  &:before {
    content: $icon-call_end;
  }
}

.icon-call_made {
  &:before {
    content: $icon-call_made;
  }
}

.icon-call_missed {
  &:before {
    content: $icon-call_missed;
  }
}

.icon-call_missed_outgoing {
  &:before {
    content: $icon-call_missed_outgoing;
  }
}

.icon-call_received {
  &:before {
    content: $icon-call_received;
  }
}

.icon-call_split {
  &:before {
    content: $icon-call_split;
  }
}

.icon-call_to_action {
  &:before {
    content: $icon-call_to_action;
  }
}

.icon-camera {
  &:before {
    content: $icon-camera;
  }
}

.icon-camera_enhance {
  &:before {
    content: $icon-camera_enhance;
  }
}

.icon-camera_front {
  &:before {
    content: $icon-camera_front;
  }
}

.icon-camera_rear {
  &:before {
    content: $icon-camera_rear;
  }
}

.icon-camera_roll {
  &:before {
    content: $icon-camera_roll;
  }
}

.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}

.icon-card_membership {
  &:before {
    content: $icon-card_membership;
  }
}

.icon-card_travel {
  &:before {
    content: $icon-card_travel;
  }
}

.icon-casino {
  &:before {
    content: $icon-casino;
  }
}

.icon-cast {
  &:before {
    content: $icon-cast;
  }
}

.icon-cast_connected {
  &:before {
    content: $icon-cast_connected;
  }
}

.icon-center_focus_strong {
  &:before {
    content: $icon-center_focus_strong;
  }
}

.icon-center_focus_weak {
  &:before {
    content: $icon-center_focus_weak;
  }
}

.icon-change_history {
  &:before {
    content: $icon-change_history;
  }
}

.icon-chat {
  &:before {
    content: $icon-chat;
  }
}

.icon-chat_bubble {
  &:before {
    content: $icon-chat_bubble;
  }
}

.icon-chat_bubble_outline {
  &:before {
    content: $icon-chat_bubble_outline;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-check_box {
  &:before {
    content: $icon-check_box;
  }
}

.icon-check_box_outline_blank {
  &:before {
    content: $icon-check_box_outline_blank;
  }
}

.icon-check_circle {
  &:before {
    content: $icon-check_circle;
  }
}

.icon-child_care {
  &:before {
    content: $icon-child_care;
  }
}

.icon-child_friendly {
  &:before {
    content: $icon-child_friendly;
  }
}

.icon-chrome_reader_mode {
  &:before {
    content: $icon-chrome_reader_mode;
  }
}

.icon-class {
  &:before {
    content: $icon-class;
  }
}

.icon-clear_all {
  &:before {
    content: $icon-clear_all;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-closed_caption {
  &:before {
    content: $icon-closed_caption;
  }
}

.icon-cloud_circle {
  &:before {
    content: $icon-cloud_circle;
  }
}

.icon-cloud_done {
  &:before {
    content: $icon-cloud_done;
  }
}

.icon-cloud_download {
  &:before {
    content: $icon-cloud_download;
  }
}

.icon-cloud_off {
  &:before {
    content: $icon-cloud_off;
  }
}

.icon-cloud_queue {
  &:before {
    content: $icon-cloud_queue;
  }
}

.icon-cloud_upload {
  &:before {
    content: $icon-cloud_upload;
  }
}

.icon-code {
  &:before {
    content: $icon-code;
  }
}

.icon-collections_bookmark {
  &:before {
    content: $icon-collections_bookmark;
  }
}

.icon-colorize {
  &:before {
    content: $icon-colorize;
  }
}

.icon-comment {
  &:before {
    content: $icon-comment;
  }
}

.icon-compare {
  &:before {
    content: $icon-compare;
  }
}

.icon-compare_arrows {
  &:before {
    content: $icon-compare_arrows;
  }
}

.icon-confirmation_number {
  &:before {
    content: $icon-confirmation_number;
  }
}

.icon-contact_mail {
  &:before {
    content: $icon-contact_mail;
  }
}

.icon-contact_phone {
  &:before {
    content: $icon-contact_phone;
  }
}

.icon-contacts {
  &:before {
    content: $icon-contacts;
  }
}

.icon-content_copy {
  &:before {
    content: $icon-content_copy;
  }
}

.icon-content_cut {
  &:before {
    content: $icon-content_cut;
  }
}

.icon-content_paste {
  &:before {
    content: $icon-content_paste;
  }
}

.icon-control_point {
  &:before {
    content: $icon-control_point;
  }
}

.icon-control_point_duplicate {
  &:before {
    content: $icon-control_point_duplicate;
  }
}

.icon-copyright {
  &:before {
    content: $icon-copyright;
  }
}

.icon-create_new_folder {
  &:before {
    content: $icon-create_new_folder;
  }
}

.icon-crop {
  &:before {
    content: $icon-crop;
  }
}

.icon-crop_16_9 {
  &:before {
    content: $icon-crop_16_9;
  }
}

.icon-crop_3_2 {
  &:before {
    content: $icon-crop_3_2;
  }
}

.icon-crop_7_5 {
  &:before {
    content: $icon-crop_7_5;
  }
}

.icon-crop_din {
  &:before {
    content: $icon-crop_din;
  }
}

.icon-crop_free {
  &:before {
    content: $icon-crop_free;
  }
}

.icon-crop_landscape {
  &:before {
    content: $icon-crop_landscape;
  }
}

.icon-crop_original {
  &:before {
    content: $icon-crop_original;
  }
}

.icon-crop_portrait {
  &:before {
    content: $icon-crop_portrait;
  }
}

.icon-crop_rotate {
  &:before {
    content: $icon-crop_rotate;
  }
}

.icon-crop_square {
  &:before {
    content: $icon-crop_square;
  }
}

.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}

.icon-data_usage {
  &:before {
    content: $icon-data_usage;
  }
}

.icon-date_range {
  &:before {
    content: $icon-date_range;
  }
}

.icon-dehaze {
  &:before {
    content: $icon-dehaze;
  }
}

.icon-delete {
  &:before {
    content: $icon-delete;
  }
}

.icon-delete_forever {
  &:before {
    content: $icon-delete_forever;
  }
}

.icon-delete_sweep {
  &:before {
    content: $icon-delete_sweep;
  }
}

.icon-description {
  &:before {
    content: $icon-description;
  }
}

.icon-desktop_mac {
  &:before {
    content: $icon-desktop_mac;
  }
}

.icon-desktop_windows {
  &:before {
    content: $icon-desktop_windows;
  }
}

.icon-details {
  &:before {
    content: $icon-details;
  }
}

.icon-developer_board {
  &:before {
    content: $icon-developer_board;
  }
}

.icon-developer_mode {
  &:before {
    content: $icon-developer_mode;
  }
}

.icon-device_hub {
  &:before {
    content: $icon-device_hub;
  }
}

.icon-devices_other {
  &:before {
    content: $icon-devices_other;
  }
}

.icon-dialer_sip {
  &:before {
    content: $icon-dialer_sip;
  }
}

.icon-dialpad {
  &:before {
    content: $icon-dialpad;
  }
}

.icon-directions {
  &:before {
    content: $icon-directions;
  }
}

.icon-directions_bike {
  &:before {
    content: $icon-directions_bike;
  }
}

.icon-directions_boat {
  &:before {
    content: $icon-directions_boat;
  }
}

.icon-directions_bus {
  &:before {
    content: $icon-directions_bus;
  }
}

.icon-directions_car {
  &:before {
    content: $icon-directions_car;
  }
}

.icon-directions_railway {
  &:before {
    content: $icon-directions_railway;
  }
}

.icon-directions_run {
  &:before {
    content: $icon-directions_run;
  }
}

.icon-directions_transit {
  &:before {
    content: $icon-directions_transit;
  }
}

.icon-directions_walk {
  &:before {
    content: $icon-directions_walk;
  }
}

.icon-disc_full {
  &:before {
    content: $icon-disc_full;
  }
}

.icon-dns {
  &:before {
    content: $icon-dns;
  }
}

.icon-do_not_disturb_alt {
  &:before {
    content: $icon-do_not_disturb_alt;
  }
}

.icon-do_not_disturb_off {
  &:before {
    content: $icon-do_not_disturb_off;
  }
}

.icon-dock {
  &:before {
    content: $icon-dock;
  }
}

.icon-domain {
  &:before {
    content: $icon-domain;
  }
}

.icon-done {
  &:before {
    content: $icon-done;
  }
}

.icon-done_all {
  &:before {
    content: $icon-done_all;
  }
}

.icon-donut_large {
  &:before {
    content: $icon-donut_large;
  }
}

.icon-donut_small {
  &:before {
    content: $icon-donut_small;
  }
}

.icon-drafts {
  &:before {
    content: $icon-drafts;
  }
}

.icon-drag_handle {
  &:before {
    content: $icon-drag_handle;
  }
}

.icon-dvr {
  &:before {
    content: $icon-dvr;
  }
}

.icon-edit_location {
  &:before {
    content: $icon-edit_location;
  }
}

.icon-eject {
  &:before {
    content: $icon-eject;
  }
}

.icon-enhanced_encryption {
  &:before {
    content: $icon-enhanced_encryption;
  }
}

.icon-equalizer {
  &:before {
    content: $icon-equalizer;
  }
}

.icon-error {
  &:before {
    content: $icon-error;
  }
}

.icon-error_outline {
  &:before {
    content: $icon-error_outline;
  }
}

.icon-euro_symbol {
  &:before {
    content: $icon-euro_symbol;
  }
}

.icon-ev_station {
  &:before {
    content: $icon-ev_station;
  }
}

.icon-event_available {
  &:before {
    content: $icon-event_available;
  }
}

.icon-event_busy {
  &:before {
    content: $icon-event_busy;
  }
}

.icon-event_note {
  &:before {
    content: $icon-event_note;
  }
}

.icon-event_seat {
  &:before {
    content: $icon-event_seat;
  }
}

.icon-exit_to_app {
  &:before {
    content: $icon-exit_to_app;
  }
}

.icon-expand_less {
  &:before {
    content: $icon-expand_less;
  }
}

.icon-expand_more {
  &:before {
    content: $icon-expand_more;
  }
}

.icon-explicit {
  &:before {
    content: $icon-explicit;
  }
}

.icon-explore {
  &:before {
    content: $icon-explore;
  }
}

.icon-exposure {
  &:before {
    content: $icon-exposure;
  }
}

.icon-exposure_neg_1 {
  &:before {
    content: $icon-exposure_neg_1;
  }
}

.icon-exposure_neg_2 {
  &:before {
    content: $icon-exposure_neg_2;
  }
}

.icon-exposure_plus_1 {
  &:before {
    content: $icon-exposure_plus_1;
  }
}

.icon-exposure_plus_2 {
  &:before {
    content: $icon-exposure_plus_2;
  }
}

.icon-exposure_zero {
  &:before {
    content: $icon-exposure_zero;
  }
}

.icon-extension {
  &:before {
    content: $icon-extension;
  }
}

.icon-face {
  &:before {
    content: $icon-face;
  }
}

.icon-fast_forward {
  &:before {
    content: $icon-fast_forward;
  }
}

.icon-fast_rewind {
  &:before {
    content: $icon-fast_rewind;
  }
}

.icon-favorite {
  &:before {
    content: $icon-favorite;
  }
}

.icon-favorite_border {
  &:before {
    content: $icon-favorite_border;
  }
}

.icon-featured_play_list {
  &:before {
    content: $icon-featured_play_list;
  }
}

.icon-featured_video {
  &:before {
    content: $icon-featured_video;
  }
}

.icon-fiber_dvr {
  &:before {
    content: $icon-fiber_dvr;
  }
}

.icon-fiber_manual_record {
  &:before {
    content: $icon-fiber_manual_record;
  }
}

.icon-fiber_new {
  &:before {
    content: $icon-fiber_new;
  }
}

.icon-fiber_pin {
  &:before {
    content: $icon-fiber_pin;
  }
}

.icon-fiber_smart_record {
  &:before {
    content: $icon-fiber_smart_record;
  }
}

.icon-file_upload {
  &:before {
    content: $icon-file_upload;
  }
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}

.icon-filter_1 {
  &:before {
    content: $icon-filter_1;
  }
}

.icon-filter_2 {
  &:before {
    content: $icon-filter_2;
  }
}

.icon-filter_3 {
  &:before {
    content: $icon-filter_3;
  }
}

.icon-filter_4 {
  &:before {
    content: $icon-filter_4;
  }
}

.icon-filter_5 {
  &:before {
    content: $icon-filter_5;
  }
}

.icon-filter_6 {
  &:before {
    content: $icon-filter_6;
  }
}

.icon-filter_7 {
  &:before {
    content: $icon-filter_7;
  }
}

.icon-filter_8 {
  &:before {
    content: $icon-filter_8;
  }
}

.icon-filter_9 {
  &:before {
    content: $icon-filter_9;
  }
}

.icon-filter_9_plus {
  &:before {
    content: $icon-filter_9_plus;
  }
}

.icon-filter_b_and_w {
  &:before {
    content: $icon-filter_b_and_w;
  }
}

.icon-filter_center_focus {
  &:before {
    content: $icon-filter_center_focus;
  }
}

.icon-filter_drama {
  &:before {
    content: $icon-filter_drama;
  }
}

.icon-filter_frames {
  &:before {
    content: $icon-filter_frames;
  }
}

.icon-filter_list {
  &:before {
    content: $icon-filter_list;
  }
}

.icon-filter_none {
  &:before {
    content: $icon-filter_none;
  }
}

.icon-filter_tilt_shift {
  &:before {
    content: $icon-filter_tilt_shift;
  }
}

.icon-filter_vintage {
  &:before {
    content: $icon-filter_vintage;
  }
}

.icon-find_in_page {
  &:before {
    content: $icon-find_in_page;
  }
}

.icon-find_replace {
  &:before {
    content: $icon-find_replace;
  }
}

.icon-fingerprint {
  &:before {
    content: $icon-fingerprint;
  }
}

.icon-first_page {
  &:before {
    content: $icon-first_page;
  }
}

.icon-fitness_center {
  &:before {
    content: $icon-fitness_center;
  }
}

.icon-flag {
  &:before {
    content: $icon-flag;
  }
}

.icon-flare {
  &:before {
    content: $icon-flare;
  }
}

.icon-flash_auto {
  &:before {
    content: $icon-flash_auto;
  }
}

.icon-flash_off {
  &:before {
    content: $icon-flash_off;
  }
}

.icon-flash_on {
  &:before {
    content: $icon-flash_on;
  }
}

.icon-flight {
  &:before {
    content: $icon-flight;
  }
}

.icon-flight_land {
  &:before {
    content: $icon-flight_land;
  }
}

.icon-flight_takeoff {
  &:before {
    content: $icon-flight_takeoff;
  }
}

.icon-flip {
  &:before {
    content: $icon-flip;
  }
}

.icon-flip_to_back {
  &:before {
    content: $icon-flip_to_back;
  }
}

.icon-flip_to_front {
  &:before {
    content: $icon-flip_to_front;
  }
}

.icon-folder {
  &:before {
    content: $icon-folder;
  }
}

.icon-folder_open {
  &:before {
    content: $icon-folder_open;
  }
}

.icon-folder_shared {
  &:before {
    content: $icon-folder_shared;
  }
}

.icon-folder_special {
  &:before {
    content: $icon-folder_special;
  }
}

.icon-font_download {
  &:before {
    content: $icon-font_download;
  }
}

.icon-format_align_center {
  &:before {
    content: $icon-format_align_center;
  }
}

.icon-format_align_justify {
  &:before {
    content: $icon-format_align_justify;
  }
}

.icon-format_align_left {
  &:before {
    content: $icon-format_align_left;
  }
}

.icon-format_align_right {
  &:before {
    content: $icon-format_align_right;
  }
}

.icon-format_bold {
  &:before {
    content: $icon-format_bold;
  }
}

.icon-format_clear {
  &:before {
    content: $icon-format_clear;
  }
}

.icon-format_color_fill {
  &:before {
    content: $icon-format_color_fill;
  }
}

.icon-format_color_reset {
  &:before {
    content: $icon-format_color_reset;
  }
}

.icon-format_color_text {
  &:before {
    content: $icon-format_color_text;
  }
}

.icon-format_indent_decrease {
  &:before {
    content: $icon-format_indent_decrease;
  }
}

.icon-format_indent_increase {
  &:before {
    content: $icon-format_indent_increase;
  }
}

.icon-format_italic {
  &:before {
    content: $icon-format_italic;
  }
}

.icon-format_line_spacing {
  &:before {
    content: $icon-format_line_spacing;
  }
}

.icon-format_list_bulleted {
  &:before {
    content: $icon-format_list_bulleted;
  }
}

.icon-format_list_numbered {
  &:before {
    content: $icon-format_list_numbered;
  }
}

.icon-format_paint {
  &:before {
    content: $icon-format_paint;
  }
}

.icon-format_quote {
  &:before {
    content: $icon-format_quote;
  }
}

.icon-format_shapes {
  &:before {
    content: $icon-format_shapes;
  }
}

.icon-format_size {
  &:before {
    content: $icon-format_size;
  }
}

.icon-format_strikethrough {
  &:before {
    content: $icon-format_strikethrough;
  }
}

.icon-format_textdirection_l_to_r {
  &:before {
    content: $icon-format_textdirection_l_to_r;
  }
}

.icon-format_textdirection_r_to_l {
  &:before {
    content: $icon-format_textdirection_r_to_l;
  }
}

.icon-format_underlined {
  &:before {
    content: $icon-format_underlined;
  }
}

.icon-forward {
  &:before {
    content: $icon-forward;
  }
}

.icon-forward_10 {
  &:before {
    content: $icon-forward_10;
  }
}

.icon-forward_30 {
  &:before {
    content: $icon-forward_30;
  }
}

.icon-forward_5 {
  &:before {
    content: $icon-forward_5;
  }
}

.icon-free_breakfast {
  &:before {
    content: $icon-free_breakfast;
  }
}

.icon-fullscreen {
  &:before {
    content: $icon-fullscreen;
  }
}

.icon-fullscreen_exit {
  &:before {
    content: $icon-fullscreen_exit;
  }
}

.icon-functions {
  &:before {
    content: $icon-functions;
  }
}

.icon-g_translate {
  &:before {
    content: $icon-g_translate;
  }
}

.icon-games {
  &:before {
    content: $icon-games;
  }
}

.icon-gavel {
  &:before {
    content: $icon-gavel;
  }
}

.icon-gesture {
  &:before {
    content: $icon-gesture;
  }
}

.icon-get_app {
  &:before {
    content: $icon-get_app;
  }
}

.icon-gif {
  &:before {
    content: $icon-gif;
  }
}

.icon-goat {
  &:before {
    content: $icon-goat;
  }
}

.icon-golf_course {
  &:before {
    content: $icon-golf_course;
  }
}

.icon-gradient {
  &:before {
    content: $icon-gradient;
  }
}

.icon-grain {
  &:before {
    content: $icon-grain;
  }
}

.icon-graphic_eq {
  &:before {
    content: $icon-graphic_eq;
  }
}

.icon-grid_off {
  &:before {
    content: $icon-grid_off;
  }
}

.icon-grid_on {
  &:before {
    content: $icon-grid_on;
  }
}

.icon-group_add {
  &:before {
    content: $icon-group_add;
  }
}

.icon-group_work {
  &:before {
    content: $icon-group_work;
  }
}

.icon-hd {
  &:before {
    content: $icon-hd;
  }
}

.icon-hdr_off {
  &:before {
    content: $icon-hdr_off;
  }
}

.icon-hdr_on {
  &:before {
    content: $icon-hdr_on;
  }
}

.icon-hdr_strong {
  &:before {
    content: $icon-hdr_strong;
  }
}

.icon-hdr_weak {
  &:before {
    content: $icon-hdr_weak;
  }
}

.icon-headset {
  &:before {
    content: $icon-headset;
  }
}

.icon-headset_mic {
  &:before {
    content: $icon-headset_mic;
  }
}

.icon-healing {
  &:before {
    content: $icon-healing;
  }
}

.icon-hearing {
  &:before {
    content: $icon-hearing;
  }
}

.icon-help {
  &:before {
    content: $icon-help;
  }
}

.icon-help_outline {
  &:before {
    content: $icon-help_outline;
  }
}

.icon-high_quality {
  &:before {
    content: $icon-high_quality;
  }
}

.icon-highlight {
  &:before {
    content: $icon-highlight;
  }
}

.icon-highlight_off {
  &:before {
    content: $icon-highlight_off;
  }
}

.icon-home {
  &:before {
    content: $icon-home;
  }
}

.icon-hot_tub {
  &:before {
    content: $icon-hot_tub;
  }
}

.icon-hourglass_empty {
  &:before {
    content: $icon-hourglass_empty;
  }
}

.icon-hourglass_full {
  &:before {
    content: $icon-hourglass_full;
  }
}

.icon-http {
  &:before {
    content: $icon-http;
  }
}

.icon-image_aspect_ratio {
  &:before {
    content: $icon-image_aspect_ratio;
  }
}

.icon-import_contacts {
  &:before {
    content: $icon-import_contacts;
  }
}

.icon-import_export {
  &:before {
    content: $icon-import_export;
  }
}

.icon-important_devices {
  &:before {
    content: $icon-important_devices;
  }
}

.icon-inbox {
  &:before {
    content: $icon-inbox;
  }
}

.icon-indeterminate_check_box {
  &:before {
    content: $icon-indeterminate_check_box;
  }
}

.icon-info {
  &:before {
    content: $icon-info;
  }
}

.icon-info_outline {
  &:before {
    content: $icon-info_outline;
  }
}

.icon-input {
  &:before {
    content: $icon-input;
  }
}

.icon-insert_comment {
  &:before {
    content: $icon-insert_comment;
  }
}

.icon-insert_drive_file {
  &:before {
    content: $icon-insert_drive_file;
  }
}

.icon-insert_invitation {
  &:before {
    content: $icon-insert_invitation;
  }
}

.icon-invert_colors {
  &:before {
    content: $icon-invert_colors;
  }
}

.icon-invert_colors_off {
  &:before {
    content: $icon-invert_colors_off;
  }
}

.icon-iso {
  &:before {
    content: $icon-iso;
  }
}

.icon-keyboard {
  &:before {
    content: $icon-keyboard;
  }
}

.icon-chevron_down {
  &:before {
    content: $icon-chevron_down;
  }
}

.icon-chevron_left {
  &:before {
    content: $icon-chevron_left;
  }
}

.icon-chevron_right {
  &:before {
    content: $icon-chevron_right;
  }
}

.icon-chevron_up {
  &:before {
    content: $icon-chevron_up;
  }
}

.icon-keyboard_backspace {
  &:before {
    content: $icon-keyboard_backspace;
  }
}

.icon-keyboard_capslock {
  &:before {
    content: $icon-keyboard_capslock;
  }
}

.icon-keyboard_hide {
  &:before {
    content: $icon-keyboard_hide;
  }
}

.icon-keyboard_return {
  &:before {
    content: $icon-keyboard_return;
  }
}

.icon-keyboard_tab {
  &:before {
    content: $icon-keyboard_tab;
  }
}

.icon-keyboard_voice {
  &:before {
    content: $icon-keyboard_voice;
  }
}

.icon-kitchen {
  &:before {
    content: $icon-kitchen;
  }
}

.icon-label {
  &:before {
    content: $icon-label;
  }
}

.icon-label_outline {
  &:before {
    content: $icon-label_outline;
  }
}

.icon-language {
  &:before {
    content: $icon-language;
  }
}

.icon-laptop {
  &:before {
    content: $icon-laptop;
  }
}

.icon-laptop_chromebook {
  &:before {
    content: $icon-laptop_chromebook;
  }
}

.icon-laptop_mac {
  &:before {
    content: $icon-laptop_mac;
  }
}

.icon-laptop_windows {
  &:before {
    content: $icon-laptop_windows;
  }
}

.icon-last_page {
  &:before {
    content: $icon-last_page;
  }
}

.icon-layers {
  &:before {
    content: $icon-layers;
  }
}

.icon-layers_clear {
  &:before {
    content: $icon-layers_clear;
  }
}

.icon-leak_add {
  &:before {
    content: $icon-leak_add;
  }
}

.icon-leak_remove {
  &:before {
    content: $icon-leak_remove;
  }
}

.icon-lens {
  &:before {
    content: $icon-lens;
  }
}

.icon-library_books {
  &:before {
    content: $icon-library_books;
  }
}

.icon-library_music {
  &:before {
    content: $icon-library_music;
  }
}

.icon-lightbulb_outline {
  &:before {
    content: $icon-lightbulb_outline;
  }
}

.icon-line_style {
  &:before {
    content: $icon-line_style;
  }
}

.icon-line_weight {
  &:before {
    content: $icon-line_weight;
  }
}

.icon-linear_scale {
  &:before {
    content: $icon-linear_scale;
  }
}

.icon-link {
  &:before {
    content: $icon-link;
  }
}

.icon-linked_camera {
  &:before {
    content: $icon-linked_camera;
  }
}

.icon-list {
  &:before {
    content: $icon-list;
  }
}

.icon-live_help {
  &:before {
    content: $icon-live_help;
  }
}

.icon-live_tv {
  &:before {
    content: $icon-live_tv;
  }
}

.icon-local_airport {
  &:before {
    content: $icon-local_airport;
  }
}

.icon-local_atm {
  &:before {
    content: $icon-local_atm;
  }
}

.icon-local_bar {
  &:before {
    content: $icon-local_bar;
  }
}

.icon-local_cafe {
  &:before {
    content: $icon-local_cafe;
  }
}

.icon-local_car_wash {
  &:before {
    content: $icon-local_car_wash;
  }
}

.icon-local_convenience_store {
  &:before {
    content: $icon-local_convenience_store;
  }
}

.icon-local_drink {
  &:before {
    content: $icon-local_drink;
  }
}

.icon-local_florist {
  &:before {
    content: $icon-local_florist;
  }
}

.icon-local_gas_station {
  &:before {
    content: $icon-local_gas_station;
  }
}

.icon-local_hospital {
  &:before {
    content: $icon-local_hospital;
  }
}

.icon-local_hotel {
  &:before {
    content: $icon-local_hotel;
  }
}

.icon-local_laundry_service {
  &:before {
    content: $icon-local_laundry_service;
  }
}

.icon-local_library {
  &:before {
    content: $icon-local_library;
  }
}

.icon-local_mall {
  &:before {
    content: $icon-local_mall;
  }
}

.icon-local_offer {
  &:before {
    content: $icon-local_offer;
  }
}

.icon-local_parking {
  &:before {
    content: $icon-local_parking;
  }
}

.icon-local_pharmacy {
  &:before {
    content: $icon-local_pharmacy;
  }
}

.icon-local_pizza {
  &:before {
    content: $icon-local_pizza;
  }
}

.icon-local_play {
  &:before {
    content: $icon-local_play;
  }
}

.icon-local_shipping {
  &:before {
    content: $icon-local_shipping;
  }
}

.icon-local_taxi {
  &:before {
    content: $icon-local_taxi;
  }
}

.icon-location_city {
  &:before {
    content: $icon-location_city;
  }
}

.icon-location_disabled {
  &:before {
    content: $icon-location_disabled;
  }
}

.icon-location_off {
  &:before {
    content: $icon-location_off;
  }
}

.icon-location_searching {
  &:before {
    content: $icon-location_searching;
  }
}

.icon-lock {
  &:before {
    content: $icon-lock;
  }
}

.icon-lock_open {
  &:before {
    content: $icon-lock_open;
  }
}

.icon-lock_outline {
  &:before {
    content: $icon-lock_outline;
  }
}

.icon-looks {
  &:before {
    content: $icon-looks;
  }
}

.icon-looks_3 {
  &:before {
    content: $icon-looks_3;
  }
}

.icon-looks_4 {
  &:before {
    content: $icon-looks_4;
  }
}

.icon-looks_5 {
  &:before {
    content: $icon-looks_5;
  }
}

.icon-looks_6 {
  &:before {
    content: $icon-looks_6;
  }
}

.icon-looks_one {
  &:before {
    content: $icon-looks_one;
  }
}

.icon-looks_two {
  &:before {
    content: $icon-looks_two;
  }
}

.icon-loupe {
  &:before {
    content: $icon-loupe;
  }
}

.icon-low_priority {
  &:before {
    content: $icon-low_priority;
  }
}

.icon-loyalty {
  &:before {
    content: $icon-loyalty;
  }
}

.icon-mail_outline {
  &:before {
    content: $icon-mail_outline;
  }
}

.icon-map {
  &:before {
    content: $icon-map;
  }
}

.icon-markunread {
  &:before {
    content: $icon-markunread;
  }
}

.icon-markunread_mailbox {
  &:before {
    content: $icon-markunread_mailbox;
  }
}

.icon-memory {
  &:before {
    content: $icon-memory;
  }
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-merge_type {
  &:before {
    content: $icon-merge_type;
  }
}

.icon-message {
  &:before {
    content: $icon-message;
  }
}

.icon-mic {
  &:before {
    content: $icon-mic;
  }
}

.icon-mic_none {
  &:before {
    content: $icon-mic_none;
  }
}

.icon-mic_off {
  &:before {
    content: $icon-mic_off;
  }
}

.icon-mms {
  &:before {
    content: $icon-mms;
  }
}

.icon-mode_comment {
  &:before {
    content: $icon-mode_comment;
  }
}

.icon-mode_edit {
  &:before {
    content: $icon-mode_edit;
  }
}

.icon-monetization_on {
  &:before {
    content: $icon-monetization_on;
  }
}

.icon-money_off {
  &:before {
    content: $icon-money_off;
  }
}

.icon-monochrome_photos {
  &:before {
    content: $icon-monochrome_photos;
  }
}

.icon-mood_bad {
  &:before {
    content: $icon-mood_bad;
  }
}

.icon-more {
  &:before {
    content: $icon-more;
  }
}

.icon-more_horiz {
  &:before {
    content: $icon-more_horiz;
  }
}

.icon-more_vert {
  &:before {
    content: $icon-more_vert;
  }
}

.icon-motorcycle {
  &:before {
    content: $icon-motorcycle;
  }
}

.icon-mouse {
  &:before {
    content: $icon-mouse;
  }
}

.icon-move_to_inbox {
  &:before {
    content: $icon-move_to_inbox;
  }
}

.icon-movie_creation {
  &:before {
    content: $icon-movie_creation;
  }
}

.icon-movie_filter {
  &:before {
    content: $icon-movie_filter;
  }
}

.icon-multiline_chart {
  &:before {
    content: $icon-multiline_chart;
  }
}

.icon-music_note {
  &:before {
    content: $icon-music_note;
  }
}

.icon-music_video {
  &:before {
    content: $icon-music_video;
  }
}

.icon-my_location {
  &:before {
    content: $icon-my_location;
  }
}

.icon-nature {
  &:before {
    content: $icon-nature;
  }
}

.icon-nature_people {
  &:before {
    content: $icon-nature_people;
  }
}

.icon-navigate_before {
  &:before {
    content: $icon-navigate_before;
  }
}

.icon-navigate_next {
  &:before {
    content: $icon-navigate_next;
  }
}

.icon-navigation {
  &:before {
    content: $icon-navigation;
  }
}

.icon-near_me {
  &:before {
    content: $icon-near_me;
  }
}

.icon-network_cell {
  &:before {
    content: $icon-network_cell;
  }
}

.icon-network_check {
  &:before {
    content: $icon-network_check;
  }
}

.icon-network_locked {
  &:before {
    content: $icon-network_locked;
  }
}

.icon-network_wifi {
  &:before {
    content: $icon-network_wifi;
  }
}

.icon-new_releases {
  &:before {
    content: $icon-new_releases;
  }
}

.icon-next_week {
  &:before {
    content: $icon-next_week;
  }
}

.icon-nfc {
  &:before {
    content: $icon-nfc;
  }
}

.icon-no_encryption {
  &:before {
    content: $icon-no_encryption;
  }
}

.icon-not_interested {
  &:before {
    content: $icon-not_interested;
  }
}

.icon-note {
  &:before {
    content: $icon-note;
  }
}

.icon-note_add {
  &:before {
    content: $icon-note_add;
  }
}

.icon-notifications {
  &:before {
    content: $icon-notifications;
  }
}

.icon-notifications_active {
  &:before {
    content: $icon-notifications_active;
  }
}

.icon-notifications_none {
  &:before {
    content: $icon-notifications_none;
  }
}

.icon-notifications_off {
  &:before {
    content: $icon-notifications_off;
  }
}

.icon-notifications_paused {
  &:before {
    content: $icon-notifications_paused;
  }
}

.icon-offline_pin {
  &:before {
    content: $icon-offline_pin;
  }
}

.icon-ondemand_video {
  &:before {
    content: $icon-ondemand_video;
  }
}

.icon-opacity {
  &:before {
    content: $icon-opacity;
  }
}

.icon-open_in_browser {
  &:before {
    content: $icon-open_in_browser;
  }
}

.icon-open_in_new {
  &:before {
    content: $icon-open_in_new;
  }
}

.icon-open_with {
  &:before {
    content: $icon-open_with;
  }
}

.icon-pages {
  &:before {
    content: $icon-pages;
  }
}

.icon-pageview {
  &:before {
    content: $icon-pageview;
  }
}

.icon-palette {
  &:before {
    content: $icon-palette;
  }
}

.icon-pan_tool {
  &:before {
    content: $icon-pan_tool;
  }
}

.icon-panorama {
  &:before {
    content: $icon-panorama;
  }
}

.icon-panorama_horizontal {
  &:before {
    content: $icon-panorama_horizontal;
  }
}

.icon-panorama_vertical {
  &:before {
    content: $icon-panorama_vertical;
  }
}

.icon-panorama_wide_angle {
  &:before {
    content: $icon-panorama_wide_angle;
  }
}

.icon-party_mode {
  &:before {
    content: $icon-party_mode;
  }
}

.icon-pause {
  &:before {
    content: $icon-pause;
  }
}

.icon-pause_circle_filled {
  &:before {
    content: $icon-pause_circle_filled;
  }
}

.icon-pause_circle_outline {
  &:before {
    content: $icon-pause_circle_outline;
  }
}

.icon-payment {
  &:before {
    content: $icon-payment;
  }
}

.icon-people {
  &:before {
    content: $icon-people;
  }
}

.icon-people_outline {
  &:before {
    content: $icon-people_outline;
  }
}

.icon-perm_camera_mic {
  &:before {
    content: $icon-perm_camera_mic;
  }
}

.icon-perm_contact_calendar {
  &:before {
    content: $icon-perm_contact_calendar;
  }
}

.icon-perm_data_setting {
  &:before {
    content: $icon-perm_data_setting;
  }
}

.icon-perm_device_information {
  &:before {
    content: $icon-perm_device_information;
  }
}

.icon-perm_media {
  &:before {
    content: $icon-perm_media;
  }
}

.icon-perm_phone_msg {
  &:before {
    content: $icon-perm_phone_msg;
  }
}

.icon-perm_scan_wifi {
  &:before {
    content: $icon-perm_scan_wifi;
  }
}

.icon-person {
  &:before {
    content: $icon-person;
  }
}

.icon-person_add {
  &:before {
    content: $icon-person_add;
  }
}

.icon-person_outline {
  &:before {
    content: $icon-person_outline;
  }
}

.icon-person_pin {
  &:before {
    content: $icon-person_pin;
  }
}

.icon-person_pin_circle {
  &:before {
    content: $icon-person_pin_circle;
  }
}

.icon-personal_video {
  &:before {
    content: $icon-personal_video;
  }
}

.icon-pets {
  &:before {
    content: $icon-pets;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-phone_android {
  &:before {
    content: $icon-phone_android;
  }
}

.icon-phone_bluetooth_speaker {
  &:before {
    content: $icon-phone_bluetooth_speaker;
  }
}

.icon-phone_forwarded {
  &:before {
    content: $icon-phone_forwarded;
  }
}

.icon-phone_in_talk {
  &:before {
    content: $icon-phone_in_talk;
  }
}

.icon-phone_iphone {
  &:before {
    content: $icon-phone_iphone;
  }
}

.icon-phone_locked {
  &:before {
    content: $icon-phone_locked;
  }
}

.icon-phone_missed {
  &:before {
    content: $icon-phone_missed;
  }
}

.icon-phone_paused {
  &:before {
    content: $icon-phone_paused;
  }
}

.icon-phonelink {
  &:before {
    content: $icon-phonelink;
  }
}

.icon-phonelink_erase {
  &:before {
    content: $icon-phonelink_erase;
  }
}

.icon-phonelink_lock {
  &:before {
    content: $icon-phonelink_lock;
  }
}

.icon-phonelink_off {
  &:before {
    content: $icon-phonelink_off;
  }
}

.icon-phonelink_ring {
  &:before {
    content: $icon-phonelink_ring;
  }
}

.icon-phonelink_setup {
  &:before {
    content: $icon-phonelink_setup;
  }
}

.icon-photo {
  &:before {
    content: $icon-photo;
  }
}

.icon-photo_album {
  &:before {
    content: $icon-photo_album;
  }
}

.icon-photo_camera {
  &:before {
    content: $icon-photo_camera;
  }
}

.icon-photo_filter {
  &:before {
    content: $icon-photo_filter;
  }
}

.icon-photo_library {
  &:before {
    content: $icon-photo_library;
  }
}

.icon-photo_size_select_actual {
  &:before {
    content: $icon-photo_size_select_actual;
  }
}

.icon-photo_size_select_large {
  &:before {
    content: $icon-photo_size_select_large;
  }
}

.icon-photo_size_select_small {
  &:before {
    content: $icon-photo_size_select_small;
  }
}

.icon-picture_as_pdf {
  &:before {
    content: $icon-picture_as_pdf;
  }
}

.icon-picture_in_picture {
  &:before {
    content: $icon-picture_in_picture;
  }
}

.icon-picture_in_picture_alt {
  &:before {
    content: $icon-picture_in_picture_alt;
  }
}

.icon-pie_chart {
  &:before {
    content: $icon-pie_chart;
  }
}

.icon-pie_chart_outlined {
  &:before {
    content: $icon-pie_chart_outlined;
  }
}

.icon-pin_drop {
  &:before {
    content: $icon-pin_drop;
  }
}

.icon-play_arrow {
  &:before {
    content: $icon-play_arrow;
  }
}

.icon-play_circle_filled {
  &:before {
    content: $icon-play_circle_filled;
  }
}

.icon-play_circle_outline {
  &:before {
    content: $icon-play_circle_outline;
  }
}

.icon-play_for_work {
  &:before {
    content: $icon-play_for_work;
  }
}

.icon-playlist_add {
  &:before {
    content: $icon-playlist_add;
  }
}

.icon-playlist_add_check {
  &:before {
    content: $icon-playlist_add_check;
  }
}

.icon-playlist_play {
  &:before {
    content: $icon-playlist_play;
  }
}

.icon-plus_one {
  &:before {
    content: $icon-plus_one;
  }
}

.icon-poll {
  &:before {
    content: $icon-poll;
  }
}

.icon-polymer {
  &:before {
    content: $icon-polymer;
  }
}

.icon-pool {
  &:before {
    content: $icon-pool;
  }
}

.icon-portable_wifi_off {
  &:before {
    content: $icon-portable_wifi_off;
  }
}

.icon-portrait {
  &:before {
    content: $icon-portrait;
  }
}

.icon-power {
  &:before {
    content: $icon-power;
  }
}

.icon-power_input {
  &:before {
    content: $icon-power_input;
  }
}

.icon-power_settings_new {
  &:before {
    content: $icon-power_settings_new;
  }
}

.icon-pregnant_woman {
  &:before {
    content: $icon-pregnant_woman;
  }
}

.icon-present_to_all {
  &:before {
    content: $icon-present_to_all;
  }
}

.icon-print {
  &:before {
    content: $icon-print;
  }
}

.icon-priority_high {
  &:before {
    content: $icon-priority_high;
  }
}

.icon-public {
  &:before {
    content: $icon-public;
  }
}

.icon-publish {
  &:before {
    content: $icon-publish;
  }
}

.icon-question_answer {
  &:before {
    content: $icon-question_answer;
  }
}

.icon-queue {
  &:before {
    content: $icon-queue;
  }
}

.icon-queue_music {
  &:before {
    content: $icon-queue_music;
  }
}

.icon-queue_play_next {
  &:before {
    content: $icon-queue_play_next;
  }
}

.icon-radio {
  &:before {
    content: $icon-radio;
  }
}

.icon-radio_button_checked {
  &:before {
    content: $icon-radio_button_checked;
  }
}

.icon-radio_button_unchecked {
  &:before {
    content: $icon-radio_button_unchecked;
  }
}

.icon-rate_review {
  &:before {
    content: $icon-rate_review;
  }
}

.icon-receipt {
  &:before {
    content: $icon-receipt;
  }
}

.icon-recent_actors {
  &:before {
    content: $icon-recent_actors;
  }
}

.icon-record_voice_over {
  &:before {
    content: $icon-record_voice_over;
  }
}

.icon-redeem {
  &:before {
    content: $icon-redeem;
  }
}

.icon-redo {
  &:before {
    content: $icon-redo;
  }
}

.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}

.icon-remove {
  &:before {
    content: $icon-remove;
  }
}

.icon-remove_circle {
  &:before {
    content: $icon-remove_circle;
  }
}

.icon-remove_circle_outline {
  &:before {
    content: $icon-remove_circle_outline;
  }
}

.icon-remove_from_queue {
  &:before {
    content: $icon-remove_from_queue;
  }
}

.icon-remove_shopping_cart {
  &:before {
    content: $icon-remove_shopping_cart;
  }
}

.icon-reorder {
  &:before {
    content: $icon-reorder;
  }
}

.icon-repeat {
  &:before {
    content: $icon-repeat;
  }
}

.icon-repeat_one {
  &:before {
    content: $icon-repeat_one;
  }
}

.icon-replay {
  &:before {
    content: $icon-replay;
  }
}

.icon-replay_10 {
  &:before {
    content: $icon-replay_10;
  }
}

.icon-replay_30 {
  &:before {
    content: $icon-replay_30;
  }
}

.icon-replay_5 {
  &:before {
    content: $icon-replay_5;
  }
}

.icon-reply {
  &:before {
    content: $icon-reply;
  }
}

.icon-reply_all {
  &:before {
    content: $icon-reply_all;
  }
}

.icon-report {
  &:before {
    content: $icon-report;
  }
}

.icon-restaurant {
  &:before {
    content: $icon-restaurant;
  }
}

.icon-restaurant_menu {
  &:before {
    content: $icon-restaurant_menu;
  }
}

.icon-restore {
  &:before {
    content: $icon-restore;
  }
}

.icon-restore_page {
  &:before {
    content: $icon-restore_page;
  }
}

.icon-ring_volume {
  &:before {
    content: $icon-ring_volume;
  }
}

.icon-room {
  &:before {
    content: $icon-room;
  }
}

.icon-room_service {
  &:before {
    content: $icon-room_service;
  }
}

.icon-rotate_90_degrees_ccw {
  &:before {
    content: $icon-rotate_90_degrees_ccw;
  }
}

.icon-rotate_left {
  &:before {
    content: $icon-rotate_left;
  }
}

.icon-rotate_right {
  &:before {
    content: $icon-rotate_right;
  }
}

.icon-rounded_corner {
  &:before {
    content: $icon-rounded_corner;
  }
}

.icon-router {
  &:before {
    content: $icon-router;
  }
}

.icon-rowing {
  &:before {
    content: $icon-rowing;
  }
}

.icon-rss_feed {
  &:before {
    content: $icon-rss_feed;
  }
}

.icon-rv_hookup {
  &:before {
    content: $icon-rv_hookup;
  }
}

.icon-satellite {
  &:before {
    content: $icon-satellite;
  }
}

.icon-save {
  &:before {
    content: $icon-save;
  }
}

.icon-scanner {
  &:before {
    content: $icon-scanner;
  }
}

.icon-schedule {
  &:before {
    content: $icon-schedule;
  }
}

.icon-school {
  &:before {
    content: $icon-school;
  }
}

.icon-screen_lock_landscape {
  &:before {
    content: $icon-screen_lock_landscape;
  }
}

.icon-screen_lock_portrait {
  &:before {
    content: $icon-screen_lock_portrait;
  }
}

.icon-screen_lock_rotation {
  &:before {
    content: $icon-screen_lock_rotation;
  }
}

.icon-screen_rotation {
  &:before {
    content: $icon-screen_rotation;
  }
}

.icon-screen_share {
  &:before {
    content: $icon-screen_share;
  }
}

.icon-sd_storage {
  &:before {
    content: $icon-sd_storage;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-security {
  &:before {
    content: $icon-security;
  }
}

.icon-select_all {
  &:before {
    content: $icon-select_all;
  }
}

.icon-send {
  &:before {
    content: $icon-send;
  }
}

.icon-sentiment_dissatisfied {
  &:before {
    content: $icon-sentiment_dissatisfied;
  }
}

.icon-sentiment_neutral {
  &:before {
    content: $icon-sentiment_neutral;
  }
}

.icon-sentiment_satisfied {
  &:before {
    content: $icon-sentiment_satisfied;
  }
}

.icon-sentiment_very_dissatisfied {
  &:before {
    content: $icon-sentiment_very_dissatisfied;
  }
}

.icon-sentiment_very_satisfied {
  &:before {
    content: $icon-sentiment_very_satisfied;
  }
}

.icon-settings {
  &:before {
    content: $icon-settings;
  }
}

.icon-settings_applications {
  &:before {
    content: $icon-settings_applications;
  }
}

.icon-settings_backup_restore {
  &:before {
    content: $icon-settings_backup_restore;
  }
}

.icon-settings_bluetooth {
  &:before {
    content: $icon-settings_bluetooth;
  }
}

.icon-settings_brightness {
  &:before {
    content: $icon-settings_brightness;
  }
}

.icon-settings_cell {
  &:before {
    content: $icon-settings_cell;
  }
}

.icon-settings_ethernet {
  &:before {
    content: $icon-settings_ethernet;
  }
}

.icon-settings_input_antenna {
  &:before {
    content: $icon-settings_input_antenna;
  }
}

.icon-settings_input_composite {
  &:before {
    content: $icon-settings_input_composite;
  }
}

.icon-settings_input_hdmi {
  &:before {
    content: $icon-settings_input_hdmi;
  }
}

.icon-settings_input_svideo {
  &:before {
    content: $icon-settings_input_svideo;
  }
}

.icon-settings_overscan {
  &:before {
    content: $icon-settings_overscan;
  }
}

.icon-settings_phone {
  &:before {
    content: $icon-settings_phone;
  }
}

.icon-settings_power {
  &:before {
    content: $icon-settings_power;
  }
}

.icon-settings_remote {
  &:before {
    content: $icon-settings_remote;
  }
}

.icon-settings_system_daydream {
  &:before {
    content: $icon-settings_system_daydream;
  }
}

.icon-settings_voice {
  &:before {
    content: $icon-settings_voice;
  }
}

.icon-share {
  &:before {
    content: $icon-share;
  }
}

.icon-shop {
  &:before {
    content: $icon-shop;
  }
}

.icon-shop_two {
  &:before {
    content: $icon-shop_two;
  }
}

.icon-shopping_basket {
  &:before {
    content: $icon-shopping_basket;
  }
}

.icon-shopping_cart {
  &:before {
    content: $icon-shopping_cart;
  }
}

.icon-short_text {
  &:before {
    content: $icon-short_text;
  }
}

.icon-show_chart {
  &:before {
    content: $icon-show_chart;
  }
}

.icon-shuffle {
  &:before {
    content: $icon-shuffle;
  }
}

.icon-signal_cellular_4_bar {
  &:before {
    content: $icon-signal_cellular_4_bar;
  }
}

.icon-signal_cellular_connected_no_internet_4_bar {
  &:before {
    content: $icon-signal_cellular_connected_no_internet_4_bar;
  }
}

.icon-signal_cellular_no_sim {
  &:before {
    content: $icon-signal_cellular_no_sim;
  }
}

.icon-signal_cellular_null {
  &:before {
    content: $icon-signal_cellular_null;
  }
}

.icon-signal_cellular_off {
  &:before {
    content: $icon-signal_cellular_off;
  }
}

.icon-signal_wifi_4_bar {
  &:before {
    content: $icon-signal_wifi_4_bar;
  }
}

.icon-signal_wifi_4_bar_lock {
  &:before {
    content: $icon-signal_wifi_4_bar_lock;
  }
}

.icon-signal_wifi_off {
  &:before {
    content: $icon-signal_wifi_off;
  }
}

.icon-sim_card {
  &:before {
    content: $icon-sim_card;
  }
}

.icon-sim_card_alert {
  &:before {
    content: $icon-sim_card_alert;
  }
}

.icon-skip_next {
  &:before {
    content: $icon-skip_next;
  }
}

.icon-skip_previous {
  &:before {
    content: $icon-skip_previous;
  }
}

.icon-slideshow {
  &:before {
    content: $icon-slideshow;
  }
}

.icon-slow_motion_video {
  &:before {
    content: $icon-slow_motion_video;
  }
}

.icon-smoke_free {
  &:before {
    content: $icon-smoke_free;
  }
}

.icon-smoking_rooms {
  &:before {
    content: $icon-smoking_rooms;
  }
}

.icon-sms_failed {
  &:before {
    content: $icon-sms_failed;
  }
}

.icon-snooze {
  &:before {
    content: $icon-snooze;
  }
}

.icon-sort {
  &:before {
    content: $icon-sort;
  }
}

.icon-sort_by_alpha {
  &:before {
    content: $icon-sort_by_alpha;
  }
}

.icon-spa {
  &:before {
    content: $icon-spa;
  }
}

.icon-space_bar {
  &:before {
    content: $icon-space_bar;
  }
}

.icon-speaker {
  &:before {
    content: $icon-speaker;
  }
}

.icon-speaker_group {
  &:before {
    content: $icon-speaker_group;
  }
}

.icon-speaker_notes {
  &:before {
    content: $icon-speaker_notes;
  }
}

.icon-speaker_notes_off {
  &:before {
    content: $icon-speaker_notes_off;
  }
}

.icon-speaker_phone {
  &:before {
    content: $icon-speaker_phone;
  }
}

.icon-spellcheck {
  &:before {
    content: $icon-spellcheck;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-star_border {
  &:before {
    content: $icon-star_border;
  }
}

.icon-star_half {
  &:before {
    content: $icon-star_half;
  }
}

.icon-stars {
  &:before {
    content: $icon-stars;
  }
}

.icon-stay_primary_landscape {
  &:before {
    content: $icon-stay_primary_landscape;
  }
}

.icon-stay_primary_portrait {
  &:before {
    content: $icon-stay_primary_portrait;
  }
}

.icon-stop {
  &:before {
    content: $icon-stop;
  }
}

.icon-stop_screen_share {
  &:before {
    content: $icon-stop_screen_share;
  }
}

.icon-storage {
  &:before {
    content: $icon-storage;
  }
}

.icon-store_mall_directory {
  &:before {
    content: $icon-store_mall_directory;
  }
}

.icon-straighten {
  &:before {
    content: $icon-straighten;
  }
}

.icon-streetview {
  &:before {
    content: $icon-streetview;
  }
}

.icon-strikethrough_s {
  &:before {
    content: $icon-strikethrough_s;
  }
}

.icon-style {
  &:before {
    content: $icon-style;
  }
}

.icon-subdirectory_arrow_left {
  &:before {
    content: $icon-subdirectory_arrow_left;
  }
}

.icon-subdirectory_arrow_right {
  &:before {
    content: $icon-subdirectory_arrow_right;
  }
}

.icon-subject {
  &:before {
    content: $icon-subject;
  }
}

.icon-subscriptions {
  &:before {
    content: $icon-subscriptions;
  }
}

.icon-subtitles {
  &:before {
    content: $icon-subtitles;
  }
}

.icon-subway {
  &:before {
    content: $icon-subway;
  }
}

.icon-supervisor_account {
  &:before {
    content: $icon-supervisor_account;
  }
}

.icon-surround_sound {
  &:before {
    content: $icon-surround_sound;
  }
}

.icon-swap_calls {
  &:before {
    content: $icon-swap_calls;
  }
}

.icon-swap_horiz {
  &:before {
    content: $icon-swap_horiz;
  }
}

.icon-swap_vert {
  &:before {
    content: $icon-swap_vert;
  }
}

.icon-swap_vertical_circle {
  &:before {
    content: $icon-swap_vertical_circle;
  }
}

.icon-switch_camera {
  &:before {
    content: $icon-switch_camera;
  }
}

.icon-switch_video {
  &:before {
    content: $icon-switch_video;
  }
}

.icon-sync {
  &:before {
    content: $icon-sync;
  }
}

.icon-sync_disabled {
  &:before {
    content: $icon-sync_disabled;
  }
}

.icon-sync_problem {
  &:before {
    content: $icon-sync_problem;
  }
}

.icon-system_update {
  &:before {
    content: $icon-system_update;
  }
}

.icon-system_update_alt {
  &:before {
    content: $icon-system_update_alt;
  }
}

.icon-tab {
  &:before {
    content: $icon-tab;
  }
}

.icon-tab_unselected {
  &:before {
    content: $icon-tab_unselected;
  }
}

.icon-tablet {
  &:before {
    content: $icon-tablet;
  }
}

.icon-tablet_android {
  &:before {
    content: $icon-tablet_android;
  }
}

.icon-tablet_mac {
  &:before {
    content: $icon-tablet_mac;
  }
}

.icon-tag_faces {
  &:before {
    content: $icon-tag_faces;
  }
}

.icon-tap_and_play {
  &:before {
    content: $icon-tap_and_play;
  }
}

.icon-terrain {
  &:before {
    content: $icon-terrain;
  }
}

.icon-text_fields {
  &:before {
    content: $icon-text_fields;
  }
}

.icon-text_format {
  &:before {
    content: $icon-text_format;
  }
}

.icon-textsms {
  &:before {
    content: $icon-textsms;
  }
}

.icon-texture {
  &:before {
    content: $icon-texture;
  }
}

.icon-theaters {
  &:before {
    content: $icon-theaters;
  }
}

.icon-thumb_down {
  &:before {
    content: $icon-thumb_down;
  }
}

.icon-thumb_up {
  &:before {
    content: $icon-thumb_up;
  }
}

.icon-thumbs_up_down {
  &:before {
    content: $icon-thumbs_up_down;
  }
}

.icon-time_to_leave {
  &:before {
    content: $icon-time_to_leave;
  }
}

.icon-timelapse {
  &:before {
    content: $icon-timelapse;
  }
}

.icon-timeline {
  &:before {
    content: $icon-timeline;
  }
}

.icon-timer {
  &:before {
    content: $icon-timer;
  }
}

.icon-timer_10 {
  &:before {
    content: $icon-timer_10;
  }
}

.icon-timer_3 {
  &:before {
    content: $icon-timer_3;
  }
}

.icon-timer_off {
  &:before {
    content: $icon-timer_off;
  }
}

.icon-title {
  &:before {
    content: $icon-title;
  }
}

.icon-toc {
  &:before {
    content: $icon-toc;
  }
}

.icon-today {
  &:before {
    content: $icon-today;
  }
}

.icon-toll {
  &:before {
    content: $icon-toll;
  }
}

.icon-tonality {
  &:before {
    content: $icon-tonality;
  }
}

.icon-touch_app {
  &:before {
    content: $icon-touch_app;
  }
}

.icon-toys {
  &:before {
    content: $icon-toys;
  }
}

.icon-track_changes {
  &:before {
    content: $icon-track_changes;
  }
}

.icon-traffic {
  &:before {
    content: $icon-traffic;
  }
}

.icon-train {
  &:before {
    content: $icon-train;
  }
}

.icon-tram {
  &:before {
    content: $icon-tram;
  }
}

.icon-transfer_within_a_station {
  &:before {
    content: $icon-transfer_within_a_station;
  }
}

.icon-transform {
  &:before {
    content: $icon-transform;
  }
}

.icon-translate {
  &:before {
    content: $icon-translate;
  }
}

.icon-trending_down {
  &:before {
    content: $icon-trending_down;
  }
}

.icon-trending_flat {
  &:before {
    content: $icon-trending_flat;
  }
}

.icon-trending_up {
  &:before {
    content: $icon-trending_up;
  }
}

.icon-tune {
  &:before {
    content: $icon-tune;
  }
}

.icon-turned_in {
  &:before {
    content: $icon-turned_in;
  }
}

.icon-turned_in_not {
  &:before {
    content: $icon-turned_in_not;
  }
}

.icon-tv {
  &:before {
    content: $icon-tv;
  }
}

.icon-unarchive {
  &:before {
    content: $icon-unarchive;
  }
}

.icon-undo {
  &:before {
    content: $icon-undo;
  }
}

.icon-unfold_less {
  &:before {
    content: $icon-unfold_less;
  }
}

.icon-unfold_more {
  &:before {
    content: $icon-unfold_more;
  }
}

.icon-update {
  &:before {
    content: $icon-update;
  }
}

.icon-usb {
  &:before {
    content: $icon-usb;
  }
}

.icon-verified_user {
  &:before {
    content: $icon-verified_user;
  }
}

.icon-vertical_align_bottom {
  &:before {
    content: $icon-vertical_align_bottom;
  }
}

.icon-vertical_align_center {
  &:before {
    content: $icon-vertical_align_center;
  }
}

.icon-vertical_align_top {
  &:before {
    content: $icon-vertical_align_top;
  }
}

.icon-vibration {
  &:before {
    content: $icon-vibration;
  }
}

.icon-video_call {
  &:before {
    content: $icon-video_call;
  }
}

.icon-video_label {
  &:before {
    content: $icon-video_label;
  }
}

.icon-video_library {
  &:before {
    content: $icon-video_library;
  }
}

.icon-videocam {
  &:before {
    content: $icon-videocam;
  }
}

.icon-videocam_off {
  &:before {
    content: $icon-videocam_off;
  }
}

.icon-videogame_asset {
  &:before {
    content: $icon-videogame_asset;
  }
}

.icon-view_agenda {
  &:before {
    content: $icon-view_agenda;
  }
}

.icon-view_array {
  &:before {
    content: $icon-view_array;
  }
}

.icon-view_carousel {
  &:before {
    content: $icon-view_carousel;
  }
}

.icon-view_column {
  &:before {
    content: $icon-view_column;
  }
}

.icon-view_comfy {
  &:before {
    content: $icon-view_comfy;
  }
}

.icon-view_compact {
  &:before {
    content: $icon-view_compact;
  }
}

.icon-view_day {
  &:before {
    content: $icon-view_day;
  }
}

.icon-view_headline {
  &:before {
    content: $icon-view_headline;
  }
}

.icon-view_list {
  &:before {
    content: $icon-view_list;
  }
}

.icon-view_module {
  &:before {
    content: $icon-view_module;
  }
}

.icon-view_quilt {
  &:before {
    content: $icon-view_quilt;
  }
}

.icon-view_stream {
  &:before {
    content: $icon-view_stream;
  }
}

.icon-view_week {
  &:before {
    content: $icon-view_week;
  }
}

.icon-vignette {
  &:before {
    content: $icon-vignette;
  }
}

.icon-visibility {
  &:before {
    content: $icon-visibility;
  }
}

.icon-visibility_off {
  &:before {
    content: $icon-visibility_off;
  }
}

.icon-voice_chat {
  &:before {
    content: $icon-voice_chat;
  }
}

.icon-voicemail {
  &:before {
    content: $icon-voicemail;
  }
}

.icon-volume_down {
  &:before {
    content: $icon-volume_down;
  }
}

.icon-volume_mute {
  &:before {
    content: $icon-volume_mute;
  }
}

.icon-volume_off {
  &:before {
    content: $icon-volume_off;
  }
}

.icon-volume_up {
  &:before {
    content: $icon-volume_up;
  }
}

.icon-vpn_key {
  &:before {
    content: $icon-vpn_key;
  }
}

.icon-vpn_lock {
  &:before {
    content: $icon-vpn_lock;
  }
}

.icon-wallpaper {
  &:before {
    content: $icon-wallpaper;
  }
}

.icon-warning {
  &:before {
    content: $icon-warning;
  }
}

.icon-watch {
  &:before {
    content: $icon-watch;
  }
}

.icon-watch_later {
  &:before {
    content: $icon-watch_later;
  }
}

.icon-wb_auto {
  &:before {
    content: $icon-wb_auto;
  }
}

.icon-wb_cloudy {
  &:before {
    content: $icon-wb_cloudy;
  }
}

.icon-wb_incandescent {
  &:before {
    content: $icon-wb_incandescent;
  }
}

.icon-wb_iridescent {
  &:before {
    content: $icon-wb_iridescent;
  }
}

.icon-wb_sunny {
  &:before {
    content: $icon-wb_sunny;
  }
}

.icon-wc {
  &:before {
    content: $icon-wc;
  }
}

.icon-web {
  &:before {
    content: $icon-web;
  }
}

.icon-web_asset {
  &:before {
    content: $icon-web_asset;
  }
}

.icon-weekend {
  &:before {
    content: $icon-weekend;
  }
}

.icon-whatshot {
  &:before {
    content: $icon-whatshot;
  }
}

.icon-widgets {
  &:before {
    content: $icon-widgets;
  }
}

.icon-wifi {
  &:before {
    content: $icon-wifi;
  }
}

.icon-wifi_lock {
  &:before {
    content: $icon-wifi_lock;
  }
}

.icon-wifi_tethering {
  &:before {
    content: $icon-wifi_tethering;
  }
}

.icon-work {
  &:before {
    content: $icon-work;
  }
}

.icon-wrap_text {
  &:before {
    content: $icon-wrap_text;
  }
}

.icon-youtube_searched_for {
  &:before {
    content: $icon-youtube_searched_for;
  }
}

.icon-zoom_in {
  &:before {
    content: $icon-zoom_in;
  }
}

.icon-zoom_out {
  &:before {
    content: $icon-zoom_out;
  }
}

.icon-zoom_out_map {
  &:before {
    content: $icon-zoom_out_map;
  }
}