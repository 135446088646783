//////////////////////////////////
////////////////////////////////// Blocks
//////////////////////////////////
body {
   &.deployed--nav { overflow: hidden }
}


a {
   @include transitional;
   &:hover { @include transitional }
}

figure { margin: 0}

ul,
ol {
   margin: 1rem 0;
   padding-left: 1rem;
}

hr.separator{
	border-color: rgba(#cdcdcd, 1);
	width: calc(100% - 1rem);
	margin: 1rem auto;
}


.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 .5rem;
	width: 100%;
	@include media (tablet-down) {
		padding: 0 1rem;
	}

	>.row {
		@include media (tablet-up) {
			margin: 0 -.5rem;
			width: calc(100% + 1rem);
			max-width: calc(100% + 1rem);
		}
		@include media (tablet-down) {
			margin: 0 -1rem;
			width: calc(100% + 2rem);
			max-width: calc(100% + 2rem);
		}
	}
}

.row {
	flex: 0 0 auto;
	position: relative;
}

.wrap{
	overflow: hidden;
	position: relative;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 50000s ease-in-out 0s;
}

.elastic-img {
	display: block;
	max-width: 100%;
	height: auto;
}

.cover-img {
	display: block;
	width: 100%;
	height: auto;
}

.cover-object {
	@extend .cover-img;
	object-fit: cover;
	display: block;
	min-width: 100%;
	height: auto;
}

.file-img {
	max-width: 60%;
	margin: 0 15%;
	display: inline-block;
}

.content-box {
	padding: 1rem;
	margin: 1rem 0;
	background: $color_white;
	border: 1px solid map-get(map-get($colors_map, grey), light);
	border-radius: $base_border_radius;

	&:only-child {
		margin: 0;
	}
}

.rounded-img {
	border-radius: 50%;
}

.mq {
   background: $color_main_regular;
   text-align: center;
   color: white;
   padding: 1rem 0;
   text-transform: uppercase;
   position: fixed;
   left: 0;
   z-index: 666;
   bottom: 0;
   width: 100%;
   flex-direction: row;
   display: flex;
   align-items: center;
   justify-content: center;

   [data-check-width] {
      margin-left: 2rem;
      background: red;
   }
}




//////////////////////////////////
////////////////////////////////// Typoraphy
//////////////////////////////////
// font styles
.font-line-through {
	text-decoration: line-through;
}

.font-bold {
	font-weight: bold;
}

.font-italic {
	font-style: italic;
}

// font alignments
.font-centered {
	text-align: center;
}

.font-lefted {
	text-align: left;
}

.font-righted {
	text-align: right;
}

//float alignments
.float-center {
	margin-left: auto;
	margin-right: auto;
}

// font sizes
@each $size_key,
$size_val in $text_sizes {
	.font-size-#{$size_key} {
		font-size: $size_val;
	}
}

// font colors
@each $type,
$colors in $colors_map {

	@each $shade,
	$code in $colors {
		.font-color-#{$type}-#{$shade} {
			color: $code;
		}
	}
}

.cypher{
	&-up,&-down{
		&:after{ margin-left: .125rem }
	}
	&-up{
		color: $color_success_regular;
		&:after{
			@include get-icon($icon-caret-up);
		}
	}
	&-down{
		color: $color_danger_regular;
		&:after{
			@include get-icon($icon-caret-down);
		}
	}
}

//////////////////////////////////
////////////////////////////////// Helper classes
//////////////////////////////////
// backgrounds and borders
@each $type,
$colors in $colors_map {

	@each $shade,
	$code in $colors {
		.bg-#{$type}-#{$shade} {
			background: $code;
		}
	}
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.clearfix {
	zoom: 1;

	&::before,
	&::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}
}

////////////////////
//common helpers////
///////////////////
textarea {
	resize: none;
}

.text-upper {
	text-transform: uppercase;
}

.text-lower {
	text-transform: lowercase;
}

.text-capital {
	text-transform: capitalize;
}

.no-radius {
	border-radius: 0;
}

.no-border {
	border: 0;
}

.click-handler {
	z-index: 5;
	@extend .icon;
	@extend .icon-chevron_down;
	@include rotate-x(0);
}