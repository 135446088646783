.image-box {
	display: block;
	padding: .5rem;
	background: $color_white;
	border: 1px solid map-get(map-get($colors_map, grey), light);
}

//Sectors

.main--fixed{
	padding-top: 120px;
	&_mobile{
		@include media (tablet-down){
			padding-top: 56px;
		}
	}
}

.horizon{
	position: relative;
	padding: 1rem 0;
	// padding: 3rem 0;
	&:last-child, &.no-margin{margin: 0}
	//elements
	&__title{
		font-size: 1.5rem;
		margin: 0;
		// margin: 0 0 2rem;
		text-transform: uppercase;
	}
	&__subtitle{
		font-size: 1.25rem;
		line-height: 1.25rem;
		margin: 1rem 0 .5rem;
	}
	&__minititle{
		display: block;
		margin: 0 .5rem .25rem;
	}
	&__featuring{
		background: $color_grey_light;
		overflow: hidden;
	}
	&__excerpt{
		@include get-text-size($base_font_size, $size_6);
	}
	//modifiers
	&--main{
		margin: 0 0 2rem;
	}
	&--fullheight{
		min-height: calc(100vh - 38px - 106px);
		height: 100%;
		padding: 0;
		display: flex;
		align-items: center;
		@include media (tablet-down){
			min-height: calc(100vh - 38px - 56px);
		}
	}
}

.collapse{
   &:not(:last-child){
      margin: 0 0 .25rem;
   }
	&__deployer{
		width: 100%;
		padding: 1rem;
		border: none;
		border-radius: 0;
		background-color: $color_main_regular;
		color: $color_white;
		font-size: $size_12;
		line-height: $size_12;
      position: relative;
      text-align: left;
		&:after{
			@include get-icon($icon-chevron_bold_down);
         position: absolute;
         font-size: 1rem;
         height: 100%;
			top: 0;
         right: 1rem;
         display: flex;
         align-items: center;
         justify-content: center;
         @include rotate-x(0);
      }
      &:focus{outline: none}
      &.active{
         &:after{@include rotate-x(180deg);}
      }
   }
   &__body{
      display: none;
      padding: 1rem 2rem;
      &.active{display: block }
   }
}

.child-list{
	border: 1px solid red;
	margin: 0;
	padding: 0;
	list-style: none;
	&__item{
		position: relative;
		padding-left: 1.5rem;
		margin: 0 0 1rem;
		display: block;
		&:last-child{
			margin: 0;
		}
		&.current{
			color: $color_secondary_regular;
			&:before{
				position: absolute;
				left: 0;
				@include get-icon($icon-play_arrow);
			}
		}
	}
}

.sticky-side{
	margin-top: 1rem;
}

.link{
	color: $color_main_regular;
	text-decoration: none;
	font-weight: $weight_semibold;
	font-size: $size_14	;
	line-height: 1.2;
	position: relative;
	&--more{
		padding-right: 1.75rem;
		&:after{
			// content: "\e315\e315\e315";
			// font-family: $font_ketchup;
			content: ">>>";
			// @include get-icon($icon-angle-double-right);
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.ghost-link{
	position: absolute;
	width: 100%; height: 100%;
	left: 0; top: 0;
	z-index: 2;
}

table{
	td,th{
		padding: .5rem;
	}
	th{
		background: $color_aux_regular;
		font-size: $size_14;
		&:not(.min):not([scope="col"]){
			min-width: 4rem;
		}
	}
	tr{
		&:nth-child(even){
			background: rgba($color_border, .5);
		}
	}
	thead{
		border: 1px solid $color_aux_regular;
	}
	tbody{
		border: 1px solid rgba($color_border, .5);
		border-top: none;
	}
}

.rest-graph {
	max-height: 90px;
	max-width: 180px
}
