.button {
	@include reset-appearance;
	
	display: inline-block;
	vertical-align: middle;
	padding: $size_10 $size_14;
	margin: 0;
	border-radius: $base_border_radius;
	font-weight: bold;
	font-size: map-get($text_sizes, regular);
	color: map-get(map-get($colors_map, main), regular);
	text-align: center;
	line-height: 1.2;
	text-decoration: none;
	cursor: pointer;
	transition: $base_transition;
	border-width: 2px;
	border-style: solid;
	&:hover {
		color: map-get(map-get($colors_map, main), light);
		text-decoration: none;
	}

	// modifiers

	@each $type, $color_map in $colors_map {
		$text_color: $color_white;
		@if $type == grey { $text_color: map-get($color_map, dark); }

		&--#{$type} {
			text-decoration: none;
			color: $text_color;
			background: map-get($color_map, regular);
			border-color: map-get($color_map, regular);

			&:hover { 
				background: map-get($color_map, light);
				border-color: map-get($color_map, light);
				color: $text_color;
			}
			&:active { 
				background: map-get($color_map, light);
			}

			&[class*="--ghost"] {
				background: transparent;
				color: map-get($color_map, regular);
				border: 2px solid map-get($color_map, regular);
				&:hover { 
					color: $color_white;
					background: map-get($color_map, regular);
					border-color: map-get($color_map, regular);
				}
				&:active { 
					color: map-get($color_map, dark);
					border-color: map-get($color_map, dark);
				}
         }
      }
      
	}

	&--ghost-white {
		background: transparent;
		color: $color_white;
		border: 2px solid $color_white;
		&:hover { 
			color: $color_main_regular;
			background: $color_white;
		}
		&:active { 
			color: $color_main_regular;
			background: rgba($color_white, .75);
		}
	}

	&--fixed {
		width: 100%;
		max-width: 15rem;
	}

	&--full-width {
		display: block;
		width: 100%;
	}

	&--wide {
		display: block;
		min-width: 10rem;
	}

	&--extra-wide {
		display: block;
		min-width: 15rem;
	}

	&--small {
		font-weight: normal;
		font-size: map-get($text_sizes, small);
	}
	
	&[disabled], &--disabled, &--disabled[class*="--ghost"] {
		color: map-get(map-get($colors_map, grey), light);
		background: map-get(map-get($colors_map, grey), light);
		border: 1px solid map-get(map-get($colors_map, grey), light);
		pointer-events: none;
	}

	&--loading{
		position:relative;
		pointer-events: none;

		&:before{
			content: "";
			z-index: 100;
			position: absolute;
			left: 0;
			top: 5%;
			width: 100%;
			height: 90%;
		}

		&:after{
			content: "";
			position: absolute;
			left: 0;
			top: 5%;
			width: 100%;
			height: 90%;
			background: $loading_main;
			-webkit-animation-fill-mode: both;
			-o-animation-fill-mode: both;
			animation-fill-mode: both;
			z-index: 100;
		}
	}
	
	&--search{
		&:before{
			@include get-icon($icon-search);
			margin-right: .5rem;
		}
	}
}

.btn-arrow-right,
.btn-arrow-left {
	position: relative;
	padding-left: 18px;
	padding-right: 18px;
	border-radius: 0 !important;
	margin-right: 1px;
}

.btn-arrow-right[disabled],
.btn-arrow-left[disabled] {
	opacity: 1.00;
}

.btn-arrow-right:before,
.btn-arrow-right:after,
.btn-arrow-left:before,
.btn-arrow-left:after {
	content: "";
	position: absolute;
	top: 4px;
	/* move it down because of rounded corners */
	height: 24px;
	/* button_inner_height / sqrt(2) */
	width: 24px;
	/* same as height */
	background: inherit;
	/* use parent background */
	border: inherit;
	/* use parent border */
	border-left-color: transparent;
	/* hide left border */
	border-bottom-color: transparent;
	/* hide bottom border */
	border-radius: 0 !important;
}

.btn-arrow-right:before,
.btn-arrow-left:before {
	left: -13px;
}

.btn-arrow-right:after,
.btn-arrow-left:after {
	right: -13px;
}

.btn-arrow-right.btn-arrow-left,
.btn-arrow-left.btn-arrow-left {
	padding-right: 36px;
}

.btn-arrow-right.btn-arrow-left:before,
.btn-arrow-right.btn-arrow-left:after,
.btn-arrow-left.btn-arrow-left:before,
.btn-arrow-left.btn-arrow-left:after {
	-webkit-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
	/* rotate right arrow squares 45 deg to point right */
}

.btn-arrow-right.btn-arrow-right,
.btn-arrow-left.btn-arrow-right {
	padding-left: 36px;
}

.btn-arrow-right.btn-arrow-right:before,
.btn-arrow-right.btn-arrow-right:after,
.btn-arrow-left.btn-arrow-right:before,
.btn-arrow-left.btn-arrow-right:after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	/* rotate right arrow squares 45 deg to point right */
}

.btn-arrow-right:after,
.btn-arrow-left:before {
	/* bring arrow pointers to front */
	z-index: 3;
}

.btn-arrow-right:before,
.btn-arrow-left:after {
	/* hide arrow tails background */
	background-color: white;
}


/* Large */

.btn-lg.btn-arrow-right,
.btn-lg.btn-arrow-left,
.btn-group-lg>.btn-arrow-left,
.btn-group-lg>.btn-arrow-right {
	padding-left: 22px;
	padding-right: 22px;
	margin-right: 0px;
}

.btn-lg.btn-arrow-right:before,
.btn-lg.btn-arrow-right:after,
.btn-lg.btn-arrow-left:before,
.btn-lg.btn-arrow-left:after,
.btn-group-lg>.btn-arrow-left:before,
.btn-group-lg>.btn-arrow-left:after,
.btn-group-lg>.btn-arrow-right:before,
.btn-group-lg>.btn-arrow-right:after {
	top: 6px;
	/* move it down because of rounded corners */
	height: 32px;
	/* button_inner_height / sqrt(2) */
	width: 32px;
	/* same as height */
}

.btn-lg.btn-arrow-right:before,
.btn-lg.btn-arrow-left:before,
.btn-group-lg>.btn-arrow-left:before,
.btn-group-lg>.btn-arrow-right:before {
	left: -16px;
}

.btn-lg.btn-arrow-right:after,
.btn-lg.btn-arrow-left:after,
.btn-group-lg>.btn-arrow-left:after,
.btn-group-lg>.btn-arrow-right:after {
	right: -16px;
}

.btn-lg.btn-arrow-right.btn-arrow-left,
.btn-lg.btn-arrow-left.btn-arrow-left,
.btn-group-lg>.btn-arrow-left.btn-arrow-left,
.btn-group-lg>.btn-arrow-right.btn-arrow-left {
	padding-right: 44px;
}

.btn-lg.btn-arrow-right.btn-arrow-right,
.btn-lg.btn-arrow-left.btn-arrow-right,
.btn-group-lg>.btn-arrow-left.btn-arrow-right,
.btn-group-lg>.btn-arrow-right.btn-arrow-right {
	padding-left: 44px;
}


/* Small */

.btn-sm.btn-arrow-right,
.btn-sm.btn-arrow-left,
.btn-group-sm>.btn-arrow-left,
.btn-group-sm>.btn-arrow-right {
	padding-left: 14px;
	padding-right: 14px;
	margin-right: -1px;
}

.btn-sm.btn-arrow-right:before,
.btn-sm.btn-arrow-right:after,
.btn-sm.btn-arrow-left:before,
.btn-sm.btn-arrow-left:after,
.btn-group-sm>.btn-arrow-left:before,
.btn-group-sm>.btn-arrow-left:after,
.btn-group-sm>.btn-arrow-right:before,
.btn-group-sm>.btn-arrow-right:after {
	top: 4px;
	/* move it down because of rounded corners */
	height: 20px;
	/* button_inner_height / sqrt(2) */
	width: 20px;
	/* same as height */
}

.btn-sm.btn-arrow-right:before,
.btn-sm.btn-arrow-left:before,
.btn-group-sm>.btn-arrow-left:before,
.btn-group-sm>.btn-arrow-right:before {
	left: -10px;
}

.btn-sm.btn-arrow-right:after,
.btn-sm.btn-arrow-left:after,
.btn-group-sm>.btn-arrow-left:after,
.btn-group-sm>.btn-arrow-right:after {
	right: -10px;
}

.btn-sm.btn-arrow-right.btn-arrow-left,
.btn-sm.btn-arrow-left.btn-arrow-left,
.btn-group-sm>.btn-arrow-left.btn-arrow-left,
.btn-group-sm>.btn-arrow-right.btn-arrow-left {
	padding-right: 28px;
}

.btn-sm.btn-arrow-right.btn-arrow-right,
.btn-sm.btn-arrow-left.btn-arrow-right,
.btn-group-sm>.btn-arrow-left.btn-arrow-right,
.btn-group-sm>.btn-arrow-right.btn-arrow-right {
	padding-left: 28px;
}


/* Extra Small */

.btn-xs.btn-arrow-right,
.btn-xs.btn-arrow-left,
.btn-group-xs>.btn-arrow-left,
.btn-group-xs>.btn-arrow-right {
	padding-left: 10px;
	padding-right: 10px;
	margin-right: -1px;
}

.btn-xs.btn-arrow-right:before,
.btn-xs.btn-arrow-right:after,
.btn-xs.btn-arrow-left:before,
.btn-xs.btn-arrow-left:after,
.btn-group-xs>.btn-arrow-left:before,
.btn-group-xs>.btn-arrow-left:after,
.btn-group-xs>.btn-arrow-right:before,
.btn-group-xs>.btn-arrow-right:after {
	top: 3px;
	/* move it down because of rounded corners */
	height: 14px;
	/* button_inner_height / sqrt(2) */
	width: 14px;
	/* same as height */
}

.btn-xs.btn-arrow-right:before,
.btn-xs.btn-arrow-left:before,
.btn-group-xs>.btn-arrow-left:before,
.btn-group-xs>.btn-arrow-right:before {
	left: -7px;
}

.btn-xs.btn-arrow-right:after,
.btn-xs.btn-arrow-left:after,
.btn-group-xs>.btn-arrow-left:after,
.btn-group-xs>.btn-arrow-right:after {
	right: -7px;
}

.btn-xs.btn-arrow-right.btn-arrow-left,
.btn-xs.btn-arrow-left.btn-arrow-left,
.btn-group-xs>.btn-arrow-left.btn-arrow-left,
.btn-group-xs>.btn-arrow-right.btn-arrow-left {
	padding-right: 20px;
}

.btn-xs.btn-arrow-right.btn-arrow-right,
.btn-xs.btn-arrow-left.btn-arrow-right,
.btn-group-xs>.btn-arrow-left.btn-arrow-right,
.btn-group-xs>.btn-arrow-right.btn-arrow-right {
	padding-left: 20px;
}


/* Button Groups */

.btn-group>.btn-arrow-left:hover,
.btn-group>.btn-arrow-left:focus,
.btn-group>.btn-arrow-right:hover,
.btn-group>.btn-arrow-right:focus {
	z-index: initial;
}

.btn-group>.btn-arrow-right+.btn-arrow-right,
.btn-group>.btn-arrow-left+.btn-arrow-left {
	margin-left: 0px;
}

.btn-group>.btn:not(.btn-arrow-right):not(.btn-arrow-left) {
	z-index: 1;
}