////////////////////////////////////////
//////////////////////////////////////// Paths
////////////////////////////////////////
$fonts_path: '../fonts/';
$images_path: '../img/';
$ikons_path: '../img/ketchup-icons/';

////////////////////////////////////////
//////////////////////////////////////// Base
////////////////////////////////////////


$directions_map: ('top', 'bottom', 'left', 'right');
$cols_map: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
$grid_map: (8,7,6,5,4,3,2,1);
$base_sans_font_family: 'Roboto', Helvetica, Arial, sans-serif !default;
$base_serif_font_family: Palatino, serif !default;

////////
//Font alias
///////
$font_ketchup: "material-ketchup";
$font_sans: $base_sans_font_family;
$font_serif: $base_serif_font_family;

$base_font_size: 16px !default;
$base_line_height: 1.4rem !default;
$base_padding: 1rem !default; // em for it to react to its context
$base_transition: all 300ms ease !default;
$base_box_shadow: 0 0 .5rem rgba(0,0,0,.1) !default;
$base_border_radius: .25rem !default;
$base_color: #33383c !default;
$base_background: #f8f8f8 !default;

////////////////////////////////////////
//////////////////////////////////////// Typography
////////////////////////////////////////

$text_sizes: (
	half: 		.25rem, 			// 5px
	mini: 		.5rem,	 		// 8px
	tiny: 		.625rem,	 		// 10px
	short: 		.75rem, 	 		// 12px
	small: 		.875rem,			// 14px
	regular: 	1rem,				// 16px
	semi: 		1.125rem,	 	// 18px
	medium: 		1.25rem,	 		// 20px
	big: 			1.5rem,			// 24px
	tall: 		1.75rem,			// 28px
	double: 		2rem, 	      // 32px
	huge: 		3rem, 			// 36px
);

//use
//map-get($text_sizes, size);

/////////////
///Shorthands
////////////

//relacion 1 : 16

$size_4:				.25rem;		//4px
$size_6:				.375rem;		//4px
$size_8:				.5rem;		//8px
$size_10:			.625rem;		//10px
$size_11:			.6875rem;	//11px
$size_12:			.75rem;		//12px
$size_13:			.8125rem;	//14px
$size_14:			.875rem;		//14px
$size_16: 			1rem; 		//16px
$size_18: 			1.125rem;   //18px
$size_20: 			1.25rem;   	//20px
$size_21: 			1.3125rem;	//21px
$size_22: 			1.375rem;	//22px
$size_24: 	  		1.5rem;		//24px
$size_26: 			1.675rem;   //26px
$size_28: 			1.75rem; 	//28px
$size_32: 			2rem;			//32px
$size_36: 			2.25rem;		//36px
$size_40: 			2.5rem; 		//40px
$size_48: 			3rem;			//48px
$size_56: 			3.5rem;		//56px
$size_60: 			3.75rem;		//56px
$size_64: 			4rem;			//64px
$size_74: 			4.625rem; 	//74px
$size_120: 			8.75rem; 	//120px
$size_160: 			10rem;		//160px

$headings_line_height: 1.4;
$regular_line_height: $base_line_height;

////////////
////Weights/
///////////
$weight_thin: 100;
$weight_light: 300;
$weight_regular: 400;
$weight_medium: 500;
$weight_semibold: 600;
$weight_bold: 700;
$weight_extrabold: 800;
$weight_black: 900;

////////////////////////////////////////
//////////////////////////////////Colors
////////////////////////////////////////

/*********************************************/
/*********************************************/


$color_black:  		  		#26292c !default;
$color_white: 					white !default;
$color_dark:  		  			#333333 !default;
$color_light: 					#F3F3F3 !default;
$color_border: 				#b7b7b7 !default;
$color_gray:					#5a6268 !default;

$color_base: 					$color_dark !default;

$color_grey_regular:  		$color_gray !default;
$color_grey_light:	  		lighten($color_grey_regular, 10%) !default;
$color_grey_dark:	  			darken($color_grey_regular, 10%) !default;

$color_main_regular: 		#003b30 !default;
$color_main_light: 			lighten($color_main_regular, 10%) !default;
$color_main_dark: 			darken($color_main_regular, 10%) !default;

$color_secondary_regular: 	#f90 !default;
$color_secondary_light: 	lighten($color_secondary_regular, 10%) !default;
$color_secondary_dark: 		darken($color_secondary_regular, 10%) !default;

$color_aux_regular: 			#9cb93f !default;
$color_aux_light: 			lighten($color_aux_regular, 10%) !default;
$color_aux_dark: 				darken($color_aux_regular, 10%) !default;

$color_backup_regular: 		#6A7C7B !default;
$color_backup_light: 		lighten($color_backup_regular, 10%) !default;
$color_backup_dark: 			darken($color_backup_regular, 10%) !default;

$color_success_regular: 	#00af74 !default;
$color_success_light: 		lighten($color_success_regular, 10%) !default;
$color_success_dark: 		darken($color_success_regular, 10%) !default;

$color_info_regular: 		#1dbfba !default;
$color_info_light: 			lighten($color_info_regular, 10%) !default;
$color_info_dark: 			darken($color_info_regular, 10%) !default;

$color_warning_regular: 	#bf9900 !default;
$color_warning_light: 		lighten($color_warning_regular, 10%) !default;
$color_warning_dark: 		darken($color_warning_regular, 10%) !default;

$color_danger_regular: 		#d0021b !default;
$color_danger_light: 		lighten($color_danger_regular, 10%) !default;
$color_danger_dark: 			darken($color_danger_regular, 10%) !default;


$colors_map : (
	grey: (
		dark: 	  	$color_grey_dark,
		regular:  	$color_grey_regular,
		light:    	$color_grey_light
	),
	main: (
		dark: 	  	$color_main_dark,
		regular:  	$color_main_regular,
		light: 		$color_main_light
	),
	secondary: (
		dark: 		$color_secondary_dark,
		regular: 	$color_secondary_regular,
		light: 		$color_secondary_light
	),
	backup: (
		dark: 		$color_backup_dark,
		regular: 	$color_backup_regular,
		light: 		$color_backup_light
	),
	success: (
		dark: 		$color_success_dark,
		regular: 	$color_success_regular,
		light: 		$color_success_light
	),
	info: (
		dark: 		$color_info_dark,
		regular: 	$color_info_regular,
		light: 		$color_info_light
	),
	warning: (
		dark: 		$color_warning_dark,
		regular: 	$color_warning_regular,
		light: 		$color_warning_light
	),
	danger: (
		dark: 		$color_danger_dark,
		regular: 	$color_danger_regular,
		light: 		$color_danger_light
	)
);

// social networks colors
$color_facebook: 	#3b5998;
$color_twitter: 	#55acee;
$color_googleplus: 	#dd4b39;
$color_feed: 	#dd7239;
$color_baseedin: 	#0077b5;
$color_youtube: 	#e52d27;
$color_instagram: 	#405de6;
$color_shortlink:   $color_secondary_regular;
$color_whatsapp: #25D366;

// especial
$instagram_gradient: linear-gradient( #400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);

$social_colors_map: (
	facebook: $color_facebook,
	twitter: $color_twitter,
	googleplus: $color_googleplus,
	linkedin: $color_baseedin,
	youtube: $color_youtube,
	instagram: $color_instagram,
	shortlink: $color_shortlink,
	feed: $color_feed,
	whatsapp: $color_whatsapp
);

////////////////////////////////////////
//////////////////////////////////////// UI
////////////////////////////////////////

$title_shadow: 0 2px 4px rgba($color_dark,.5);

$tr_bg: map-get( map-get($colors_map, grey), light );

$th_bg: map-get( map-get($colors_map, main), dark );
$th_color: $color_white;
$th_font_size: map-get($text_sizes, medium);
$th_align: left;
$th_font_weight: normal;

$td_font_size: map-get($text_sizes, small);
$td_line_height: 1.3;
$td_color: map-get( map-get($colors_map, grey), dark );

$loading_main:url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2235%22%20height%3D%2235%22%20fill%3D%22%23ffffff%22%3E%0A%20%20%3Cpath%20opacity%3D%22.25%22%20d%3D%22M16%200%20A16%2016%200%200%200%2016%2032%20A16%2016%200%200%200%2016%200%20M16%204%20A12%2012%200%200%201%2016%2028%20A12%2012%200%200%201%2016%204%22/%3E%0A%20%20%3Cpath%20d%3D%22M16%200%20A16%2016%200%200%201%2032%2016%20L28%2016%20A12%2012%200%200%200%2016%204z%22%3E%0A%20%20%20%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2016%2016%22%20to%3D%22360%2016%2016%22%20dur%3D%220.8s%22%20repeatCount%3D%22indefinite%22%20/%3E%0A%20%20%3C/path%3E%0A%3C/svg%3E%0A%0A) $color_main_regular center center no-repeat;

//loading article manquecura
$loading_white: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2240%22%20height%3D%2240%22%20fill%3D%22%230F2F54%22%3E%0A%20%20%3Cpath%20opacity%3D%22.25%22%20d%3D%22M16%200%20A16%2016%200%200%200%2016%2032%20A16%2016%200%200%200%2016%200%20M16%204%20A12%2012%200%200%201%2016%2028%20A12%2012%200%200%201%2016%204%22/%3E%0A%20%20%3Cpath%20d%3D%22M16%200%20A16%2016%200%200%201%2032%2016%20L28%2016%20A12%2012%200%200%200%2016%204z%22%3E%0A%20%20%20%20%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2016%2016%22%20to%3D%22360%2016%2016%22%20dur%3D%220.8s%22%20repeatCount%3D%22indefinite%22%20/%3E%0A%20%20%3C/path%3E%0A%3C/svg%3E%0A%0A) $color_white center center no-repeat;