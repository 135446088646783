
  .loading {
    h3,
    h4,
    p {
      color: #e3e3e3 !important;
      background: #e3e3e3 !important;
      border-radius: 5px !important;
    }
  }
  .gr-3.card--big {
    max-width: 25%;
  }
