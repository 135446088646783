$deploy_nav: 'tablet-down';
$deploy_nav_up: 'tablet-up';
////////////////////////////////////////
//////////////////////////////////////// Header general
////////////////////////////////////////

.no-touch, .no-touch:hover{
	@include transitional;
}


//* --------------- *//
//* Header elements *//
//* --------------- *//

.scrolled .header-bar{
	box-shadow: 1px 2px 10px rgba($color_black, .5);
	@include transitional;
}

.header-bar { 
	position: fixed;
	width: 100%;
	z-index: 9;
	background-color: $color_white;
	@include media ($deploy_nav){
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
		box-shadow: 1px 2px 10px rgba($color_dark, .25);
	}
	&.fixed{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9999;
	}
}

.nav-bar {
	background: $color_white;
	border-bottom: 4px solid $color_main_regular;
   padding: 1rem 0;
   @include transitional;
   &__holder {
      display: flex;
   }
   &__brand,
   &__body {
      max-width: 100%;
      display: flex;
      align-items: center;
   }
   &__brand {
      flex: 0 0 auto;
   }
   &__body {
      flex: 1 1 auto;
   }
   &__menu-holder {
      width: 100%;
      display: flex;
		justify-content: flex-end;
   }
	&__mobile{
		&-head{
			display: flex;
		}
	}
	&__tag{
		&__title{
			font-size: 1rem;
			margin: 0 0 .5rem;
		}
		&__body{
			font-size: $size_12;
			line-height: 1.4;
			p{
				margin: 0 0 .5rem;
				&:last-child{
					margin: 0;
				}
			}
		}
	}
   @include media( $deploy_nav) {
      position: relative;
      padding: 0;
      &__brand {
			flex: 0 1 auto;
         order: 2;
			justify-content: center;
			width: 100%;
			width: calc(100% - 7rem);
			margin: 0 3.5rem;
			max-width: 100%;
			height: 100%;
			.app-brand{ max-width: 85% }
      }
      &__menu-holder {
         margin: 0;
         display: block;
         background: $color_white;
         height: 100%;
         overflow-y: auto;
         -webkit-overflow-scrolling: touch;
		}
		&__body {
         background: transparent;
         order: 1;
         position: absolute;
         z-index: 1000;
         top: 100%;
         left: 0;
         transform: translateX(-100%);
			width: 100%;
			z-index: 9;
			display: block;
			@include transitional;
			&.deployed{
				transform: translateX(0);
				@include transitional;
				.nav-bar__menu-holder{
					box-shadow: 1px 2px 10px rgba($color_dark, .5);
				}
			}
      }
      &__menu {
         margin: 0;
			display: block;

			&-holder {
				padding: 1rem;
				height: auto;
				position: fixed;
				overflow-y: scroll;
				width: 100%;
			}
		}
		&__mobile{
			&-head{
				width: 100%;
				height: 100%;
				min-height: 3.5rem;
				z-index: 11;
				background: $color_white;
				align-items: center;
			}
			&-menu, &-aux{
				content: 'x';
				border: none;
				width: 3.5rem;
				height: 3.5rem;
				position: absolute;
				color: $color_white;
				top: 0;
				&:focus{
					outline: none;
				}
				span {
					display: block;
					margin: 0 auto;
					width: 1.75rem;
					height: 2px;
					background: $color_white;
					position: relative;
					@include transitional;
					&::before,
					&::after {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						background: $color_white;
						position: absolute;
						left: 0;
						right: 0;
						top: calc(50% - 1px);
						transform: rotateZ(0);
						@include transitional;
					}
					&::before {
						transform: translateY(-8px) rotateZ(0);
					}
					&::after {
						transform: translateY(8px) rotateZ(0);
					}
				}
				&.deployed {
					background: $color_secondary_regular;
					span {
						background: transparent;
						&::before {
							transform: translateY(0) rotateZ(45deg);
						}
						&::after {
							transform: translateY(0) rotateZ(-45deg);
						}
					}
				}
			}
			&-menu{
				left: 0;
				background: $color_main_regular;
			}
			&-aux{
				right: 0;
				background: $color_secondary_regular;
				display: flex;
				align-items: center;
				justify-content: center;
				&:after{
					@include get-icon($icon-airplay);
					color: $color_white;
					font-size: 1.75rem;
					@include transitional;
				}
				span, span:before, span:after{
					background: transparent;
					transition: 0s;
				}
				span{ top: 1.15rem }
				&.deployed {
					background: $color_main_regular;
					span:before, span:after{
						background: $color_white;
						@include transitional;
					}
					&:after{ color: transparent }
				}
			}
		}
	}
	
	&--left-deploy{
		@include media ($deploy_nav){
			.nav-bar{
				&__body{
					transform: translate(-100%, 3.5rem);
					@include transitional;
					&.deployed{
						transform: (translate(0, 3.5rem));
						@include transitional;
					}
				}
			}
		}
	}

	&--right-deploy{
		@include media ($deploy_nav){
			.nav-bar{
				&__body{
					transform: translate(100%, 3.5rem);
					@include transitional;
					&.deployed{
						transform: translate(0, 3.5rem);
						@include transitional;
					}
				}
			}
		}
	}
}

///////////////
///////////////
///////////////
///////////////

.aux-menu{
	position: absolute;
	top: 100%;
	right: 0;
	display: flex;
	max-width: 600px;
	transform: translateX(calc(100%));
	// visibility: hidden;
	z-index: 6;
	@include transitional;
	@include media (tablet-down){
		max-width: 100%;
		width: 100%;
	}
	&__deployer{
		position: absolute;
		left: -3rem;
		top: 0;
		width: 3rem;
		height: 3rem;
		background: $color_secondary_regular;
		border: none;
		&:before{
			@include get-icon($icon-airplay);
			font-size: 1rem;
			line-height: 1;
			color: $color_white;
		}
		&:focus,&:active{ outline: 0 }
	}
	&__body{
		background: $color_white;
		padding: 1rem;
		width: 100%;
		overflow-y: auto;
	}

	&.active,&.deployed{
		transform: translateX(0);
		visibility: visible;
		@include transitional;
		.aux-menu{
			&__deployer{
				background: $color_main_regular;
				&:before{
					@include get-icon($icon-close);
				}
			}
			&__body{
				box-shadow: 1px 2px 10px rgba($color_dark, .5);
			}
		}
	}
}

.app-brand {
	display: block;
	font-size: map-get($text_sizes, big);

	&:hover {
		text-decoration: none;
	}

	&__logo {
		max-width: 100%;
		max-height: 100%;
	}
	
	// Elements
	&__name, &__logo {
		display: block;
	}

	&__name{
		margin: 0;
		line-height: 1;
		font-size: 1.5rem;
		color: $color_main_regular;
		font-weight: $weight_black;
		text-transform: uppercase;
		@include media (small-down){
			font-size: 1rem;
		}
	}

	// Modifiers
	&--inline {
		.app-brand__name, .app-brand__logo {
			display: inline-block;
		}
	}

	@include media( large-down ){
		font-size: map-get($text_sizes, regular);
	}
}