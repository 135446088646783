
  .menu-fechas {
    &.deployed {
      z-index: 100;
    }
  }
  .menu-comments {
    position: absolute;
    top: 180%;
    &__deployer {
      border-top: 1px solid #ffffff;
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-comment.svg');
      }
    }
    &.deployed {
      max-width: 850px;
      min-width: 850px;
      z-index: 100;
    }
  }

  .menu-informes {
    position: absolute;
    top: 260%;
    &__deployer {
      border-top: 1px solid #ffffff;
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-list.svg');
      }
    }
    &.deployed {
      max-width: 1000px;
      min-width: 850px;
      z-index: 100;
    }
  }

  .menu-pdf {
    position: absolute;
    top: 340%;
    &__deployer {
      border-top: 1px solid #ffffff;
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-pdf.svg');
      }
    }
    &.deployed {
      max-width: 1200px;
      min-width: 1200px;
      z-index: 100;
    }
  }

  .menu-dates {
    &__deployer {
      &:before {
        content: url('../assets/img/ketchup-icons/light/icon-calendar.svg');
      }
    }
  }

  .reports__list {
    overflow-y: scroll;
    max-height: 380px;
  }

  //Editor
  :host ::ng-deep .ck-editor__editable_inline {
    min-height: 500px;
    background: #fafafa;
    padding-left: 1em;
    margin: 1em 0 0 0;
  }

  :host ::ng-deep .ck-read-only {
    background-color: #ffffff;
    padding: 0;
  }

  .d-none {
    display: none;
  }

  // Section MODAL
  .section-modal {
    position: fixed;
    inset: 0;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #2423236b;
  }

  #modal-container {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  .modal-content {
    width: 100%;
    max-width: 1280px;
    max-height: 720px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
