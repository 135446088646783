
.container {
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
}

.separator {
  padding: 0.5rem;
}

small {
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  color: #333333;
  margin-left: 0;
}
