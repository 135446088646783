//*---------------*//
//*.SHOW and .HIDE*//
//*---------------*//
.show {
	display: block;
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@desktop;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}

@each $media_name, $media_size in $medias_map {
	.hide\@#{$media_name} {
		@include media (#{$media_name}-down) { display: none !important }
	}
}

.show\@large {
	display: none;
	@include media (large-down) { display: block }
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@large;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}

.show\@book {
	display: none;
	@include media (book-down) { display: block }
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@book;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}

.show\@medium {
	display: none;
	@include media (medium-down) { display: block }
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@medium;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}

.show\@tablet {
	display: none;
	@include media (tablet-down) { display: block }
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@tablet;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}

.show\@small {
	display: none;
	@include media (small-down) { display: block }
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@small;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}

.show\@phablet {
	display: none;
	@include media (phablet-down) { display: block }
	@each $media_name, $media_size in $medias_map {
		&.hide\@#{$media_name} {
			@extend .show\@phablet;
			@include media (#{$media_name}-down) { display: none }
		}
	}
}



//*-----------------------------*//
//*Rewrite gridle's GUTTER class*//
//*-----------------------------*//
.gutter {
	padding: .5rem;
	&-vertical {
		padding-top: .5rem;
		padding-bottom: .5rem;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: .5rem;
					padding-bottom: .5rem;
				}
				&-up {
					@include media (#{$media_name}-up) {
						padding-top: .5rem;
						padding-bottom: .5rem;
					}
				}
			}
		}
	}
	&-horizontal {
		padding-left: .5rem;
		padding-right: .5rem;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-left: .5rem;
					padding-right: .5rem;
				}
				&-up {
					@include media (#{$media_name}-up) {
						padding-left: .5rem;
						padding-right: .5rem;
					}
				}
			}
		}
	}
	&-top {
		padding-top: .5rem;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: .5rem;
				}
				&-up {
					@include media (#{$media_name}-up) {
						padding-top: .5rem;
					}
				}
			}
		}
	}
	&-bottom {
		padding-bottom: .5rem;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: .5rem;
				}
				&-up {
					@include media (#{$media_name}-up) {
						padding-bottom: .5rem;
					}
				}
			}
		}
	}
	&-left {
		padding-left: .5rem;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-left: .5rem;
				}
				&-up {
					@include media (#{$media_name}-up) {
						padding-left: .5rem;
					}
				}
			}
		}
	}
	&-right {
		padding-right: .5rem;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-right: .5rem;
				}
				&-up {
					@include media (#{$media_name}-up) {
						padding-right: .5rem;
					}
				}
			}
		}
	}
	@each $media_name, $media_size in $medias_map {
		&\@#{$media_name} {
			@include media (#{$media_name}-down) {
				padding: .5rem;
			}

			&-up {
				@include media (#{$media_name}-up) {
					padding: .5rem;
				}
			}
		}
	}
}


//*------------*//
//*Border class*//
//*------------*//
.br {
	&-right {
		border-right: 1px solid $color_border;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-right: 1px solid $color_border }
			}
		}
	}

	&-left {
		border-left: 1px solid $color_border;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-left: 1px solid $color_border }
			}
		}
	}

	&-top {
		border-top: 1px solid $color_border;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-top: 1px solid $color_border }
			}
		}
	}

	&-bottom {
		border-bottom: 1px solid $color_border;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-bottom: 1px solid $color_border }
			}
		}
	}
}

//*---------------*//
//*No border class*//
//*---------------*//
.no-br {
	&-right {
		border-right: none;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-right: none }
			}
		}
	}

	&-left {
		border-left: none;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-left: none }
			}
		}
	}

	&-top {
		border-top: none;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-top: none }
			}
		}
	}

	&-bottom {
		border-bottom: none;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { border-bottom: none }
			}
		}
	}
}


//*-----------------------------------*//
//*Border created using :after element*//
//*-----------------------------------*//
.brc {
	&-right {
		@include br-right;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include br-right }
			}
		}
	}

	&-left {
		@include br-left;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include br-left }
			}
		}
	}

	&-top {
		@include br-top;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include br-top }
			}
		}
	}

	&-bottom {
		@include br-bottom;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include br-bottom }
			}
		}
	}
}

//*--------------------------------*//
//*No :after element Border element*//
//*--------------------------------*//
.no-brc {
	&-right {
		@include no-br;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include no-br }
			}
		}
	}

	&-left {
		@include no-br;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include no-br }
			}
		}
	}

	&-top {
		@include no-br;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include no-br }
			}
		}
	}

	&-bottom {
		@include no-br;
		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) { @include no-br }
			}
		}
	}
}

//*----------------*//
//*Big paddings top*//
//*----------------*//
.hat {
	padding-top: 3rem;

	@each $media_name,
	$media_size in $medias_map {
		&\@#{$media_name} {
			@include media (#{$media_name}-down) {
				padding-top: 3rem;
			}
		}
	}

	&-tall {
		padding-top: 5rem;
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 5rem;
				}
			}
		}
	}

	&-big {
		padding-top: 4rem;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 4rem;
				}
			}
		}
	}

	&-small {
		padding-top: 2rem;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 2rem;
				}
			}
		}
	}
	
	&-tiny {
		padding-top: 1rem;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 1rem;
				}
			}
		}
	}

	&-not {
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 0;
				}
			}
		}
	}
}

//*----------------*//
//*Big paddings bottom*//
//*----------------*//
.heels {
	padding-bottom: 3rem;

	@each $media_name,
	$media_size in $medias_map {
		&\@#{$media_name} {
			@include media (#{$media_name}-down) {
				padding-bottom: 3rem;
			}
		}
	}

	&-tall {
		padding-bottom: 5rem;
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: 5rem;
				}
			}
		}
	}

	&-big {
		padding-bottom: 4rem;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: 4rem;
				}
			}
		}
	}

	&-small {
		padding-bottom: 2rem;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: 2rem;
				}
			}
		}
	}
	
	&-tiny {
		padding-bottom: 1rem;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: 1rem;
				}
			}
		}
	}

	&-not {
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: 0;
				}
			}
		}
	}
}

//*---------------------------*//
//*Big paddings left and right*//
//*---------------------------*//
.mv{
	&-right{
		padding-right: 1rem;

		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-right: 1rem;
				}
			}
		}

		&-double {
			padding-right: 2rem;
			@each $media_name,
			$media_size in $medias_map {
				&\@#{$media_name} {
					@include media (#{$media_name}-down) {
						padding-right: 2rem;
					}
				}
			}
		}

		&-triple {
			padding-right: 3rem;
			@each $media_name,
			$media_size in $medias_map {
				&\@#{$media_name} {
					@include media (#{$media_name}-down) {
						padding-right: 3rem;
					}
				}
			}
		}

		&-not {
			@each $media_name, $media_size in $medias_map {
				&\@#{$media_name} {
					@include media (#{$media_name}-down) {
						padding-right: 0;
					}
				}
			}
		}
	}
	&-left{
		padding-left: 1rem;

		@each $media_name, $media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-left: 1rem;
				}
			}
		}

		&-double {
			padding-left: 2rem;
			@each $media_name,
			$media_size in $medias_map {
				&\@#{$media_name} {
					@include media (#{$media_name}-down) {
						padding-left: 2rem;
					}
				}
			}
		}

		&-triple {
			padding-left: 3rem;
			@each $media_name,
			$media_size in $medias_map {
				&\@#{$media_name} {
					@include media (#{$media_name}-down) {
						padding-left: 3rem;
					}
				}
			}
		}

		&-not {
			@each $media_name, $media_size in $medias_map {
				&\@#{$media_name} {
					@include media (#{$media_name}-down) {
						padding-left: 0;
					}
				}
			}
		}
	}
}


//*---------------*//
//*No gutter class*//
//*override all of above*//
//*---------------------*//
.no-gutter {
	padding: 0;

	&-vertical {
		padding-top: 0;
		padding-bottom: 0;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 0;
					padding-bottom: 0;
				}

				&-up {
					@include media (#{$media_name}-up) {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}
	}

	&-horizontal {
		padding-left: 0;
		padding-right: 0;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-left: 0;
					padding-right: 0;
				}

				&-up {
					@include media (#{$media_name}-up) {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}

	&-top {
		padding-top: 0;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-top: 0;
				}

				&-up {
					@include media (#{$media_name}-up) {
						padding-top: 0;
					}
				}
			}
		}
	}

	&-bottom {
		padding-bottom: 0;

		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					padding-bottom: 0;
				}

				&-up {
					@include media (#{$media_name}-up) {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	@each $media_name,
	$media_size in $medias_map {
		&\@#{$media_name} {
			@include media (#{$media_name}-down) {
				padding: 0;
			}

			&-up {
				@include media (#{$media_name}-up) {
					padding: 0;
				}
			}
		}
	}
}

.icon{
	&-main{
		color: $color_main_regular;
	}
	&-secondary{
		color: $color_secondary_regular;
	}
	&-base{
		color: $color_base;
	}
	&-white{
		color: $color_white;
	}
	&-rounder{
		border-radius: 50%;
	}
}

.title {
	font-weight: $weight_extrabold;
	text-transform: uppercase;
	line-height: 1.2;
	&-main {
		@extend .title;
		font-size: $size_48;
		@include media (tablet-down){
			font-size: $size_36;
		}
	}

	&-huge {
		@extend .title;
		font-size: $size_36;
		@include media (tablet-down){
			&:not(.single__title){
				font-size: $size_32;
			}
		}
	}

	&-big {
		@extend .title;
		font-size: $size_32;
		@include media (tablet-down){
			font-size: $size_28;
			&:not(.single__title){
				font-size: $size_28;
			}
		}
	}

	&-medium {
		@extend .title;
		font-size: $size_28;
		@include media (tablet-down){
			font-size: $size_24;
			&:not(.single__title){
				font-size: $size_24;
			}
		}
	}

	&-small {
		@extend .title;
		font-size: $size_24;
		@include media (tablet-down){
			font-size: $size_20;
		}
	}

	&-tiny {
		@extend .title;
		font-size: $size_20;
		@include media (tablet-down){
			font-size: $size_18;
		}
	}
}

.text{
	font-size: $size_16;
	line-height: 1.8;

	&-big {
		line-height: 1.8;
		font-size: $size_24;
	}

	&-medium {
		line-height: 1.8;
		font-size: $size_18;
	}

	&-small {
		line-height: 1.8;
		font-size: $size_14;
	}

	&-tiny {
		line-height: 1.8;
		font-size: $size_12;
	}

	&-min {
		line-height: 1.8;
		font-size: $size_10;
	}
}

.w-thin{ font-weight: 100 }
.w-light{ font-weight: 300 }
.w-regular{ font-weight: 400 }
.w-medium{ font-weight: 500 }
.w-semibold{ font-weight: 600 }
.w-bold{ font-weight: 700 }
.w-extrabold{ font-weight: 800 }
.w-black{ font-weight: 900 }

.flex {
	display: flex;

	&-col {
		@extend .flex;
		flex-direction: column;

		&-reverse {
			@extend .flex-col;
			flex-direction: column-reverse;
		}

		&-middle {
			@extend .flex-col;
			@extend .flex-middle;
		}
	}

	&-row {
		@extend .flex;
		flex-direction: row;

		&-reverse {
			@extend .flex-row;
			flex-direction: row-reverse;
		}
	}

	&-top {
		@extend .flex;
		align-items: flex-start;
	}

	&-bottom {
		@extend .flex;
		align-items: flex-end;
	}

	&-left {
		@extend .flex;
		justify-content: flex-start;
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					display: flex;
					align-items: flex-start;
				}
			}
		}
	}

	&-right {
		@extend .flex;
		justify-content: flex-end;
	}

	&-space-arnd {
		@extend .flex;
		justify-content: space-around;
	}

	&-space-btwn {
		@extend .flex;
		justify-content: space-between;
	}


	&-center {
		@extend .flex;
		justify-content: center;
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					display: flex;
					justify-content: center;
				}
			}
		}
	}

	&-middle {
		@extend .flex;
		align-items: center;
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					display: flex;
					align-items: center;
				}
			}
		}
	}
	&-left {
		@each $media_name,
		$media_size in $medias_map {
			&\@#{$media_name} {
				@include media (#{$media_name}-down) {
					display: flex;
					align-items: flex-start;
				}
			}
		}
	}
}
