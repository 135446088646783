
:root {
  --icon-size-small: 22px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.horizon__title {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
}

.spacer {
  flex-grow: 1;
}

a:hover {
  text-decoration: none;
}
