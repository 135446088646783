
  .loading {
    h3,
    h4,
    p,
    figure {
      color: #e3e3e3 !important;
      background: #e3e3e3 !important;
      border-radius: 5px !important;
    }
    figure {
      display: block;
      height: 90px;
    }
    .card__graph {
      overflow: hidden;
    }
  }
