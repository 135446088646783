
button {
  background-color: transparent;
  border: none;
  padding: 0;
}

section {
  background: #fea82b;
}

nav {
  margin-bottom: 1rem;
}

nav.menu {
  padding-top: 1.75rem;

  .exul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;

    .nav-item {
      display: block;
      margin-top: 2rem;
      padding: 1rem 2rem;

      &--orange {
        background: #ff7f00;

        &.active {
          background: #fff;

          a {
            font-weight: bold;
            color: black;
          }
        }
      }

      &--yellow {
        background: #fa0;

        &.active {
          background: #fff;

          a {
            font-weight: bold;
            color: black;
          }
        }

        &:hover {
          background: #df8d0a;
        }
      }

      & a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

.formDesplegable {
  position: relative;
  justify-self: end;

  &+& {
    margin-left: 0.2rem;
  }
}

.menu-parcial {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgb(216, 216, 216) !important;
  margin-top: 0px;

  .nav-item {
    background: transparent !important;

    a {
      color: black !important;
      font-weight: bold !important;
    }

    &:hover {
      background: rgb(179, 179, 179) !important;
      color: white !important;
    }
  }

  &__title {
    color: black;
    padding: 0.5rem;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;

    a {
      color: black !important;
      font-weight: bold !important;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
