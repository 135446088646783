// |------------------------------------------------------
// |------------------------------------------------------
// | Settings mixins
// |------------------------------------------------------
// |------------------------------------------------------

//
// Setup
//
@mixin gridle_setup(
	$settings : ()
) {
	// prepare state
	$settings : _gridle_prepare_state_settings($settings);

	// extend default settings
	$_gridle-settings : map-merge((
		name : default,
		min-width : null,
		max-width : null,
		query : null,
		classes : true,
		context : 12,
		column-width : null,
		gutter-width : 20px,
		gutter-height : 0,
		gutter-top : 0,
		gutter-right : 10px,
		gutter-bottom : 0,
		gutter-left : 10px,
		direction : ltr,
		dir-attribute : false,
		name-multiplicator : 1,
		states-classes : false,
		classes-prefix : null
	), $settings) !global;

	// register default state :
	@include gridle_register_state(default, $_gridle-settings);

	// set current state to default
	$_gridle_current_state : $_gridle-settings !global;
	$_gridle_current_stateName : default !global;

}

//
// Register a state
//
@mixin gridle_register_state(
	$name,
	$settings : ()
) {
	// prepare state
	$settings : _gridle_prepare_state_settings($settings);

	// settings :
	$settings : map-merge($_gridle-settings, $settings);

	// set name :
	$settings : map-set($settings, name, $name);

	// gutter sizes :
	$gutter-top : map-get($settings, gutter-top);
	$gutter-right : map-get($settings, gutter-right);
	$gutter-bottom : map-get($settings, gutter-bottom);
	$gutter-left : map-get($settings, gutter-left);

	// add state in maps :
	$_gridle_states : map-set($_gridle_states, $name, $settings) !global;

	// add rules if gutter is different
	@if $gutter-top != map-get($_gridle-settings, gutter-top)
		or $gutter-right != map-get($_gridle-settings, gutter-right)
		or $gutter-bottom != map-get($_gridle-settings, gutter-bottom)
		or $gutter-left != map-get($_gridle-settings, gutter-left) {
		$gutters : _gridle_forge_gutters_map(top right bottom left, $name);
		@include gridle_apply_css_for(grid grid-grow grid-adapt, (
			gridle_gutter : top right bottom left
		), $name);
	}

	// add rules if direction is different
	$direction : map-get($settings, direction);
	@if $direction != map-get($_gridle-settings, direction) {
		$float : left;
		@if $direction == rtl {
			$float : right;
		}
		@include gridle_apply_css_for(grid, (
			direction : $direction,
			float : $float
		), $name);
	}
}


//
//  Register a clear each class
//
@mixin gridle_register_clear_each(
	$count,
	$clearWhat
) {
	// create the clear map :
	$classMap : (
		clearEach : $count,
		clearWhat : $clearWhat
	);

	// append to map :
	$_gridle_clear_classes : map-set($_gridle_clear_classes, $count, $classMap) !global;
}


//
// Register a special class
//
@mixin gridle_register_column(
	$name,
	$columns,
	$context
) {
	// create a column :
	$col : _gridle_create_column($name, $columns, $context);

	// add column in maps :
	$_gridle_columns : map-set($_gridle_columns, $name, $col) !global;
}


//
// Set a classmap
//
@mixin gridle_set_classname_map(
	$for,
	$pattern
) {
	// get the map
	$map : map-get($_gridle-packages, $for);
	// set the new classname
	$map : map-set($map, classname, $pattern);
	// set the classmap
	$_gridle-packages : map-set($_gridle-packages, $for, $map) !global;
}


//
// Set a generic selector
//
@mixin gridle_set_generic_selector(
	$for,
	$selector
) {
	// get the map
	$map : map-get($_gridle-packages, $for);
	// set the new classname
	$map : map-set($map, generic-selector, $selector);
	// set the classmap
	$_gridle-packages : map-set($_gridle-packages, $for, $map) !global;
}


//
// Extend a gridle class
//
// map format :
// (
// 	grid : (
// 		mobile : (
// 			padding : 10px,
// 			float : left
// 		),
// 		tablet : (
// 			padding : 10px
// 		)
// 	),
// 	flex-grid : (
// 		mobile : (
// 			padding : 10px
// 		)
// 	)
// )
//
// @mixin gridle_extend_base_class(
// 	$for,
// 	$extend,
// 	$statesNames : null
// ) {
// 	// if $states is all
// 	@if $statesNames == null {
// 		$statesNames : gridle_get_states_names();
// 	}

// 	// allow multiple "$for"
// 	@each $f in $for {

// 		// get the map for the passed mixin "category"
// 		$forMap : map-get($_gridle_extend_base_classes, $f);

// 		// create the map if not exist
// 		@if $forMap == null { $forMap : (); }

// 		// loop on each states to register an extend map
// 		@each $stateName in $statesNames {

// 			// check if has a map for the state
// 			$forStateMap : map-get($forMap, $stateName);
// 			@if $forStateMap == null {
// 				$forStateMap : ();
// 			}

// 			// merge map
// 			$forStateMap : map-merge($forStateMap, $extend);

// 			// set the new state map into forMap
// 			$forMap : map-set($forMap, $stateName, $forStateMap);

// 			// set the map in the gridle global variable
// 			$_gridle_extend_base_classes : map-set($_gridle_extend_base_classes, $f, $forMap) !global;

// 		}

// 	}
// }
@mixin gridle_extend_base_class(
	$for,
	$extend,
	$statesNames : null
) {
	// if $states is all
	@if $statesNames == null {
		$statesNames : gridle_get_states_names();
	}

	// allow multiple "$for"
	@each $stateName in $statesNames {

		// get the map for the passed mixin "category"
		$stateMap : map-get($_gridle_extend_base_classes, $stateName);

		// create the map if not exist
		@if $stateMap == null { $stateMap : (); }

		// loop on each states to register an extend map
		@each $f in $for {

			// check if has a map for the state
			$forStateMap : map-get($stateMap, $f);
			@if $forStateMap == null {
				$forStateMap : ();
			}

			// merge map
			$forStateMap : map-merge($forStateMap, $extend);

			// set the new state map into forMap
			$stateMap : map-set($stateMap, $f, $forStateMap);

			// set the map in the gridle global variable
			$_gridle_extend_base_classes : map-set($_gridle_extend_base_classes, $stateName, $stateMap) !global;
		}
	}
}

//
// Apply css for
//
// map format :
// (
// 	(
// 		grid : (
// 			mobile : (
// 				padding : 0,
// 				margin : 0
// 			),
// 			tablet : (
// 				padding : 0
// 			)
// 		)
// 	),
// 	(
// 		etc...
// 	)
// )
// @mixin gridle_apply_css_for(
// 	$for,
// 	$extend,
// 	$statesNames : null
// ) {

// 	// if statesNames is null, mean that it's all the states
// 	@if $statesNames == null {
// 		$statesNames : gridle_get_states_names();
// 	}

// 	// allow multiple "$for"
// 	@each $f in $for {

// 		// get the map for the passed mixin "category"
// 		$stateMap : ();

// 		// loop on each states to register an extend map
// 		@each $stateName in $statesNames {
// 			// add the css
// 			$stateMap : map-set($stateMap, $stateName, $extend);
// 		}

// 		// set in map
// 		$forMap : map-set((), $f, $stateMap);

// 		// set the map in the gridle global variable
// 		$_gridle_apply_css_for : append($_gridle_apply_css_for, $forMap) !global;

// 	}
// }
@mixin gridle_apply_css_for(
	$for,
	$extend,
	$statesNames : null
) {

	// if statesNames is null, mean that it's all the states
	@if $statesNames == null {
		$statesNames : gridle_get_states_names();
	}

	// allow multiple "$for"
	@each $stateName in $statesNames {

		// get the map for the passed mixin "category"
		$forMap : ();

		// loop on each states to register an extend map
		@each $f in $for {
			// add the css
			$forMap : map-set($forMap, $f, $extend);
			// $stateMap : map-set($stateMap, $stateName, $extend);
		}

		// set in map
		$statesMap : map-set((), $stateName, $forMap);

		// set the map in the gridle global variable
		$_gridle_apply_css_for : append($_gridle_apply_css_for, $statesMap) !global;
	}
}


//
// Register default states
//
@mixin gridle_register_default_states() {
	@include gridle_register_state(mobile, (
		max-width : 480px
	));
	@include gridle_register_state(tablet, (
		min-width : 481px,
		max-width : 1024px
	));
}


//
// Register default mobile first states :
//
@mixin gridle_register_default_mobile_first_states() {
	@include gridle_register_state(xs, (
		max-width : 750px
	));
	@include gridle_register_state(sm, (
		min-width : 750px
	));
	@include gridle_register_state(md, (
		min-width : 970px
	));
	@include gridle_register_state(lg, (
		min-width : 1170px
	));
}