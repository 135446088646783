@media print {
   body a[href]:after {
   	content: none !important;
   }
}

pre {
   white-space: pre-wrap;
   /* css-3 */
   white-space: -moz-pre-wrap;
   /* Mozilla, since 1999 */
   white-space: -pre-wrap;
   /* Opera 4-6 */
   white-space: -o-pre-wrap;
   /* Opera 7 */
   word-wrap: break-word;
   /* Internet Explorer 5.5+ */
   background: dimgray;
   color: white;
   padding: 1em;
   font-size: 14px;
}