////////////////////////////////////////
//////////////////////////////////////// Funciones
////////////////////////////////////////
@function strip-unit($number) {
   @if type-of($number)=='number'and not unitless($number) {
      @return $number / ($number * 0 + 1);
   }

   @return $number;
}

@mixin get-text-size($font_val, $font_line : 4px) {
   font-size: $font_val;
   line-height: calc(#{$font_val} + #{$font_line});
}

@function get-color($type, $shade) {
   @return map-get(map-get($colors_map, $type), $shade);
}

@mixin get-icon($icon) {
   content: $icon;
   font-family: $font_ketchup;
   @include icon-font-family;
}

////////////////////////////////////////
//////////////////////////////////////// Utilitarios
////////////////////////////////////////
@mixin order-list {
   list-style: none;
   padding: 0;

   li {
      counter-increment: counterattack;
      position: relative;
      padding-left: 2rem;

      &:before {
         content: counter(counterattack);
         margin-right: 1rem;
         color: $color_main_regular;
         font-weight: $weight_bold;
         position: absolute;
         left: .5rem;
      }

      &:first-child {
         counter-reset: counterattack;
      }
   }

   @for $i from 1 through 100 {
      &[start="#{$i}"] {
         li {
            counter-increment: counterattack #{$i};
         }
      }
   }
}

@mixin unordered-list {
   list-style: none;
   padding: 0;

   li {
      position: relative;
      padding-left: 2rem;

      &:before {
         @include get-icon($icon-circle);
         color: $color_main_regular;
			font-size: 6px;
			top: 7px;
         font-weight: $weight_bold;
         position: absolute;
         left: .5rem;
      }
   }
}

@mixin center-middle {
   top: 50%;
   transform: translateY(-50%);
}

@mixin image-replacement {
   font-size: 0;
   line-height: 0;
   color: transparent;
   font-family: a;
}

@mixin reset-list{
	list-style-type: none;
	padding: 0;
	margin: 0;
	li{
		margin: 0; padding: 0;
	}
}

@mixin reset-appearance {
   -webkit-appearance: none;
   -ms-appearance: none;
   appearance: none;
   background: transparent;
   border-radius: 0;
   line-height: 1;
   border: 0;
   outline: 0;
   padding: 0;
}

@mixin reset-button {
   background: transparent;
   border-radius: 0;
   line-height: 1;
   border: 0;
   outline: 0;
   padding: 0;
   color: $color_base;
}

@mixin remove-select-arrow {
   text-indent: 0.01px;
   text-overflow: '';

   &::-ms-expand {
      display: none;
   }
}

@mixin active-state {
   &.no-touch:hover { @content; }
   &.touch:focus { @content; }
}

@mixin placeholder {
   &::-webkit-input-placeholder {
      @content;
   }

   &::-moz-placeholder {
      @content;
   }

   &::-ms-input-placeholder {
      @content;
   }
}

@mixin range-track {
   &::-webkit-slider-runnable-track {
      @content;
   }

   &::-moz-range-track {
      @content;
   }

   &::-ms-track {
      @content;
   }
}

@mixin range-thumb {
   &::-webkit-slider-thumb {
      @content;
   }

   &::-moz-range-thumb {
      @content;
   }

   &::-ms-thumb {
      @content;
   }
}

@mixin overlay{
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

@mixin transitional {
	-webkit-transition: $base_transition;
   -ms-transition: $base_transition;
   -moz-transition: $base_transition;
   transition: $base_transition;
}

@mixin relax {
   @include ease-transition(.75s);
}

@mixin divider-btm($color) {
   border-bottom: 1px solid $color;
}

@mixin ease-transition($secs) {
   -webkit-transition: ease $secs;
   -ms-transition: ease $secs;
   -moz-transition: ease $secs;
   transition: ease $secs;
}

@mixin rotate-y($howmuch) {
   -webkit-transform: rotateY($howmuch);
   -ms-transform: rotateY($howmuch);
   -moz-transform: rotateY($howmuch);
   transform: rotateY($howmuch);
}

@mixin rotate-x($howmuch) {
   -webkit-transform: rotateX($howmuch);
   -ms-transform: rotateX($howmuch);
   -moz-transform: rotateX($howmuch);
   transform: rotateX($howmuch);
   @include transitional;
}

@mixin scale {
   -webkit-transform: scale(1.025);
   -ms-transform: scale(1.025);
   -moz-transform: scale(1.025);
   transform: scale(1.025);
}

@mixin scaleme($x) {
   -webkit-transform: scale($x);
   -ms-transform: scale($x);
   -moz-transform: scale($x);
   transform: scale($x);
}

@mixin filter($action) {
   -webkit-filter: $action;
   -ms-filter: $action;
   -moz-filter: $action;
   filter: $action;
}

@mixin get-svg($route) {
   background: url($images_path + $route) 50% 50% no-repeat;
   background-size: contain;
}

@mixin shadow {
   -moz-box-shadow: 0 2px 10px 0 rgba($color_dark, 0.15);
   -webkit-box-shadow: 0 2px 10px 0 rgba($color_dark, 0.15);
   box-shadow: 0 2px 10px 0 rgba($color_dark, 0.15);
}

@mixin border-shadow($color: $color_grey_light) {
   content: "";
   position: absolute;
   width: 98%;
   right: 1%;
   bottom: 0;
   height: 20px; // z-index:-1;
   -moz-box-shadow: inset 1px -10px 2px -10px $color;
   -webkit-box-shadow: inset 1px -10px 2px -10px $color;
   box-shadow: inset 1px -10px 2px -10px $color;
}
@mixin flex-column {
   display: flex;
   flex-direction: column;
}

@mixin flex-row {
   display: flex;
   flex-direction: row;
}

@mixin flex-fill {
   @include flex-column;
   align-content: stretch;
}

@mixin flex-col-center-start {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
}

@mixin flex-col-center-all {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

@mixin flex-col-start-end {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-end;
}

@mixin placeholder-color($color) {

   &::placeholder,
   &::-webkit-input-placeholder,
   &::-moz-placeholder,
   &:-ms-input-placeholder,
   &:-moz-placeholder {
      color: $color;
   }
}

////////////////////////////////////
////////////////////////////////////
///////////////////////////////////
$black_05: rgba($color_black, .05);
$black_10: rgba($color_black, .1);
$black_15: rgba($color_black, .15);
$black_25: rgba($color_black, .25);
$black_50: rgba($color_black, .5);
$black_75: rgba($color_black, .75);
$black_90: rgba($color_black, .9);

@mixin black-gradient {
   background: $black_50;
   /* For browsers that do not support gradients */
   background: -webkit-linear-gradient($black_10, $black_10, $black_15, $black_15, $black_25, $black_50, $black_50, $black_75, $black_90);
   /* For Safari 5.1 to 6.0 */
   background: -o-linear-gradient($black_10, $black_10, $black_15, $black_15, $black_25, $black_50, $black_50, $black_75, $black_90);
   /* For Opera 11.1 to 12.0 */
   background: -moz-linear-gradient($black_10, $black_10, $black_15, $black_15, $black_25, $black_50, $black_50, $black_75, $black_90);
   /* For Firefox 3.6 to 15 */
   background: linear-gradient($black_10, $black_10, $black_15, $black_15, $black_25, $black_50, $black_50, $black_75, $black_90);
}

@mixin height-animate {
   -moz-animation: heightness .5s ease .5s forwards;
   -webkit-animation: heightness .5s ease .5s forwards;
   -o-animation: heightness .5s ease .5s forwards;
   -ms-animation: heightness .5s ease .5s forwards;
   animation: heightness .5s ease .5s forwards;
}

@-moz-keyframes heightness

/* Firefox */
   {
   from {
      height: 0%;
   }

   to {
      height: 100%;
   }
}

@-webkit-keyframes heightness {
   from {
      height: 0%;
   }

   to {
      height: 100%;
   }
}

@-o-keyframes heightness {
   from {
      height: 0%;
   }

   to {
      height: 100%;
   }
}

@-ms-keyframes heightness {
   from {
      height: 0%;
   }

   to {
      height: 100%;
   }
}

@keyframes heightness {
   from {
      height: 0%;
   }

   to {
      height: 100%;
   }
}

@mixin video-animate {
   -moz-animation: slide .5s ease .5s forwards;
   -webkit-animation: slide .5s ease .5s forwards;
   -o-animation: slide .5s ease .5s forwards;
   -ms-animation: slide .5s ease .5s forwards;
   animation: slide .5s ease .5s forwards;
}

@-moz-keyframes slide

/* Firefox */
   {
   from {
      height: 100%;
   }

   to {
      height: -100%;
   }
}

@-webkit-keyframes slide {
   from {
      height: 100%;
   }

   to {
      height: -100%;
   }
}

@-o-keyframes slide {
   from {
      height: 100%;
   }

   to {
      height: -100%;
   }
}

@-ms-keyframes slide {
   from {
      height: 100%;
   }

   to {
      height: -100%;
   }
}

@keyframes slide {
   from {
      height: 100%;
   }

   to {
      height: -100%;
   }
}

@mixin video-animateauto {
   -moz-animation: slideauto .5s ease .5s forwards;
   -webkit-animation: slideauto .5s ease .5s forwards;
   -o-animation: slideauto .5s ease .5s forwards;
   -ms-animation: slideauto .5s ease .5s forwards;
   animation: slideauto .5s ease .5s forwards;
}

@-moz-keyframes slideauto

/* Firefox */
   {
   from {
      height: auto;
   }

   to {
      height: -100%;
   }
}

@-webkit-keyframes slideauto {
   from {
      height: auto;
   }

   to {
      height: -100%;
   }
}

@-o-keyframes slideauto {
   from {
      height: auto;
   }

   to {
      height: -100%;
   }
}

@-ms-keyframes slideauto {
   from {
      height: auto;
   }

   to {
      height: -100%;
   }
}

@keyframes slideauto {
   from {
      height: auto;
   }

   to {
      height: -100%;
   }
}

@mixin br-start{
	border: none;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		background: rgba($color_white, .5);
	}
}

@mixin no-br{
	border: none;
	&:after{ content: none }
}

@mixin br-right{
	@include br-start;
	&:after{
		height: calc(100% - 1rem);
		width: 1px;
		right: 0;
		top: .5rem;
	}
}

@mixin br-left{
	@include br-start;
	&:after{
		height: calc(100% - 1rem);
		width: 1px;
		left: 0;
		top: .5rem;
	}
}

@mixin br-top{
	@include br-start;
	&:after{
		width: calc(100% - 1rem);
		height: 1px;
		right: .5rem;
		top: 0;
	}
}

@mixin br-bottom{
	@include br-start;
	&:after{
		width: calc(100% - 1rem);
		height: 1px;
		right: .5rem;
		top: auto;
		bottom: 0;
	}
}