// /* You can add global styles to this file, and also import other style files */
//
html, body {
  background-color: #FFFFFF;
}

textarea, select, input, button {
  outline: none;
}

td {
  text-align: right;
}
th {
  text-align: center;
}
td.left, th.left {
  text-align: left;
}
td.center, th.center {
  text-align: center;
}
td.intercaled {
  background-color: rgba(121, 121, 121, .25);
}
th.intercaled {
  background-color: #4e5c1f;
}
.row.row-table {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.card--full {
  width: 100%;
  padding: .5rem;
  & .card__graph {
    padding: 1rem;
  }
}

.card__graph--special {
  padding-bottom: 1rem;
}
.card__graph--usuarios{
  width: 35%;
  max-width: 35%;
  margin-left: -.9em;
}
.special-graph {
  max-height: 135px;
  max-width: 270px;
}

@media screen and (max-width: 992px) {
  .card--big--medium {
    flex: 1;
    max-width: 100%;
  }
}

.show-data-container {
  visibility: visible;
}

.hide-data-container {
  visibility: hidden;
}

.overlay--loading {
  position: fixed;
  top: 0px;
  left:  0px;
  z-index:  9999;
  background:  rgba(0,0,0,0.8);
  width:  100%;
  height: 100%;
}

@media screen and (min-width: 992px) {
  main {
    min-height: calc(100vh - 154px);
  }
}

.in-between {
  display: flex;
  justify-content: space-between;
}

.upper-date {
   font-size: 14px;
   &--container {
     align-items: flex-end;
     display: flex;
   }
   &--main {
     font-weight: bold;
   }
   &--secondary {
     margin-left: .5rem;
   }
   &--old {
     color: #666666;
   }
}

.interact--container {
  display: flex;
  align-items: center;
}

.download-excel_button {
  background-image: url('~@/assets/img/iconos/excel.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  height: 28px;
  width: 30px;
}

.search-term_input, .date_filter {
  border: 1px solid #9CB93F;
  box-shadow: none;
  margin-right: 8px;
  padding: 4px 8px;
  font-size: .9em;
  &:focus {
    box-shadow: 0 0 2px 2px #9CB93F;
  }
}
.date_filter{
  background: #9cb93f;
  color: #FFFFFF;
  font-size: .9em;
}

.main-pdf {
  min-height: unset;
  max-height: 500px;
  overflow-y: scroll;
}
